import React, { ReactElement } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Drawer from '@material-ui/core/Drawer';
import { MyLargeBlueSmsIcon } from '../../styled-elements';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'transparent',
      boxShadow: 'none'
    }
  })
);

export default function PlusButtonBottomDrawer(): ReactElement {
  const classes = useStyles();
  const [state, setState] = React.useState({
    bottom: false
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  return (
    <div>
      <AddCircleIcon
        onClick={toggleDrawer('bottom', true)}
        color="primary"
        style={{
          fontSize: 40,
          maxWidth: 75,
          minWidth: 75,
          marginBottom: 20
        }}
      ></AddCircleIcon>
      <Drawer
        anchor="bottom"
        open={state.bottom}
        onClose={toggleDrawer('bottom', false)}
        PaperProps={{
          classes: {
            root: classes.root
          }
        }}
      >
        <Link to="/participate/comment" style={{ textDecoration: 'none' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center'
            }}
          >
            <p
              style={{
                marginLeft: 10,
                marginRight: 10,
                color: 'white',
                fontSize: 18
              }}
            >
              Comment on a project
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                height: 55,
                width: 55,
                borderRadius: '50%'
              }}
            >
              <MyLargeBlueSmsIcon color="primary"></MyLargeBlueSmsIcon>
            </div>
          </div>
        </Link>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            marginBottom: 125
          }}
        >
          <p
            onClick={toggleDrawer('bottom', false)}
            style={{
              marginLeft: 10,
              marginRight: 10,
              color: 'white',
              fontSize: 18
            }}
          >
            Cancel
          </p>
        </div>
      </Drawer>
    </div>
  );
}
