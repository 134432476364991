import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { RootState } from '..';
import { environment } from '../../environment';
import { ReachProjectFilterDTO } from '../../models/DTOs';
import CountyDTO from '../../models/DTOs/projects/CountyDTO';
import { fetchLoginREACH, getREACHAuthHeaders, isTokenExpired } from './user';
import { get } from 'http';
interface ProjectsState {
  projects: any[] | null;
  isAllProjectsLoaded: boolean;
  hasToggledFollow: boolean;
  isProjectCommentSelection: boolean;
  isFollowIntent: boolean | null;
  isNotificationPreferencesOpen: boolean;
  projectToFollowOrUnfollow: any;
  projectsFilterSelection: string; // generic string - handles selection state for all /projects filters as well as snackbar messages for custom filters
  customProjectsFilterSelection: boolean; // custom filter selection
  selectedProject: any;
  selectedProjectEvents: { data: any[]; count: number };
  selectedProjectComments: any[] | null;
  selectedEventDetails: any;
  isLoadingProjectEvents: boolean;
  isLoadingProjectComments: boolean;
  isAllSelectedProjCommentsLoaded: boolean;
  selectedCounties: CountyDTO[];
  isLoadingProjCommentDetails: boolean;
  hasClickedMultiLanguageEvent: boolean;
  notificationPrefsSaveOrFailMsg: string;
  lookups: {
    LUCounties: CountyDTO[];
  };
  loading: boolean;
  isLoadingMoreProjects: boolean;
  isNumAttendeesDialogOpen: boolean;
  hasErrors: boolean;
  errorMsg: string;
  searchText: string;
  startDate: Date | null;
  endDate: Date | null;
  sortField: string;
  isMine: boolean;
  hasEvents: boolean;
}

const initialState: ProjectsState = {
  projects: null,
  isAllProjectsLoaded: false,
  hasToggledFollow: false,
  isProjectCommentSelection: false,
  isFollowIntent: null,
  isNotificationPreferencesOpen: false,
  projectToFollowOrUnfollow: null,
  projectsFilterSelection: 'all', // default /projects list filter
  customProjectsFilterSelection: false,
  selectedProject: null,
  selectedProjectEvents: null,
  selectedProjectComments: null,
  selectedEventDetails: null,
  selectedCounties: [],
  isLoadingProjectEvents: false,
  isLoadingProjectComments: false,
  isAllSelectedProjCommentsLoaded: false,
  isLoadingProjCommentDetails: false,
  hasClickedMultiLanguageEvent: false,
  notificationPrefsSaveOrFailMsg: '',
  lookups: {
    LUCounties: []
  },
  loading: false,
  isLoadingMoreProjects: false,
  isNumAttendeesDialogOpen: false,
  hasErrors: false,
  errorMsg: '',
  searchText: '',
  startDate: null,
  endDate: null,
  sortField: '',
  isMine: false,
  hasEvents: false
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setSelectedCounties: (state, { payload }) => {
      state.selectedCounties = payload;
    },
    setUpdateProjectDetailsComment: (state, { payload }) => {
      const found = state.selectedProjectComments.find(
        (c) => c.StakeCommentId === payload.StakeCommentId
      );
      if (found) {
        found.IsVisible = payload.IsVisible;
      }
    },
    setAreProjectCommentResponsesExpanded: (state, { payload }) => {
      state.selectedProjectComments.find(
        (c) => c.StakeCommentId === payload.StakeCommentId
      ).AreResponsesExpanded = payload.AreResponsesExpanded;
    },
    addStakeCommentResponseToProjectsState: (state, { payload }) => {
      state.selectedProjectComments
        .find((c) => c.StakeCommentId === payload.StakeCommentId)
        .Responses.push(payload);
    },
    registerForEvent: (state) => {
      state.loading = true;
    },
    registerForEventSuccess: (state, { payload }) => {
      if (Array.isArray(state.selectedProjectEvents)) {
        state.selectedProjectEvents.find(
          (event) => event.PeId === payload
        ).IsRegistered = true;
      }
      state.loading = false;
      state.hasErrors = false;
    },
    registerForEventFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    setNotificationPrefsSaveOrFailMsg: (state, { payload }) => {
      state.notificationPrefsSaveOrFailMsg = payload;
    },
    clearEventSelectionState: (state) => {
      state.selectedEventDetails = null;
      state.hasClickedMultiLanguageEvent = false;
    },
    setIsNotificationPreferencesOpen: (state, { payload }) => {
      state.isNotificationPreferencesOpen = payload;
    },
    setIsProjectCommentSelection: (state, { payload }) => {
      state.isProjectCommentSelection = payload;
    },
    setFollowIntent: (state, { payload }) => {
      state.projectToFollowOrUnfollow = payload.project;
      state.isFollowIntent = payload.isFollowIntent;
    },
    setHasToggledFollow: (state, { payload }) => {
      state.hasToggledFollow = payload;
    },
    setHasClickedMultiLanguageEvent: (state, { payload }) => {
      state.hasClickedMultiLanguageEvent = payload;
    },
    clearSelectedProject: (state) => {
      state.isNotificationPreferencesOpen =
        initialState.isNotificationPreferencesOpen;
      state.selectedProject = initialState.selectedProject;
      state.selectedProjectEvents = initialState.selectedProjectEvents;
      state.selectedProjectComments = initialState.selectedProjectComments;
      state.isAllSelectedProjCommentsLoaded =
        initialState.isAllSelectedProjCommentsLoaded;
    },
    getProjects: (state) => {
      state.isAllProjectsLoaded = false;
      state.loading = true;
    },
    getProjectsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getProjectsSuccess: (state, { payload }) => {
      state.projects = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    loadMoreProjects: (state) => {
      state.isLoadingMoreProjects = true;
    },
    loadMoreProjectsSuccess: (state, { payload }) => {
      state.projects = [...state.projects, ...payload];
      state.isLoadingMoreProjects = false;
      state.hasErrors = false;
    },
    setIsAllProjectsLoaded: (state, { payload }) => {
      state.isAllProjectsLoaded = payload;
    },
    loadMoreProjectsFailure: (state, { payload }) => {
      state.isLoadingMoreProjects = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getSelectedProject: (state) => {
      state.loading = true;
    },
    getSelectedProjectFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getSelectedProjectSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.selectedProject = payload;
    },
    getSelectedProjectEvents: (state) => {
      state.isLoadingProjectEvents = true;
    },
    getSelectedProjectEventsFailure: (state, { payload }) => {
      state.isLoadingProjectEvents = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getSelectedProjectEventsSuccess: (state, { payload }) => {
      state.isLoadingProjectEvents = false;
      state.hasErrors = false;
      state.selectedProjectEvents = payload;
    },
    getSelectedProjectComments: (state) => {
      state.isLoadingProjectComments = true;
    },
    getSelectedProjectCommentsFailure: (state, { payload }) => {
      state.isLoadingProjectComments = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getSelectedProjectCommentsSuccess: (state, { payload }) => {
      state.isLoadingProjectComments = false;
      state.hasErrors = false;
      if (Array.isArray(state.selectedProjectComments)) {
        state.selectedProjectComments = [
          ...state.selectedProjectComments,
          ...payload
        ];
      } else {
        state.selectedProjectComments = payload;
      }
    },
    setIsAllSelectedProjCommentsLoaded: (state, { payload }) => {
      state.isAllSelectedProjCommentsLoaded = payload;
    },
    followProject: (state) => {
      state.loading = true;
    },
    followProjectSuccess: (state, { payload }) => {
      if (state.projects && state.projects.length) {
        const project = state.projects.find(
          (p) => p.ProjectId === payload.projectId
        );
        project.IsFollowing = true;
        project.IsNotify = payload.isNotify;
        project.IsEmail = payload.isEmail;
        project.IsSMS = payload.isSMS;
        state.projectToFollowOrUnfollow = project;
      }
      if (state.selectedProject) {
        state.selectedProject.IsFollowing = true;
        state.selectedProject.IsNotify = payload.isNotify;
        state.selectedProject.IsEmail = payload.isEmail;
        state.selectedProject.IsSMS = payload.isSMS;
      }
      state.loading = false;
      state.hasErrors = false;
    },
    followProjectFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    unfollowProject: (state) => {
      state.loading = true;
    },
    unfollowProjectSuccess: (state, { payload }) => {
      if (state.projectToFollowOrUnfollow) {
        state.projectToFollowOrUnfollow = null;
      }
      if (state.projects && state.projects.length) {
        // pertains to both all projects and followed projects views
        const project = state.projects.find(
          (p) => p.ProjectId === payload.projectId
        );
        project.IsFollowing = false;
        project.IsNotify = false;
        project.IsEmail = false;
        project.IsSMS = false;
      }
      if (
        state.projects &&
        state.projects.length &&
        payload.isOnFollowingOnlyView
      ) {
        // pertains to followed projects view only
        state.projects = state.projects.filter(
          (p) => p.ProjectId !== payload.projectId
        );
      }
      if (state.selectedProject) {
        state.selectedProject.IsFollowing = false;
        state.selectedProject.IsEmail = false;
        state.selectedProject.IsSMS = false;
        state.selectedProject.IsNotify = false;
      }
      state.loading = false;
      state.hasErrors = false;
    },
    unfollowProjectFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    setSelectedProject: (state, { payload }) => {
      state.selectedProject = payload;
    },
    clearProjects: (state) => {
      state.projects = [];
    },
    setProjectsFilterSelection: (state, { payload }) => {
      state.projectsFilterSelection = payload;
      if (payload === 'all') {
        state.isMine = false;
        state.hasEvents = false;
      } else if (payload === 'following') {
        state.isMine = true;
        state.hasEvents = false;
      } else if (payload === 'events') {
        state.isMine = false;
        state.hasEvents = true;
      } else if (payload === 'custom') {
        // This should no longer exist
        state.isMine = false;
        state.hasEvents = false;
      }
    },
    setCustomProjectsFilterSelection: (state, { payload }) => {
      state.customProjectsFilterSelection = payload;
    },
    getCommentDetails: (state) => {
      state.isLoadingProjCommentDetails = true;
    },
    getCommentDetailsSuccess: (state, { payload }) => {
      state.isLoadingProjCommentDetails = false;
      state.hasErrors = false;
    },
    getCommentDetailsFailure: (state, { payload }) => {
      state.isLoadingProjCommentDetails = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getEvent: (state) => {
      state.loading = true;
    },
    getEventSuccess: (state, { payload }) => {
      state.selectedEventDetails = payload;
      state.hasErrors = false;
    },
    getEventFailure: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    setSelectedEvent: (state, { payload }) => {
      state.selectedEventDetails = payload;
    },
    updateSelectedProjectState: (state, { payload }) => {
      const { IsEmail, IsSMS, IsNotify } = payload;
      state.selectedProject = {
        ...state.selectedProject,
        IsEmail,
        IsSMS,
        IsNotify
      };
    },
    getLUCounties: (state) => {
      state.loading = true;
    },
    getLUCountiesSuccess: (state, { payload }) => {
      state.lookups = { ...state.lookups, LUCounties: payload };
      state.loading = false;
      state.hasErrors = false;
    },
    getLUCountiesFailure: (state, { payload }) => {
      state.hasErrors = true;
      state.loading = false;
      state.errorMsg = payload;
    },
    setIsNumAttendeesDialogOpen: (state, { payload }) => {
      state.isNumAttendeesDialogOpen = payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setStartDate: (state, { payload }) => {
      state.startDate = payload;
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload;
    },
    setSortField: (state, { payload }) => {
      state.sortField = payload;
    }
  }
});

export const {
  setIsNumAttendeesDialogOpen,
  setUpdateProjectDetailsComment,
  setSelectedEvent,
  setAreProjectCommentResponsesExpanded,
  addStakeCommentResponseToProjectsState,
  updateSelectedProjectState,
  getEvent,
  getEventSuccess,
  getEventFailure,
  clearEventSelectionState,
  setIsProjectCommentSelection,
  setIsNotificationPreferencesOpen,
  setFollowIntent,
  setHasToggledFollow,
  setHasClickedMultiLanguageEvent,
  clearSelectedProject,
  getSelectedProject,
  getSelectedProjectSuccess,
  getSelectedProjectFailure,
  getSelectedProjectEvents,
  getSelectedProjectEventsSuccess,
  getSelectedProjectEventsFailure,
  getSelectedProjectComments,
  getSelectedProjectCommentsSuccess,
  getSelectedProjectCommentsFailure,
  getProjects,
  getProjectsSuccess,
  getProjectsFailure,
  loadMoreProjects,
  loadMoreProjectsSuccess,
  loadMoreProjectsFailure,
  setIsAllProjectsLoaded,
  setIsAllSelectedProjCommentsLoaded,
  setSelectedProject,
  clearProjects,
  setProjectsFilterSelection,
  setCustomProjectsFilterSelection,
  followProject,
  followProjectSuccess,
  followProjectFailure,
  unfollowProject,
  unfollowProjectSuccess,
  unfollowProjectFailure,
  getCommentDetails,
  getCommentDetailsSuccess,
  getCommentDetailsFailure,
  setNotificationPrefsSaveOrFailMsg,
  registerForEvent,
  registerForEventSuccess,
  registerForEventFailure,
  getLUCounties,
  getLUCountiesSuccess,
  getLUCountiesFailure,
  setSelectedCounties,
  setSearchText,
  setStartDate,
  setEndDate,
  setSortField
} = projectsSlice.actions;

export const getProjectsState = (state: RootState) => state.projects;

export const fetchLUCounties = createAsyncThunk(
  'projects/fetchLUCounties',
  async (input, thunkAPI: any) => {
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(getLUCounties());
      const { data } = await axios.get(
        `${environment.apiUrl}/lu_county/get`,
        config
      );
      if (data) {
        thunkAPI.dispatch(getLUCountiesSuccess(data));
      } else {
        throw new Error('error fetching counties list');
      }
    } catch (err) {
      thunkAPI.dispatch(getLUCountiesFailure(err.message));
    }
  }
);
export const fetchRegisterForEvent = createAsyncThunk(
  'projects/fetchRegisterForEvent',
  async (
    input: { eventId: number; attendanceCount: number },
    thunkAPI: any
  ) => {
    const hasTokenExpired = isTokenExpired(thunkAPI);
    const { eventId, attendanceCount } = input;
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(registerForEvent());
      const { status } = await axios.get(
        `${environment.apiUrl}/Reach/RegisterForEvent?eventId=${eventId}&attendance=${attendanceCount}`,
        config
      );
      if (status === 200) {
        thunkAPI.dispatch(registerForEventSuccess(eventId));
      } else {
        throw new Error('error registering for event');
      }
    } catch (err) {
      thunkAPI.dispatch(registerForEventFailure(err.message));
    }
  }
);

export const fetchSelectedProject = createAsyncThunk(
  'projects/fetchSelectedProject',
  async (projectId: number, thunkAPI: any) => {
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(getSelectedProject());
      const { data } = await axios.get(
        `${environment.apiUrl}/Reach/GetProject?id=${projectId}`,
        config
      );
      if (data) {
        thunkAPI.dispatch(getSelectedProjectSuccess(data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getSelectedProjectFailure(err.message));
    }
  }
);

export const fetchEvent = createAsyncThunk(
  'projects/fetchEventById',
  async (input: { eventId: number }, thunkAPI: any) => {
    const { eventId } = input;
    thunkAPI.dispatch(getEvent());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const { data } = await axios.get(
        `${environment.apiUrl}/Reach/GetEvent?id=${eventId}`,
        config
      );
      if (data) {
        thunkAPI.dispatch(getEventSuccess(data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getEventFailure(err.message));
    }
  }
);

export const fetchSelectedProjectEvents = createAsyncThunk(
  'projects/fetchSelectedProjectEvents',
  async (input: { projectId: number }, thunkAPI: any) => {
    const { projectId } = input;
    thunkAPI.dispatch(getSelectedProjectEvents());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const { data } = await axios.get(
        `${environment.apiUrl}/Reach/GetEventsByProjectId?id=${projectId}`,
        config
      );
      if (data) {
        thunkAPI.dispatch(getSelectedProjectEventsSuccess(data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getSelectedProjectEventsFailure(err.message));
    }
  }
);

export const fetchSelectedProjectComments = createAsyncThunk(
  'projects/fetchSelectedProjectComments',
  async (
    input: { projectId: number; start: number; length: number },
    thunkAPI: any
  ) => {
    const { projectId, start, length } = input;
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(getSelectedProjectComments());
      const { data } = await axios.get(
        `${environment.apiUrl}/Reach/GetCommentsByProjectId?id=${projectId}&start=${start}&length=${length}`,
        config
      );
      if (data) {
        if (data.length < length) {
          thunkAPI.dispatch(setIsAllSelectedProjCommentsLoaded(true));
        } else {
          thunkAPI.dispatch(setIsAllSelectedProjCommentsLoaded(false));
        }
        data.forEach((d) => (d.AreResponsesExpanded = false));
        thunkAPI.dispatch(getSelectedProjectCommentsSuccess(data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getSelectedProjectCommentsFailure(err.message));
    }
  }
);

export const fetchFollowProject = createAsyncThunk(
  'projects/fetchFollowProject',
  // defaults to in-app and email notifications
  async (
    input: {
      projectId: number;
      isEmail: boolean;
      isSMS: boolean;
      isNotify: boolean;
    },
    thunkAPI: any
  ) => {
    const { projectId, isEmail, isSMS, isNotify } = input;
    thunkAPI.dispatch(followProject());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const { status } = await axios.get(
        `${environment.apiUrl}/Reach/StakeholderSubscribe?projectId=${projectId}&isEmail=${isEmail}&isSMS=${isSMS}&isNotify=${isNotify}`,
        config
      );
      if (status === 200) {
        thunkAPI.dispatch(
          followProjectSuccess({ projectId, isEmail, isSMS, isNotify })
        );
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(followProjectFailure(err.message));
    }
  }
);

export const fetchUnfollowProject = createAsyncThunk(
  'projects/fetchUnfollowProject',
  async (
    input: { projectId: number; isOnFollowingOnlyView: boolean },
    thunkAPI: any
  ) => {
    const { projectId, isOnFollowingOnlyView } = input;
    thunkAPI.dispatch(unfollowProject());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const { status } = await axios.get(
        `${
          environment.apiUrl
        }/Reach/StakeholderUnsubscribe?projectId=${projectId}&isDelete=${true}`,
        config
      );
      if (status === 200) {
        thunkAPI.dispatch(
          unfollowProjectSuccess({ projectId, isOnFollowingOnlyView })
        );
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(unfollowProjectFailure(err.message));
    }
  }
);

export const fetchProjects = createAsyncThunk(
  'projects/fetchProjects',
  async (
    input: {
      isMine: boolean;
      start: number;
      length: number;
      hasEvents: boolean;
      countyIds?: number[];
    },
    thunkAPI: any
  ) => {
    const { isMine, start, length, hasEvents, countyIds } = input;
    thunkAPI.dispatch(getProjects());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const payload: ReachProjectFilterDTO = {
        // IsMine: isMine, // Overwritten below
        Start: start,
        Length: length,
        // HasEvents: hasEvents, // Overwritten below
        PssCountyIds: countyIds
      };
      if (thunkAPI.getState().projectsSlice.isMine != null) {
        payload.IsMine = thunkAPI.getState().projectsSlice.isMine;
      }
      if (thunkAPI.getState().projectsSlice.hasEvents != null) {
        payload.HasEvents = thunkAPI.getState().projectsSlice.hasEvents;
      }
      if (thunkAPI.getState().projectsSlice.searchText) {
        payload.SearchText = thunkAPI.getState().projectsSlice.searchText;
      }
      if (thunkAPI.getState().projectsSlice.startDate) {
        payload.StartDate = thunkAPI.getState().projectsSlice.startDate;
      }
      if (thunkAPI.getState().projectsSlice.endDate) {
        payload.EndDate = thunkAPI.getState().projectsSlice.endDate;
      }
      if (thunkAPI.getState().projectsSlice.sortField) {
        payload.SortField = thunkAPI.getState().projectsSlice.sortField;
      }
      // payload.SortField = 'EVENT_DATE_OLDEST';
      const response = await axios.post(
        `${environment.apiUrl}/Reach/FilterProjects`,
        payload,
        config
      );
      if (response.data) {
        // v1 old sorting working, but handling on back end now
        // if (!payload.SortField) {
        //   if (isMine === false && hasEvents === false) {
        //     // ALL button clicked
        //     response.data.sort((a, b) =>
        //       a.ProjectNr.localeCompare(b.ProjectNr)
        //     );
        //   } else {
        //     // FOLLOWING or MEETINGS button clicked
        //     response.data.sort((a, b) => {
        //       const today = new Date();
        //       today.setHours(0, 0, 0, 0);

        //       const maxDate = new Date(8640000000000000);
        //       const dateA = a.MeetingDate ? new Date(a.MeetingDate) : maxDate;
        //       const dateB = b.MeetingDate ? new Date(b.MeetingDate) : maxDate;

        //       const todayTime = today.getTime();
        //       const dateATime = dateA.getTime();
        //       const dateBTime = dateB.getTime();

        //       const isFutureA = dateATime >= todayTime;
        //       const isFutureB = dateBTime >= todayTime;

        //       if (isFutureA && isFutureB) {
        //         return dateATime - dateBTime;
        //       } else if (!isFutureA && !isFutureB) {
        //         return dateBTime - dateATime;
        //       } else {
        //         return isFutureA ? -1 : 1;
        //       }
        //     });
        //   }
        // }
        thunkAPI.dispatch(getProjectsSuccess(response.data));
        if (response.data.length < length) {
          thunkAPI.dispatch(setIsAllProjectsLoaded(true));
        } else {
          thunkAPI.dispatch(setIsAllProjectsLoaded(false));
        }
      } else {
        thunkAPI.dispatch(getProjectsFailure('no result from server'));
      }
    } catch (err) {
      thunkAPI.dispatch(getProjectsFailure(err.message));
    }
  }
);

export const fetchMoreProjects = createAsyncThunk(
  'projects/fetchMoreProjects',
  async (
    input: {
      isMine: boolean;
      start: number;
      length: number;
      hasEvents: boolean;
      // countyIds?: string[]; // Original
      countyIds?: number[];
    },
    thunkAPI: any
  ) => {
    const { isMine, start, length, hasEvents, countyIds } = input;
    thunkAPI.dispatch(loadMoreProjects());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const payload: ReachProjectFilterDTO = {
        // IsMine: isMine, // Overwritten below
        Start: start,
        Length: length,
        // HasEvents: hasEvents, // Overwritten below
        PssCountyIds: countyIds
      };
      if (thunkAPI.getState().projectsSlice.isMine != null) {
        payload.IsMine = thunkAPI.getState().projectsSlice.isMine;
      }
      if (thunkAPI.getState().projectsSlice.hasEvents != null) {
        payload.HasEvents = thunkAPI.getState().projectsSlice.hasEvents;
      }
      if (thunkAPI.getState().projectsSlice.searchText) {
        payload.SearchText = thunkAPI.getState().projectsSlice.searchText;
      }
      if (thunkAPI.getState().projectsSlice.startDate) {
        payload.StartDate = thunkAPI.getState().projectsSlice.startDate;
      }
      if (thunkAPI.getState().projectsSlice.endDate) {
        payload.EndDate = thunkAPI.getState().projectsSlice.endDate;
      }
      if (thunkAPI.getState().projectsSlice.sortField) {
        payload.SortField = thunkAPI.getState().projectsSlice.sortField;
      }
      const { data } = await axios.post(
        `${environment.apiUrl}/Reach/FilterProjects`,
        payload,
        config
      );
      if (data) {
        if (data.length < length) {
          thunkAPI.dispatch(setIsAllProjectsLoaded(true));
        } else {
          thunkAPI.dispatch(setIsAllProjectsLoaded(false));
        }
        thunkAPI.dispatch(loadMoreProjectsSuccess(data));
      } else {
        thunkAPI.dispatch(loadMoreProjectsFailure('no result from server'));
      }
    } catch (err) {
      thunkAPI.dispatch(loadMoreProjectsFailure(err.message));
    }
  }
);

export default projectsSlice.reducer;
