/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import grey from '@material-ui/core/colors/grey';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { useSelector, useDispatch } from 'react-redux';
import { setIsAlreadyLoggedIn, fetchLogout } from '../../store/slices/user';
import history from '../../history';
import { setNavigationTab } from '../../store/slices/application';
import { Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { fetchNotifications } from '../../store/slices/notifications';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { fetchLoggedInStakeholder } from '../../store/slices/myprofile';
import { RootState } from '../../store';
import { setIsFollowedComments } from '../../store/slices/feed';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#ef0000',
    color: 'white',
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))(Badge);

const theme = createTheme({
  typography: {
    fontSize: 12
  },
  overrides: {
    MuiBottomNavigationAction: {
      root: {
        '&$selected': {
          color: '#2b79c6'
        },
        margin: '0px -15px',
        padding: '0px 15px',
        color: grey[700]
      }
    }
  }
});

const useStyles = makeStyles({
  stickToBottom: {
    width: '100%',
    height: '75px',
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 1
  }
});

function BottomNavbar(props) {
  const dispatch = useDispatch();
  const { isLoggedInREACH } = useSelector(
    (state: RootState) => state
  ).userSlice;
  const { navigationTab } = useSelector(
    (state: RootState) => state
  ).applicationSlice;

  const { notifications } = useSelector(
    (state: RootState) => state
  ).notificationsSlice;
  const [isNotificationsActive, setIsNotificationsActive] = useState(
    navigationTab === 'notifications' ? true : false
  );
  useEffect(() => {
    if (navigationTab === 'notifications' && !isNotificationsActive) {
      setIsNotificationsActive(true);
    } else if (navigationTab !== 'notifications' && isNotificationsActive) {
      setIsNotificationsActive(false);
    }
  }, [navigationTab]);

  const classes = useStyles(props);

  useEffect(() => {
    if (!isLoggedInREACH) {
      const email = localStorage.getItem('email');
      if (email && localStorage.getItem('reachAuth')) {
        dispatch(setIsAlreadyLoggedIn(email));
      } else {
        if (
          window.location.pathname !== '/login' && // public pages
          window.location.pathname !== '/signup'
        ) {
          dispatch(fetchLogout());
          dispatch(setNavigationTab('/')); // login protected pages
          history.push('/');
        }
      }
    } else {
      // frontload notifications and stakeholder info
      dispatch(fetchNotifications());
      dispatch(fetchLoggedInStakeholder());
    }
  }, [isLoggedInREACH]);

  return (
    <MuiThemeProvider theme={theme}>
      <nav>
        {isLoggedInREACH && (
          <BottomNavigation
            value={navigationTab}
            className={classes.stickToBottom}
          >
            <BottomNavigationAction
              onClick={() => dispatch(setNavigationTab('projects'))}
              component={Link}
              to="/projects"
              label="Projects"
              value="projects"
              icon={<EmojiTransportationIcon style={{ fontSize: 30 }} />}
            />
            <BottomNavigationAction
              onClick={() => {
                dispatch(setNavigationTab('feed'));
                dispatch(setIsFollowedComments(true));
              }}
              component={Link}
              to="/feed"
              label="Feed"
              value="feed"
              icon={<InsertCommentIcon style={{ fontSize: 30 }} />}
            />
            {/* MAS-224 - temporarily hide items before UAT */}
            {/* <BottomNavigationAction
              onClick={() => dispatch(setNavigationTab('participate'))}
              component={Link}
              to="/participate"
              label="Participate"
              value="participate"
              icon={<PeopleIcon style={{ fontSize: 30 }} />}
            /> */}
            <BottomNavigationAction
              onClick={() => dispatch(setNavigationTab('dashboard'))}
              component={Link}
              to="/dashboard"
              label="Dashboard"
              value="dashboard"
              icon={<AssessmentIcon style={{ fontSize: 30 }} />}
            />
            <BottomNavigationAction
              onClick={() => {
                dispatch(setNavigationTab('notifications'));
              }}
              component={Link}
              to="/notifications"
              label="Notifications"
              value="notifications"
              icon={
                <StyledBadge
                  badgeContent={
                    notifications
                      ? notifications.filter((n) => !n.IsNoteSeen).length
                      : 0
                  }
                >
                  <NotificationsIcon
                    style={{
                      color: isNotificationsActive ? '#2b79c6' : '#616161',
                      fontSize: 30
                    }}
                  />
                </StyledBadge>
              }
            />
          </BottomNavigation>
        )}
      </nav>
    </MuiThemeProvider>
  );
}
export default withRouter(BottomNavbar);
