import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { PasswordRequirements, SubHeaderGoBack } from '../..';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { Spacer } from '../../../styled-elements';
import { environment } from '../../../environment';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';

export default function ForgotPassword(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isResetCodeReceived, setIsResetCodeRecevied] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [email, setEmail] = useState('');
  const [errorText, setErrorText] = useState('');

  const [resetPasswordFormState, setResetPasswordFormState] = useState({
    code: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const handleSubmitInitiateResetPw = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { CodeDeliveryDetails } = await Auth.forgotPassword(email);
      if (CodeDeliveryDetails) {
        setIsResetCodeRecevied(true);
        setHasErrors(false);
      }
      setLoading(false);
    } catch (err) {
      setHasErrors(true);
      setErrorMsg(err.message);
      setLoading(false);
    }
  };

  const handleChangeResetPassword = (e) => {
    setResetPasswordFormState({
      ...resetPasswordFormState,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmitResetPwd = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { newPassword, confirmNewPassword, code } = resetPasswordFormState;
    if (newPassword !== confirmNewPassword) {
      setErrorText('Passwords do not match.');
      setLoading(false);
      return;
    } else {
      setErrorText('');
      try {
        await Auth.forgotPasswordSubmit(email, code, newPassword);
        setIsPasswordReset(true);
        setHasErrors(false);
        setLoading(false);
      } catch (err) {
        setHasErrors(true);
        setErrorMsg(err.message);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Forgot Password - {environment.entityName} REACH</title>
        <link
          rel="canonical"
          href={`${environment.clientUrl}/forgotpassword`}
        />
      </Helmet>
      <SubHeaderGoBack isArrowBack={true} heading="Forgot Password" />
      <div
        style={{
          width: 300,
          margin: 'auto'
        }}
      >
        {!isResetCodeReceived ? (
          <div
            style={{
              marginTop: 24,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <form
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
              onSubmit={handleSubmitInitiateResetPw}
            >
              <TextField
                value={email}
                fullWidth
                inputProps={{ style: { textTransform: 'lowercase' } }}
                onChange={(e) =>
                  setEmail(DOMPurify.sanitize(e.target.value.toLowerCase()))
                }
                label="Email"
                name="username"
                id="username"
              />
              <br></br>
              <Button
                aria-label="Reset password submit"
                style={{
                  backgroundColor: '#21890e',
                  color: 'white'
                }}
                variant="contained"
                type="submit"
              >
                submit
              </Button>
              {loading && (
                <>
                  <span
                    role="alert"
                    style={{ position: 'absolute', marginLeft: -9999 }}
                  >
                    Submitting password reset
                  </span>
                  <CircularProgress style={{ margin: 16 }} />
                </>
              )}
            </form>
          </div>
        ) : (
          <React.Fragment>
            <Spacer />
            {isPasswordReset ? (
              <div>
                <div role="alert">Successfully reset password.</div>
                <Spacer />
                <Link to="/login" style={{ textDecoration: 'none' }}>
                  <Button
                    aria-label="Go to login page"
                    color="primary"
                    variant="contained"
                  >
                    Go to Login
                  </Button>
                </Link>
              </div>
            ) : (
              <React.Fragment>
                <div style={{ marginTop: 21 }}>
                  If your email matches the account on file, you will receive a
                  reset code shortly.
                </div>
                <Spacer />
                <PasswordRequirements />
                <Spacer />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <form onSubmit={handleSubmitResetPwd}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      onChange={handleChangeResetPassword}
                      label="Confirmation Code"
                      name="code"
                      id="confirmation code"
                    />
                    <TextField
                      required
                      fullWidth
                      error={errorText.length === 0 ? false : true}
                      helperText={errorText}
                      onChange={handleChangeResetPassword}
                      label="New Password"
                      name="newPassword"
                      type="password"
                      id="new password"
                    />
                    <TextField
                      required
                      fullWidth
                      error={errorText.length === 0 ? false : true}
                      helperText={errorText}
                      onChange={handleChangeResetPassword}
                      label="Confirm New Password"
                      name="confirmNewPassword"
                      type="password"
                      id="confirm new password"
                    />
                    <Spacer />
                    <Spacer />
                    <Button
                      aria-label="Confirm new password submit"
                      style={{
                        backgroundColor: '#21890e',
                        color: 'white'
                      }}
                      variant="contained"
                      type="submit"
                    >
                      submit
                    </Button>
                    {loading && (
                      <>
                        <span
                          role="alert"
                          style={{ position: 'absolute', marginLeft: -9999 }}
                        >
                          Confirming new password submission
                        </span>
                        <CircularProgress style={{ margin: 16 }} />
                      </>
                    )}
                  </form>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {!isPasswordReset && hasErrors && errorMsg && (
          <div role="alert" style={{ marginTop: 10, color: 'red' }}>
            {errorMsg}
          </div>
        )}
      </div>
    </div>
  );
}
