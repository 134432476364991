import { Button, CircularProgress } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericLookup from '../../models/shared/GenericLookup';
import { RootState } from '../../store';
import { fetchMoreComments } from '../../store/slices/feed';
import {
  fetchMoreProjects,
  fetchSelectedProjectComments
} from '../../store/slices/projects';
import { Spacer } from '../../styled-elements';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: -8px;
`;

export default function LoadMore(): ReactElement {
  const dispatch = useDispatch();
  const {
    isLoadingProjectComments,
    isLoadingMoreProjects,
    isAllProjectsLoaded,
    isAllSelectedProjCommentsLoaded,
    projectsFilterSelection,
    projects,
    selectedProjectComments,
    selectedProject,
    selectedCounties
  } = useSelector((state: RootState) => state).projectsSlice;

  const {
    comments,
    isLoadingMoreComments,
    isFollowedComments,
    isAllCommentsLoaded,
    isMine
  } = useSelector((state: RootState) => state).feedSlice;

  /*
  useEffect(() => {
    // TODO - RESET ALL COMMENTS LOADED STATE BASED ON PATH CHANGE
    // reset isAllSelectedProjCommentsLoaded or isAllCommentsLoaded based on cur/prev path
    console.log('currentPath', currentPath);
    console.log('prevPath', prevPath);

    if (currentPath.includes('/projects' && isAllSelectedProjCommentsLoaded)) {
      console.log('reset is all proj comments loaded');
      //dispatch(resetAllProjCommentsLoaded())
    } else if (prevPath === '/feed' && isAllCommentsLoaded) {
      console.log('reset is all comments loaded');
      // dispatch(resetAllProjCommentsLoaded())
    }
  }, [
    comments,
    currentPath,
    dispatch,
    isAllCommentsLoaded,
    isAllSelectedProjCommentsLoaded,
    prevPath
  ]);
  */
  const handleClick = () => {
    // this is reused in multiple components/pages, base dispatch on url
    const url = window.location.pathname;
    if (url === '/feed') {
      dispatch(
        fetchMoreComments({
          isMine,
          isFollowedComments,
          start: comments.length,
          length: 20
        })
      );
    } else if (url === '/projects' || url === '/projects/') {
      switch (projectsFilterSelection) {
        case 'all':
          dispatch(
            fetchMoreProjects({
              isMine: false,
              start: projects && projects.length ? projects.length : 0,
              length: 50,
              hasEvents: false,
              countyIds: []
            })
          );
          break;
        case 'following':
          dispatch(
            fetchMoreProjects({
              isMine: true,
              start: projects && projects.length ? projects.length : 0,
              length: 50,
              hasEvents: false,
              countyIds: []
            })
          );
          break;
        case 'events':
          dispatch(
            fetchMoreProjects({
              isMine: false,
              start: projects && projects.length ? projects.length : 0,
              length: 50,
              hasEvents: true,
              countyIds: []
            })
          );
          break;
        case 'custom':
          // only current custom (applied via ellipsis) filter is county
          dispatch(
            fetchMoreProjects({
              isMine: false,
              start: projects && projects.length ? projects.length : 0,
              length: 50,
              hasEvents: false,
              countyIds: selectedCounties.map((c: GenericLookup) => c.value)
            })
          );
          return;
        default:
          throw new Error('unhandle case in load more projects switch');
      }
    } else if (url.includes('/projects/')) {
      // is project details page, load comments instead've projects
      dispatch(
        fetchSelectedProjectComments({
          projectId: selectedProject.ProjectId,
          start: selectedProjectComments.length,
          length: 20
        })
      );
    }
  };
  return (
    <Container>
      <div>
        {isLoadingMoreComments && (
          <>
            <span
              role="alert"
              style={{ position: 'absolute', marginLeft: -9999 }}
            >
              Loading more project comments
            </span>
            <CircularProgress style={{ margin: 24 }} />
          </>
        )}
      </div>
      <div>
        {isLoadingProjectComments && (
          <>
            <span
              role="alert"
              style={{ position: 'absolute', marginLeft: -9999 }}
            >
              Loading project comments
            </span>
            <CircularProgress style={{ margin: 24 }} />
          </>
        )}
      </div>
      <div>
        {isLoadingMoreProjects && (
          <>
            <span
              role="alert"
              style={{ position: 'absolute', marginLeft: -9999 }}
            >
              Loading more projects
            </span>
            <CircularProgress style={{ marginTop: 6 }} />
          </>
        )}
      </div>
      <Spacer />
      {!isLoadingProjectComments && !isLoadingProjectComments && !isLoadingMoreComments && (
        !isAllProjectsLoaded ||
        !isAllSelectedProjCommentsLoaded ||
        !isAllCommentsLoaded
        ) && (
          <Button
            aria-label="Load more projects"
            disabled={
              isLoadingMoreProjects ||
              isLoadingMoreComments
            }
            onClick={handleClick}
            color="primary"
            variant="contained"
          >
            Load More
          </Button>
        )}
      <Spacer />
    </Container>
  );
}
