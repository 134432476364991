import React, { ReactElement, useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

interface Props {
  text: string;
  setIsCopiedProjLink?: Function;
}

export default function DynamicDialog({
  text,
  setIsCopiedProjLink
}: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Dialog
      onClose={() => {
        setIsOpen(false);
        setIsCopiedProjLink(false);
      }}
      aria-labelledby={text}
      open={isOpen}
    >
      <DialogTitle id={text}>
        <div role="alert">{text}</div>
      </DialogTitle>
    </Dialog>
  );
}
