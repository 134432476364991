import React, { ReactElement } from 'react';
import { SubHeaderGoBack } from '..';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {
  MyBlueHomeIcon,
  MyBlueCalendarIcon,
  MyBlueDashboardIcon,
  MyBlueSmsIcon
} from '../../styled-elements';
import { PlusButtonBottomDrawer } from '..';
import { Link } from 'react-router-dom';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { environment } from '../../environment';

interface Props {}

const participateOptionsList = [
  {
    icon: () => <MyBlueHomeIcon color="primary" />,
    text: 'My Participation',
    link: '/participate'
  },
  {
    icon: () => <MyBlueCalendarIcon color="primary" />,
    text: 'Find a Public Meeting to Attend',
    link: '/participate'
  },
  {
    icon: () => <MyBlueDashboardIcon color="primary" />,
    text: 'Find an Online Public Meeting to Attend',
    link: '/participate'
  },
  {
    icon: () => <MyBlueSmsIcon color="primary" />,
    text: 'Comment on a Project',
    link: '/participate/comment'
  }
];

export default function Participate(): ReactElement {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      },
      paper: {
        width: !isMobile && 575,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2)
      },
      plusButton: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row-reverse'
      }
    })
  );
  const classes = useStyles({});

  const { isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Participate - {environment.entityName} REACH</title>
        <link rel="canonical" href={`${environment.clientUrl}/participate`} />
      </Helmet>
      <div className={classes.root}>
        <div>
          <SubHeaderGoBack
            isArrowBack={false}
            heading="Participate"
          ></SubHeaderGoBack>
          {participateOptionsList.map((option, idx) => (
            <Link to={option.link} key={idx} style={{ textDecoration: 'none' }}>
              <Paper className={classes.paper}>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>{option.icon()}</Grid>
                  <Grid item xs zeroMinWidth>
                    <Typography align="left" noWrap>
                      {option.text}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Link>
          ))}
        </div>
      </div>
      <div className={classes.plusButton}>
        <PlusButtonBottomDrawer />
      </div>
    </React.Fragment>
  );
}
