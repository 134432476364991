import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { Button, MenuItem, Select, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateStakeCommentPayload } from '../../store/slices/participate';
import { RootState } from '../../store';
import styled from 'styled-components';

const MySelect = styled(Select)`
  margin-top: 30px;
  width: 100%;
`;

const MyContainer = styled.div`
  margin-top: 30px;
`;

const styles = () => ({
  root: {
    margin: '80px 25px 40px 25px'
  },
  markLabel: {
    fontSize: 14
  }
});

interface Props {
  defaultValue: number;
  classes?: any;
  setProjCommentFormLocalState: any;
  projectCommentFormLocalState: any;
}

const marks = [
  {
    value: 0,
    label: 'Not in Favor'
  },
  {
    value: 1,
    label: 'Less in Favor'
  },
  {
    value: 2,
    label: 'Neutral'
  },
  {
    value: 3,
    label: 'Leaning in Favor'
  },
  {
    value: 4,
    label: 'In Favor'
  }
];

const ProjectSupportSlider = ({
  defaultValue,
  classes,
  setProjCommentFormLocalState,
  projectCommentFormLocalState
}: Props): ReactElement => {
  const { isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  const CustomSlider = withStyles({
    thumb: {
      marginTop: -2
    },
    markActive: {
      display: 'none'
    },
    mark: {
      height: 8,
      width: 8,
      bottom: isMobile ? 14 : 7
    },
    rail: {
      backgroundImage: 'linear-gradient(.25turn, #FF0200, #038100)',
      height: 8
    },
    track: {
      backgroundImage: 'linear-gradient(.25turn, #FF0200, #038100)'
    },
    markLabel: {
      height: 20
    }
  })(Slider);
  const dispatch = useDispatch();
  const [value, setValue] = useState(defaultValue);
  const [isRespondViaDropdown, setIsRespondViaDropdown] = useState(false);
  const { stakeCommentPayload } = useSelector(
    (state: RootState) => state
  ).participateSlice;
  useEffect(() => {
    if (!stakeCommentPayload.STAKEHOLDER_SUPPORT_ID) {
      // set initial state to store as neutral in case user does not change it
      dispatch(
        updateStakeCommentPayload({
          property: 'STAKEHOLDER_SUPPORT_ID',
          value: defaultValue
        })
      );
    }
  }, [defaultValue, dispatch, stakeCommentPayload.STAKEHOLDER_SUPPORT_ID]);

  const mapTo_LU_STAKEHOLDER_SUPPORT = (sliderValue: number) => {
    switch (sliderValue) {
      case 0:
        return 5;
      case 1:
        return 4;
      case 2:
        return 3;
      case 3:
        return 2;
      case 4:
        return 1;
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setProjCommentFormLocalState({
      ...projectCommentFormLocalState,
      projectSupportLevel: mapTo_LU_STAKEHOLDER_SUPPORT(newValue)
    });
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs>
          <CustomSlider
            getAriaValueText={() => marks.find((m) => m.value === value).label}
            max={4}
            min={0}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => (
              <div style={{ fontSize: 16 }}>
                {marks
                  .find((m) => m.value === value)
                  .label.split(' ')
                  .map((word, idx) => {
                    if (idx === 0) {
                      return <div key={idx}>{word}</div>;
                    } else {
                      return (
                        <span key={idx}>{`${word}${
                          idx !==
                            marks
                              .find((m) => m.value === value)
                              .label.split(' ').length +
                              1 && ' '
                        }`}</span>
                      );
                    }
                  })}
              </div>
            )}
            value={value}
            onChange={handleChange}
            aria-labelledby="continuous-slider"
            step={null}
            marks={marks}
            track={false}
          />
        </Grid>
      </Grid>
      {!isRespondViaDropdown ? (
        <MyContainer>
          <Button
            aria-label="Respond to this question via dropdown menu"
            size="small"
            onClick={() => setIsRespondViaDropdown(true)}
            variant="contained"
          >
            Respond via dropdown menu
          </Button>
        </MyContainer>
      ) : (
        <MySelect value={value} onChange={(e: any) => setValue(e.target.value)}>
          <MenuItem value={0}>Not in Favor</MenuItem>
          <MenuItem value={1}>Less in Favor</MenuItem>
          <MenuItem value={2}>Neutral</MenuItem>
          <MenuItem value={3}>Leaning in Favor</MenuItem>
          <MenuItem value={4}>In Favor</MenuItem>
        </MySelect>
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectSupportSlider);
