import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import history from './history';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import * as serviceWorker from './serviceWorker';
import GA4React from 'ga-4-react';
import { environment } from './environment';

export const ga4react = new GA4React(environment.googleAnalyticsId);

(async (_) => {
  if (
    environment.googleAnalyticsId &&
    environment.googleAnalyticsId !== 'none'
  ) {
    await ga4react
      .initialize()
      .then((res) => {
        // debugger;
        // ga4react.pageview('path');
        // ga4react.gtag('event', 'pageview', 'path'); // or your custom gtag event
        console.log('GA Success.');
      })
      .catch((err) => console.log('GA Failure.'))
      .finally(() => {
        ReactDOM.render(
          <Router history={history}>
            <Provider store={store}>
              <App />
            </Provider>
          </Router>,
          document.getElementById('root')
        );
      });
  } else {
    ReactDOM.render(
      <Router history={history}>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>,
      document.getElementById('root')
    );
  }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
