export const environment = {
  entityName: 'Mass DOT',
  dashboardMapCenterCoords: '-72,42',
  landingPageImg: '/images/landingpage/massdotlanding.jpg',
  clientLogo: '/images/logos/massdot_logo.png',
  clientUrl: 'https://reach.massdotpi.com',
  privacyPolicyUrl: 'https://www.mass.gov/privacypolicy',
  apiUrl: 'https://api.massdotpi.com/api',
  cognitoUserPoolId: 'us-east-1_Vp35tx8Iu',
  cognitoUserPoolWebClientId: '36ajlocdtd9mumpdhjehvap6i8',
  cognitoDomain: 'massdotreachauth.auth.us-east-1.amazoncognito.com',
  onlineMeetingsUrl: 'https://meetings.massdotpi.com',
  googleAnalyticsId: 'none',
  faqDocUrl: 'https://massdot-reach.s3.amazonaws.com/MassDOT_REACH_FAQ.pdf',
  authority: 'https://accounts.massdotpi.com',
  client_id: 'MassDOT.Production.1.REACH',
  client_secret: '388D45BA-A36B-4984-FA59-B187D329C207',
};
