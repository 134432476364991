import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { RootState } from '..';
import { environment } from '../../environment';
import { fetchLoginREACH, getREACHAuthHeaders, isTokenExpired } from './user';

interface MyProfileState {
  orgTypes: any[];
  stakeholderInfo: any;
  isLoadingStakeholder: boolean;
  isLoadingOrgTypes: boolean;
  hasErrors: boolean;
  errorMsg: string;
}

const initialState: MyProfileState = {
  orgTypes: [],
  stakeholderInfo: null,
  isLoadingStakeholder: false,
  isLoadingOrgTypes: false,
  hasErrors: false,
  errorMsg: ''
};

const myProfileSlice = createSlice({
  name: 'myprofile',
  initialState,
  reducers: {
    clearMyProfileState: (state) => {
      // neccesary for my profile stats updates in case user follows projects or comments on them in other tab
      state.orgTypes = initialState.orgTypes;
      state.stakeholderInfo = initialState.stakeholderInfo;
      state.isLoadingStakeholder = initialState.isLoadingStakeholder;
      state.isLoadingOrgTypes = initialState.isLoadingOrgTypes;
      state.hasErrors = initialState.hasErrors;
      state.errorMsg = initialState.errorMsg;
    },
    setIncrementStakeProjectCount: (state) => {
      // not currently being used.
      // would improve performance for myprofile to reduce GetStakeholder calls.
      if (state.stakeholderInfo) {
        state.stakeholderInfo.ProjectCount += 1;
      }
    },
    setDecrementStakeProjectCount: (state) => {
      // not currently being used.
      // would improve performance for myprofile to reduce GetStakeholder calls
      if (state.stakeholderInfo) {
        state.stakeholderInfo.ProjectCount -= 1;
      }
    },
    getStakeholder: (state) => {
      state.isLoadingStakeholder = true;
    },
    getStakeholderFailure: (state, { payload }) => {
      state.isLoadingStakeholder = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getStakeholderSuccess: (state, { payload }) => {
      state.isLoadingStakeholder = false;
      state.hasErrors = false;
      state.stakeholderInfo = payload;
    },
    updateStakeholder: (state) => {
      state.isLoadingStakeholder = true;
    },
    updateStakeholderFailure: (state, { payload }) => {
      state.isLoadingStakeholder = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    updateStakeholderSuccess: (state) => {
      state.isLoadingStakeholder = false;
      state.hasErrors = false;
      // state.stakeholderInfo = payload;
    },
    getLUOrgTypes: (state) => {
      state.isLoadingOrgTypes = true;
    },
    getLUOrgTypesFailure: (state, { payload }) => {
      state.isLoadingOrgTypes = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getLUOrgTypesSuccess: (state, { payload }) => {
      state.isLoadingOrgTypes = false;
      state.hasErrors = false;
      state.orgTypes = payload;
    }
  }
});
export const {
  clearMyProfileState,
  setDecrementStakeProjectCount,
  setIncrementStakeProjectCount,
  getStakeholder,
  getStakeholderSuccess,
  getStakeholderFailure,
  updateStakeholder,
  updateStakeholderSuccess,
  updateStakeholderFailure,
  getLUOrgTypes,
  getLUOrgTypesSuccess,
  getLUOrgTypesFailure
} = myProfileSlice.actions;

export const getMyProfileState = (state: RootState) => state.myprofile;

export const fetchLUOrgTypes = createAsyncThunk(
  'myprofile/fetchLUOrgTypes',
  async (input, thunkAPI) => {
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(getLUOrgTypes());
      const response = await axios.get(
        `${environment.apiUrl}/LU_ORGANIZATION_TYPE/Get`,
        // `${environment.apiUrl}/LU_ORGANIZATION_TYPE/GetLookup`,
        config
      );
      // console.log('fetchLUOrgTypes response: ', response);
      if (response.data) {
        thunkAPI.dispatch(getLUOrgTypesSuccess(response.data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getLUOrgTypesSuccess(err.message));
    }
  }
);

export const fetchLoggedInStakeholder = createAsyncThunk(
  'myprofile/fetchStakeholder',
  async (input, thunkAPI: any) => {
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(getStakeholder());
      const { data } = await axios.get(
        `${environment.apiUrl}/Reach/GetStakeholder`,
        config
      );
      if (data) {
        thunkAPI.dispatch(getStakeholderSuccess(data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getStakeholderFailure(err.message));
    }
  }
);

export const fetchUpdateStakeholder = createAsyncThunk(
  'myprofile/fetchUpdateStakeholder',
  async (input: any, thunkAPI: any) => {
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(updateStakeholder());
      const { status } = await axios.put(
        `${environment.apiUrl}/Reach/PutStakeholder?id=${input.STAKE_ID}`,
        input,
        config
      );
      if (status === 200) {
        thunkAPI.dispatch(updateStakeholderSuccess());
        thunkAPI.dispatch(fetchLoggedInStakeholder());
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(updateStakeholderFailure(err.message));
    }
  }
);

export default myProfileSlice.reducer;
