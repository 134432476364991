import React, { ReactElement } from 'react';
import { Button } from '@material-ui/core/';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchComments,
  setIsAllComments,
  setIsAllCommentsLoaded,
  setIsFollowedComments,
  setIsMine
} from '../../store/slices/feed';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../store';
import styled from 'styled-components';
interface Props {
  isMine?: boolean;
  location?: {
    hash: string;
    key: string;
    pathname: string;
    search: string;
    state: any;
  };
}

const MyButtonChild = styled(Button)`
  border-radius: 40px;
  height: 40px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 8px 8px 8px 0px;
  @media only screen and (min-width: 600px) {
    margin: 12px 8px 8px 0px;
  }
  max-width: 100%;
`;

export default function FeedHeaderNav(props: Props): ReactElement {
  const { isFollowedComments, isMine, isAllComments } = useSelector(
    (state: RootState) => state
  ).feedSlice;
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const resetUrl = () => {
    const isMineURL = new URLSearchParams(location.search).get('isMine');
    if (isMineURL) {
      history.push('/feed');
    }
  };

  const setUrlIsMine = () => {
    history.push(`/feed?isMine=${true}`);
  };

  const handleClickFollowing = (): void => {
    resetUrl();
    dispatch(setIsFollowedComments(true));
    dispatch(
      fetchComments({
        isMine: false,
        isFollowedComments: true,
        start: 0,
        length: 20
      })
    );
  };

  const handleClickAll = (): void => {
    resetUrl();
    dispatch(setIsAllComments(true));
    dispatch(setIsAllCommentsLoaded(false));
    dispatch(
      fetchComments({
        isMine: false,
        isFollowedComments: false,
        start: 0,
        length: 20
      })
    );
  };

  const handleClickMine = (): void => {
    setUrlIsMine();
    dispatch(setIsMine(true));
    dispatch(
      fetchComments({
        isMine: true,
        isFollowedComments: false,
        start: 0,
        length: 20
      })
    );
  };
  return (
    <Container>
      <MyButtonChild
        aria-selected={isAllComments}
        aria-label="Filter comments - display all comments."
        color={isAllComments ? 'primary' : 'secondary'}
        variant="contained"
        onClick={handleClickAll}
      >
        All
      </MyButtonChild>
      <MyButtonChild
        aria-selected={isMine}
        aria-label="Filter comments - only display YOUR comments."
        color={isMine ? 'primary' : 'secondary'}
        variant="contained"
        onClick={handleClickMine}
      >
        Mine
      </MyButtonChild>

      <MyButtonChild
        aria-selected={isFollowedComments}
        aria-label="Filter comments - display only projects that you are following."
        color={isFollowedComments ? 'primary' : 'secondary'}
        variant="contained"
        onClick={handleClickFollowing}
      >
        Following
      </MyButtonChild>
      {/* MAS-224 - temporarily hide items before UAT */}
      {/* <Link style={{ textDecoration: 'none' }} to="/feed/filters">
        <MyButtonChild aria-label="More filtering options."  color="secondary" variant="contained">
          More
        </MyButtonChild>
      </Link> */}
    </Container>
  );
}
