import { environment } from '../environment';

export const config = {
  Auth: {
    region: environment.region,
    userPoolId: environment.cognitoUserPoolId,
    userPoolWebClientId: environment.cognitoUserPoolWebClientId,
    oauth: {
      // client_id: '',
      domain: environment.cognitoDomain,
      scope: ['email', 'openid'],
      redirectSignIn: `${environment.clientUrl}/`,
      redirectSignOut: `${environment.clientUrl}/`,
      responseType: 'token'
    },
    federated: {
      provider: ['Google', 'Facebook']
    }
  }
};
