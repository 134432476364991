import {
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  withStyles
} from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import {
  MyErrorIcon,
  MyIconButton,
  MySmsIcon,
  MyVisibilityIcon,
  MyVisibilityOffIcon
} from '../../styled-elements/Icons';
import moment from 'moment';
import { styled as styledMUI } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import SanitizedHTML from 'react-sanitized-html';
import sanitizeHtml from 'sanitize-html';
import ReplyIcon from '@material-ui/icons/Reply';
import { environment } from '../../environment';
import {
  fetchPostStakeCommentReponse,
  fetchUpdateComment
} from '../../store/slices/participate';
import { useDispatch, useSelector } from 'react-redux';
import {
  addStakeCommentResponseToNotificationsState,
  setAreNotificationCommentResponsesExpanded,
  setUpdateNotificationsComment
} from '../../store/slices/notifications';
import {
  addStakeCommentResponseToFeedState,
  setAreFeedCommentResponsesExpanded,
  setUpdateFeedComment
} from '../../store/slices/feed';
import {
  addStakeCommentResponseToProjectsState,
  setAreProjectCommentResponsesExpanded,
  setUpdateProjectDetailsComment
} from '../../store/slices/projects';
import { CommentDetailsDTO } from '../../models/DTOs';
import { Link } from 'react-router-dom';
import { RootState } from '../../store';
import DOMPurify from 'dompurify';

const ShowTooltipWhenDisabledButton = styledMUI(Button)({
  '&.Mui-disabled': {
    pointerEvents: 'auto'
  },
  '&.MuiButton-root': {
    padding: 0
  }
});

const MyAccordionDetails = styledMUI(AccordionDetails)({
  display: 'inline-block',
  textAlign: 'left',
  padding: 0,
  width: '100%'
});

const MyAccordion = styledMUI(Accordion)({
  width: '100%',
  margin: '0px !important',
  padding: '0px',
  boxShadow: 'none'
});

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
`;

const SimpleFlexContainer = styled.div`
  display: flex;
`;

const CenteredFlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ProjectTitle = styled.div`
  text-align: left;
  font-size: 0.875rem;
  margin-bottom: 4px;
`;

const MyH2 = styled.h2`
  margin: 0px;
  font-weight: normal;
  font-size: inherit;
`;

const StakeNameAndDate = styled.h3`
  font-size: 0.875rem;
  font-style: oblique;
  margin-top: 0px;
  margin-bottom: 4px;
  font-weight: normal;
`;
const StakeCommentDiv = styled.div`
  overflow-wrap: break-word;
  width: 100%:
  padding-bottom: 8px;
  text-align: left;
  font-size: 1rem;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    margin-left: 6px;
  }
`;

const MyPaper = styled(Paper)`
  @media only screen and (min-width: 720px) {
    width: 575px;
  }
  margin: 8px auto;
  position: relative;
  overflow: hidden;
`;

const ButtonWithTooltip = ({ tooltipText, disabled, onClick, ...other }) => {
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? 'div' : undefined,
    onClick: disabled ? undefined : onClick
  };
  return (
    <Tooltip title={tooltipText}>
      <ShowTooltipWhenDisabledButton
        style={{
          backgroundColor: 'transparent',
          textTransform: 'none',
          fontWeight: 'normal'
        }}
        {...other}
        {...adjustedButtonProps}
      />
    </Tooltip>
  );
};
const IconLeftAccordionSummary = withStyles({
  expandIcon: {
    order: -1
  }
})(AccordionSummary);
// this component is used both as the comments in the /Feed list and in the Project Details view
const useStyles = makeStyles({
  hideBorder: {
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white'
    }
  }
});
interface Props {
  comment: CommentDetailsDTO;
}

export default function ProjectComment({ comment }: Props): ReactElement {
  const {
    ProjectId,
    ProjectDesc,
    StakeComment,
    StakeCommentId,
    StakeCommentDate,
    StakeholderName,
    EntityName,
    IsVisible,
    Topics,
    AreResponsesExpanded,
    IsMine,
    ProjectNr
  } = comment;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, hasErrors } = useSelector(
    (state: RootState) => state
  ).participateSlice;
  const { comments: feedSliceComments } = useSelector(
    (state: RootState) => state
  ).feedSlice;
  const { selectedProjectComments } = useSelector(
    (state: RootState) => state
  ).projectsSlice;
  const { selectedNotificationDetails } = useSelector(
    (state: RootState) => state
  ).notificationsSlice;
  const [stakeCommentResponse, setStakeCommentResponse] = useState('');
  const [isThumbsUp, setIsThumbsUp] = useState(false);
  const handleToggleIsResponsesExpanded = () => {
    const payload = {
      StakeCommentId,
      AreResponsesExpanded: !AreResponsesExpanded
    };
    // Need below logic because the areResponsesExpanded state is re-used for ProjectComment component in 3 different Redux slices and UIs
    if (
      selectedNotificationDetails.commentDetails &&
      selectedNotificationDetails.commentDetails.StakeCommentId ===
        payload.StakeCommentId
    ) {
      // if comments in notifications state, update expanded state for that particular comment
      dispatch(setAreNotificationCommentResponsesExpanded(payload));
    }
    // if comments in feed state, update expanded state for that particular comment
    if (
      feedSliceComments &&
      feedSliceComments.length &&
      feedSliceComments.some((c) => c.StakeCommentId === payload.StakeCommentId)
    ) {
      dispatch(setAreFeedCommentResponsesExpanded(payload));
    }
    if (
      selectedProjectComments &&
      selectedProjectComments.length &&
      selectedProjectComments.some(
        (c) => c.StakeCommentId === payload.StakeCommentId
      )
    ) {
      // if comments in projects state, update expanded state for that particular comment
      dispatch(setAreProjectCommentResponsesExpanded(payload));
    }
  };

  const getResponderType = (responderType: number) => {
    // 1 is DOT or other entity, 2 is stakeholder
    if (responderType === 1) {
      return `(${EntityName})`;
    } else if (responderType === 2) {
      return '';
    } else {
      throw new Error('unhandled responderType');
    }
  };

  const submitCommentResponse = async () => {
    await dispatch(
      fetchPostStakeCommentReponse({
        commentId: StakeCommentId,
        response: stakeCommentResponse
      })
    );
    if (!hasErrors) {
      const payload = {
        CommentResponseId: null,
        LastUpdatedDate: new Date().toString(),
        LastUpdatedPersonId: null,
        RespondedBy: null,
        ResponderName: null,
        Response: stakeCommentResponse,
        ResponseDate: new Date().toString(),
        ResponseSentDate: null,
        ResponseType: 2,
        StakeCommentId
      };
      if (selectedNotificationDetails.commentDetails) {
        // if comments in notifications state, add to responseses state for that particular comment
        dispatch(addStakeCommentResponseToNotificationsState(payload));
      }
      // if comments in feed state, add to responseses state for that particular comment
      if (feedSliceComments && feedSliceComments.length) {
        dispatch(addStakeCommentResponseToFeedState(payload));
      }
      // if comments in projects state (selected project), add to responses state for that particular comment
      if (selectedProjectComments && selectedProjectComments.length) {
        dispatch(addStakeCommentResponseToProjectsState(payload));
      }
    }
  };

  const handleIsThumbsUp = () => {
    /* not currently used because of MAS-224 - temporarily hide items before UAT */
    setIsThumbsUp(!isThumbsUp);
  };

  const handleToggleCommentVisibility = async () => {
    const payload = { ...comment, IsVisible: !IsVisible };
    await dispatch(fetchUpdateComment(payload));
    if (!hasErrors) {
      // need to update store in all places comments are used
      if (selectedNotificationDetails.commentDetails) {
        // if comments in notifications state, update state for that particular comment
        dispatch(setUpdateNotificationsComment(payload));
      }
      // if comments in feed state, update state for that particular comment
      if (feedSliceComments && feedSliceComments.length) {
        dispatch(setUpdateFeedComment(payload));
      }
      if (selectedProjectComments && selectedProjectComments.length) {
        // if comments in projects state, update state for that particular comment
        dispatch(setUpdateProjectDetailsComment(payload));
      }
    }
  };

  const getEntityProjectTitle = () => {
    switch (environment.entityName) {
      case 'Iowa DOT':
        return ProjectDesc;
      default:
        return ProjectNr;
    }
  };

  return (
    <MyPaper>
      {/*
        Prototype used to conditionally show a project icon in comments -> need a DB field for this to be dynamic.
        <ListItemAvatar>
            <SvgIcon
            color="primary"
            {...{
                ...(comment.projectPhoto === 'WarningIcon' && {
                component: WarningIcon
                }),
                ...(comment.projectPhoto === 'DirectionsBikeIcon' && {
                component: DirectionsBikeIcon
                }),
                ...(comment.projectPhoto === 'BuildIcon' && {
                component: BuildIcon
                })
            }}
            />
        </ListItemAvatar>
      */}
      <OuterContainer>
        {!IsVisible && (
          <div style={{ display: 'flex', marginBottom: 16 }}>
            <MyErrorIcon color="primary" />
            <div
              style={{
                textAlign: 'left',
                fontStyle: 'italic',
                fontSize: '0.75rem'
              }}
            >
              This comment is only visible by you. Toggle the visibility icon to
              let others view your comment.
            </div>
          </div>
        )}
        <FlexContainer>
          <MyH2>
            <Link to={`/projects/${ProjectId}`}>
              <ProjectTitle>{getEntityProjectTitle()}</ProjectTitle>
            </Link>
          </MyH2>
          <IconsContainer>
            {/* MAS-224 - temporarily hide items before UAT */}
            {/* <MyEditIcon />
            <MyDeleteIcon /> */}
            {IsMine && (
              <MyIconButton
                onClick={handleToggleCommentVisibility}
                aria-label="Toggle comment visibility"
              >
                {IsVisible ? <MyVisibilityIcon /> : <MyVisibilityOffIcon />}
              </MyIconButton>
            )}
          </IconsContainer>
        </FlexContainer>
        <StakeNameAndDate>
          {`${StakeholderName} Wrote on ${moment(StakeCommentDate).format(
            'MMMM Do YYYY'
          )}:`}
        </StakeNameAndDate>
        <StakeCommentDiv>{StakeComment}</StakeCommentDiv>
        {Topics && !!Topics.length && (
          <div
            style={{
              marginBottom: 8,
              fontSize: '0.875rem',
              textAlign: 'left',
              color: '#767676'
            }}
          >
            <MyH2>
              <span>Comment Topics: </span>
              <span>
                {Topics.map(
                  (t: { CommentTopicId: number; CommentTopic: string }, idx) =>
                    `${t.CommentTopic}${idx !== Topics.length - 1 ? ', ' : ' '}`
                )}
              </span>
            </MyH2>
          </div>
        )}
        <FlexContainer>
          <CenteredFlexContainer>
            <ButtonWithTooltip
              aria-label={
                comment.Responses.length
                  ? 'View Thread'
                  : 'There are no responses to this comment.'
              }
              tooltipText={
                comment.Responses.length
                  ? 'View Thread'
                  : 'There are no responses to this comment.'
              }
              onClick={handleToggleIsResponsesExpanded}
              disabled={!comment.Responses.length}
            >
              <SimpleFlexContainer>
                <MySmsIcon
                  color={AreResponsesExpanded ? 'primary' : 'secondary'}
                />
                <div>
                  {comment.ResponseRequestTypeId === 4
                    ? `${
                        comment.StakeholderName.split(' ')[0]
                      } did not request a response`
                    : `${
                        comment.Responses && comment.Responses.length > 0
                          ? comment.Responses.length
                          : 0
                      } ${
                        comment.Responses.length === 1
                          ? 'Response'
                          : 'Responses'
                      }`}
                </div>
              </SimpleFlexContainer>
            </ButtonWithTooltip>
          </CenteredFlexContainer>
          {/* MAS-224 - temporarily hide items before UAT */}
          {/* <MyIconButton
            onClick={handleIsThumbsUp}
            aria-label="Like this comment"
          >
            <MyThumbUpIcon color={isThumbsUp ? 'primary' : 'secondary'} />
          </MyIconButton> */}
        </FlexContainer>
        <MyAccordion
          className={classes.hideBorder}
          expanded={AreResponsesExpanded}
          onChange={handleToggleIsResponsesExpanded}
        >
          <IconLeftAccordionSummary
            style={{ display: 'none', padding: 0 }}
            aria-controls="panel1a-content"
          ></IconLeftAccordionSummary>
          <MyAccordionDetails>
            <div>
              {!!comment.Responses.length &&
                comment.Responses.map((r, idx) => (
                  <Paper
                    style={{ padding: 8, margin: '8px 0px' }}
                    variant="outlined"
                    key={idx}
                  >
                    <CenteredFlexContainer>
                      {r.ResponseType === 1 && (
                        <img
                          style={{
                            width: 50,
                            marginRight: 8
                          }}
                          src={environment.clientLogo}
                          alt={`${environment.entityName} Logo`}
                        ></img>
                      )}
                      <StakeNameAndDate>
                        {`${r.ResponderName ? r.ResponderName : ''} ${
                          !r.LastUpdatedPersonId ? StakeholderName : ''
                        }${getResponderType(r.ResponseType)} on ${moment(
                          r.ResponseDate
                        ).format('MMMM Do YYYY')}:`}
                      </StakeNameAndDate>
                    </CenteredFlexContainer>
                    <StakeCommentDiv>
                      <SanitizedHTML
                        allowedAttributes={{
                          ...sanitizeHtml.defaults.allowedAttributes,
                          '*': ['style', 'href']
                        }}
                        allowedTags={[
                          ...sanitizeHtml.defaults.allowedTags,
                          'a'
                        ]}
                        html={r.Response}
                      />
                    </StakeCommentDiv>
                  </Paper>
                ))}
              {IsMine && (
                <React.Fragment>
                  <CenteredFlexContainer>
                    <ReplyIcon style={{ marginRight: 8 }} color="primary" />
                    <TextField
                      id="continue-the-discussion"
                      value={stakeCommentResponse}
                      onChange={(e) =>
                        setStakeCommentResponse(
                          DOMPurify.sanitize(e.target.value)
                        )
                      }
                      maxRows={8}
                      multiline
                      fullWidth
                      variant="outlined"
                      placeholder="Continue the discussion"
                    />
                  </CenteredFlexContainer>
                  {stakeCommentResponse.length >= 4 && (
                    <Button
                      onClick={submitCommentResponse}
                      style={{ marginTop: 8 }}
                      fullWidth
                      aria-label="Submit Comment Reply"
                      color="primary"
                      variant="contained"
                    >
                      Submit Reply
                    </Button>
                  )}
                </React.Fragment>
              )}
              {loading && (
                <>
                  <span
                    role="alert"
                    style={{ position: 'absolute', marginLeft: -9999 }}
                  >
                    Loading project comment
                  </span>
                  <CircularProgress style={{ margin: 12 }} />
                </>
              )}
            </div>
          </MyAccordionDetails>
        </MyAccordion>
      </OuterContainer>
    </MyPaper>
  );
}
