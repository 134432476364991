import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import history from '../../history';
import grey from '@material-ui/core/colors/grey';
import {
  MySmallAccountCircleIcon,
  MyExitToAppIcon,
  MyHelpOutlineIcon
} from '../../styled-elements/Icons';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { setIsLoggedOut } from '../../store/slices/user';
import { setNavigationTab } from '../../store/slices/application';
import { RootState } from '../../store';
import { environment } from '../../environment';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    color: theme.palette.secondary.main,
    background: 'white'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  centerLogo: {
    justifyContent: 'center'
  }
}));

export default function Header(): ReactElement {
  const dispatch = useDispatch();

  const getProjectsLinkLogo = () => {
    // console.log('environment: ', environment);
    switch (environment.entityName) {
      case 'Iowa DOT':
        return (
          <Link
            onClick={() => dispatch(setNavigationTab('projects'))}
            to="/projects"
          >
            <img
              style={{ width: 100, marginLeft: 10 }}
              src="/images/logos/REACH_logo.png"
              alt={`${environment.entityName} REACH Logo (Real Time Engagement and Communications Hub)`}
            ></img>
          </Link>
        );
      case 'Mass DOT':
        return (
          <Link
            onClick={() => dispatch(setNavigationTab('projects'))}
            to="/projects"
          >
            <img
              style={{ width: 100, marginLeft: 10 }}
              src="/images/logos/REACH_logo.png"
              alt={`${environment.entityName} REACH Logo (Real Time Engagement and Communications Hub)`}
            ></img>
          </Link>
        );
      case 'Maine DOT':
        return (
          <Link
            onClick={() => dispatch(setNavigationTab('projects'))}
            to="/projects"
          >
            <img
              style={{ width: 100, marginLeft: 10 }}
              src={environment.clientLogo}
              alt={`${environment.entityName} Logo`}
            ></img>
          </Link>
        );
      case 'Connecticut DOT':
        return (
          <Link
            onClick={() => dispatch(setNavigationTab('projects'))}
            to="/projects"
          >
            <img
              style={{ width: 65, marginLeft: 10 }}
              src={environment.clientLogo}
              alt={`${environment.entityName} Logo`}
            ></img>
          </Link>
        );
      default:
        throw new Error(
          'unhandled case in LandingPage -> getProjectsLinkLogo()'
        );
    }
  };

  const { isLoggedInREACH, userInfo } = useSelector((state: RootState) => {
    return state;
  }).userSlice;

  const classes = useStyles({});
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {isLoggedInREACH && (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open
            })}
          >
            <Toolbar className={classes.centerLogo}>
              {isLoggedInREACH && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                    style={{ marginRight: 'auto' }}
                  >
                    <MenuIcon />
                  </IconButton>
                  {getProjectsLinkLogo()}
                </div>
              )}
              {isLoggedInREACH ? (
                <div
                  style={{
                    marginLeft: 'auto',
                    display: 'flex'
                  }}
                >
                  {userInfo.username && <div>{userInfo.username}</div>}
                  {/* {!openAfterDelay && (
                    <div>
                      <MySmallSearchIcon />
                    </div>
                  )} */}
                </div>
              ) : (
                <img
                  style={{
                    width: 100
                  }}
                  src="/images/logos/REACH_logo.png"
                  alt={`${environment.entityName} REACH Logo (Real Time Engagement and Communications Hub)`}
                ></img>
              )}
            </Toolbar>
          </AppBar>

          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton
                aria-label="Close sidebar navigation drawer."
                onClick={handleDrawerClose}
              >
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              {['My Profile', 'FAQ', 'Logout'].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  {...(text === 'Logout' && {
                    onClick: async () => {
                      await Auth.signOut();
                      dispatch(setIsLoggedOut());

                      // localStorage.clear();
                      // history.push('/');
                      //dispatch(fetchLogout());

                      handleDrawerClose();
                    }
                  })}
                  {...(text === 'My Profile' && {
                    onClick: () => {
                      handleDrawerClose();
                      history.push('/myprofile');
                    }
                  })}
                  {...(text === 'FAQ' && {
                    onClick: () => {
                      window.open(
                        'https://mainedot-reach-public-pdfs.s3.amazonaws.com/MaineDOT_REACH_FAQ.pdf',
                        '_blank'
                      );
                    }
                  })}
                >
                  <ListItemIcon>
                    {text === 'My Profile' ? (
                      <MySmallAccountCircleIcon />
                    ) : text === 'Logout' ? (
                      <MyExitToAppIcon />
                    ) : (
                      <MyHelpOutlineIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </Drawer>

          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open
            })}
          >
            <div style={{ height: '10px' }} />
          </main>
        </div>
      )}
    </div>
  );
}
