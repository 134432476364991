import { CircularProgress } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ProjectComment } from '..';
import { RootState } from '../../store';
import { Spacer } from '../../styled-elements';

export default function SelectedNotificationDetails(): ReactElement {
  const {
    selectedNotificationDetails,
    loading,
    loadingNotificationDetails
  } = useSelector((state: RootState) => state).notificationsSlice;
  const { NotificationType } = selectedNotificationDetails.notification;

  const switchRenderNotificationDetails = () => {
    switch (NotificationType) {
      case 1:
        return (
          <div>
            {selectedNotificationDetails.commentDetails && (
              <React.Fragment>
                <div style={{ padding: 16 }}>
                  <strong>
                    <em> {selectedNotificationDetails.notification.Message}</em>
                  </strong>
                </div>
                <ProjectComment
                  comment={selectedNotificationDetails.commentDetails}
                />
                <Spacer />
                {selectedNotificationDetails.notification.Data.projectId && (
                  <Link
                    to={`/projects/${selectedNotificationDetails.notification.Data.projectId}`}
                  >
                    Go to project page for this notification.
                  </Link>
                )}
              </React.Fragment>
            )}
          </div>
        );
      default:
        throw new Error('unhandled notification type');
    }
  };
  return (
    <div style={{ margin: 8 }}>
      {switchRenderNotificationDetails()}
      {loading ||
        (loadingNotificationDetails && (
          <>
            <span
              role="alert"
              style={{ position: 'absolute', marginLeft: -9999 }}
            >
              Loading notification details
            </span>
            <CircularProgress style={{ margin: 24 }} />
          </>
        ))}
    </div>
  );
}
