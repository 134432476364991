import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { RootState } from '..';
import { environment } from '../../environment';
import { CommentDetailsDTO, NotificationDTO } from '../../models/DTOs';
import { fetchLoginREACH, getREACHAuthHeaders, isTokenExpired } from './user';
interface NotificationsState {
  loading: boolean;
  loadingNotificationDetails: boolean;
  hasErrors: boolean;
  errorMsg: string;
  notifications: NotificationDTO[] | null;
  selectedNotificationDetails: {
    notification: any;
    commentDetails?: CommentDetailsDTO | null;
  };
}

const initialState: NotificationsState = {
  loading: false,
  loadingNotificationDetails: false,
  hasErrors: false,
  errorMsg: '',
  notifications: null,
  selectedNotificationDetails: {
    notification: null,
    commentDetails: null
  }
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setUpdateNotificationsComment: (state, { payload }) => {
      state.selectedNotificationDetails.commentDetails.IsVisible =
        payload.IsVisible;
    },
    setAreNotificationCommentResponsesExpanded: (state, { payload }) => {
      state.selectedNotificationDetails.commentDetails.AreResponsesExpanded =
        payload.AreResponsesExpanded;
    },
    addStakeCommentResponseToNotificationsState: (state, { payload }) => {
      state.selectedNotificationDetails.commentDetails.Responses.push(payload);
    },
    resetSelectedNotificationState: (state) => {
      state.selectedNotificationDetails =
        initialState.selectedNotificationDetails;
      state.hasErrors = initialState.hasErrors;
      state.errorMsg = initialState.errorMsg;
    },
    getNotifications: (state) => {
      state.loading = true;
    },
    getNotificationsSuccess: (state, { payload }) => {
      state.notifications = payload;
      state.loading = false;
    },
    getNotificationsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    markReadNotification: (state) => {
      state.loading = true;
    },
    markReadNotificationSuccess: (state, { payload }) => {
      const notificationToModify = state.notifications.find(
        (n) => n.NotificationId === payload
      );
      if (!notificationToModify.IsNoteSeen) {
        notificationToModify.IsNoteSeen = true;
      }
      state.loading = false;
    },
    markReadNotificationFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    setSelectedNotification: (state, { payload }) => {
      state.selectedNotificationDetails.notification = payload;
    },
    getCommentDetails: (state) => {
      state.loadingNotificationDetails = true;
    },
    getCommentDetailsSuccess: (state, { payload }) => {
      state.selectedNotificationDetails.commentDetails = payload;
      state.loadingNotificationDetails = false;
      state.hasErrors = false;
    },
    getCommentDetailsFailure: (state, { payload }) => {
      state.loadingNotificationDetails = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    }
  }
});
export const {
  setUpdateNotificationsComment,
  setAreNotificationCommentResponsesExpanded,
  addStakeCommentResponseToNotificationsState,
  getNotifications,
  getNotificationsSuccess,
  getNotificationsFailure,
  setSelectedNotification,
  getCommentDetails,
  getCommentDetailsSuccess,
  getCommentDetailsFailure,
  resetSelectedNotificationState,
  markReadNotification,
  markReadNotificationSuccess,
  markReadNotificationFailure
} = notificationsSlice.actions;

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (input, thunkAPI: any) => {
    thunkAPI.dispatch(getNotifications());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const { data } = await axios.get(
        `${
          environment.apiUrl
        }/Reach/GetNotifications?includeIsSeen=${true}&includeIsDeleted=${true}`,
        config
      );
      if (data) {
        const parseStringifiedResult = data.map((d) => ({
          ...d,
          Data: JSON.parse(d.Data)
        }));
        thunkAPI.dispatch(getNotificationsSuccess(parseStringifiedResult));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getNotificationsFailure(err.message));
    }
  }
);

export const fetchCommentDetails = createAsyncThunk(
  'notifications/fetchCommentDetails',
  async (commentId: number, thunkAPI: any) => {
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(getCommentDetails());
      const { data } = await axios.get(
        `${environment.apiUrl}/Reach/GetCommentDetails?commentId=${commentId}`,
        config
      );
      if (data) {
        data.AreResponsesExpanded = true;
        thunkAPI.dispatch(getCommentDetailsSuccess(data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      if (err.response.data.error) {
        return thunkAPI.dispatch(
          getCommentDetailsFailure(err.response.data.error) // custom error message from API: "Comment does not exist."
        );
      }
      thunkAPI.dispatch(getCommentDetailsFailure(err.message));
    }
  }
);

export const fetchMarkReadNotification = createAsyncThunk(
  'notifications/fetchMarkReadNotification',
  async (notificationId: string, thunkAPI: any) => {
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(markReadNotification());
      const { status } = await axios.get(
        `${environment.apiUrl}/Reach/MarkNotificationRead?notificationId=${notificationId}`,
        config
      );
      if (status === 200) {
        thunkAPI.dispatch(markReadNotificationSuccess(notificationId));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(markReadNotificationFailure(err.message));
    }
  }
);

export const getNotificationsState = (state: RootState) => state.notifications;

export default notificationsSlice.reducer;
