import { Button, CircularProgress, TextField } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchSignupPIMA,
  fetchAmplifySignup,
  fetchConfirmAmplifySignup,
  createPIMAStakeholder,
  fetchLoginREACH,
  fetchConfirmSignupAll
} from '../../../store/slices/user';
import { PolicyAgreementsFixedBottom } from '../../../styled-elements';
import { PasswordRequirements } from '../../../components';
import { RootState } from '../../../store';
import styled from 'styled-components';
import { getEntityPrivacyPolicy } from '../LandingPage';
import { Helmet } from 'react-helmet';
import { environment } from '../../../environment';
import { TextFieldHalfWidth, TextFieldThirdWidth } from './AmplifySignUpForm';
import DOMPurify from 'dompurify';

const MyH1 = styled.h1`
  text-align: left;
  font-size: 20px;
`;

let pwCache = ''; // used to temp store password from choose your password screen to confirm account screen. amplify requires it along with the email confirmation code.
export default function ConfirmAccount(props: any): ReactElement {
  const dispatch = useDispatch();
  const {
    userInfo,
    loading,
    hasErrors,
    errorMsg,
    isLoggedInREACH,
    isLoggedInAmplify
  } = useSelector((state: RootState) => {
    return state;
  }).userSlice;

  const [newAccountFormState, setNewAccountFormState] = useState({
    password: '',
    confirmPassword: ''
  });

  const [confirmAccountCodeFormState, setConfirmAccountCodeFormState] =
    useState({
      password: '',
      confirmationCode: '',
      firstName: '',
      lastName: '',
      zipcode: '',
      address: '',
      city: '',
      state: ''
    });

  const [confirmAccountFormState, setConfirmAccountFormState] = useState({
    confirmationCode: ''
  });

  const [errorText, setErrorText] = useState('');

  const handleSubmitNewAccount = async (e) => {
    // For "Choose your password" screen
    e.preventDefault();
    const { password, confirmPassword } = newAccountFormState;
    pwCache = password;
    const { username: email } = userInfo;
    if (password !== confirmPassword) {
      setErrorText('Passwords do not match.');
      return;
    } else {
      setErrorText('');
      if (!userInfo.isSignedUpAmplify && email && password) {
        await dispatch(
          fetchAmplifySignup({
            email: email,
            password: password,
            attributes: {
              email: email
            }
          })
        );
      }
    }
  };

  const handleChangeNewAccount = (e) => {
    setNewAccountFormState({
      ...newAccountFormState,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmitConfirmAccount = async (e) => {
    // for multi-factor auth screen where you submit confirmation code
    e.preventDefault();
    const { confirmationCode } = confirmAccountFormState;
    const { username: email } = userInfo;
    dispatch(
      fetchConfirmSignupAll({
        email,
        password: pwCache,
        confirmationCode,
        firstName: userInfo.signUpFormData.firstName,
        lastName: userInfo.signUpFormData.lastName,
        address: userInfo.signUpFormData.address,
        city: userInfo.signUpFormData.city,
        state: userInfo.signUpFormData.state,
        zipcode: userInfo.signUpFormData.zipcode
      })
    );
  };

  const handleChangeConfirmAccount = (e) => {
    setConfirmAccountFormState({
      ...confirmAccountFormState,
      [e.target.name]: DOMPurify.sanitize(e.target.value)
    });
  };

  const handleChangeConfirmAccountCode = (e) => {
    // case where the user didnt enter their email confirmation code correctly then left the app
    setConfirmAccountCodeFormState({
      ...confirmAccountCodeFormState,
      [e.target.name]: DOMPurify.sanitize(e.target.value)
    });
  };

  const handleSubmitConfirmAccountCode = (e) => {
    // case where the user didnt enter their email confirmation code correctly then left the app
    // for multi-factor auth screen where you submit confirmation code
    e.preventDefault();
    const { confirmationCode, password } = confirmAccountCodeFormState;
    const { username: email } = userInfo;
    dispatch(
      fetchConfirmSignupAll({
        email,
        password,
        confirmationCode,
        firstName: DOMPurify.sanitize(confirmAccountCodeFormState.firstName),
        lastName: DOMPurify.sanitize(confirmAccountCodeFormState.lastName),
        address: DOMPurify.sanitize(confirmAccountCodeFormState.address),
        city: DOMPurify.sanitize(confirmAccountCodeFormState.city),
        state: DOMPurify.sanitize(confirmAccountCodeFormState.state),
        zipcode: DOMPurify.sanitize(confirmAccountCodeFormState.zipcode)
      })
    );
  };

  return (
    <div style={{ margin: 36 }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Confirm Account - {environment.entityName} REACH</title>
        <link
          rel="canonical"
          href={`${environment.clientUrl}/signup/confirmaccount`}
        />
      </Helmet>
      {!props.location.state &&
        userInfo &&
        !userInfo.isSignedUpAmplify && ( // CHOOSE PASSWORD SCREEN AT /confirmaccount
          <React.Fragment>
            <div style={{ textAlign: 'left' }}>
              <MyH1>Choose your password</MyH1>
              <PasswordRequirements />
            </div>
            <form onSubmit={handleSubmitNewAccount}>
              <TextField
                required
                fullWidth
                error={errorText.length === 0 ? false : true}
                helperText={errorText}
                onChange={handleChangeNewAccount}
                label="Password"
                name="password"
                type="password"
                id="password"
              />
              <TextField
                required
                fullWidth
                error={errorText.length === 0 ? false : true}
                helperText={errorText}
                onChange={handleChangeNewAccount}
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                id="confirm password"
              />
              <div style={{ height: 16 }}></div>
              <Button
                disabled={loading}
                aria-label="Continue"
                style={{
                  backgroundColor: '#21890e',
                  color: 'white'
                }}
                variant="contained"
                type="submit"
              >
                Continue
              </Button>
            </form>
          </React.Fragment>
        )}
      {!props.location.state &&
        userInfo &&
        userInfo.isSignedUpAmplify && ( // CONFIRMATION SCREEN AT /confirmaccount
          <React.Fragment>
            {!isLoggedInREACH ? (
              <React.Fragment>
                <div style={{ margin: 20 }}>
                  A confirmation code will be emailed to you shortly to confirm
                  your account. Please confirm your email address and enter the
                  code here.
                </div>
                <form onSubmit={handleSubmitConfirmAccount}>
                  <TextField
                    fullWidth
                    onChange={handleChangeConfirmAccount}
                    label="Confirmation Code"
                    name="confirmationCode"
                    id="confirmation code"
                  />
                  <div style={{ height: 16 }}></div>
                  <Button
                    disabled={loading}
                    aria-label="Confirm account."
                    style={{
                      backgroundColor: '#21890e',
                      color: 'white'
                    }}
                    variant="contained"
                    type="submit"
                  >
                    Confirm
                  </Button>
                </form>
                <PolicyAgreementsFixedBottom>
                  {getEntityPrivacyPolicy()}
                </PolicyAgreementsFixedBottom>
              </React.Fragment>
            ) : (
              <div role="alert">Success! You will be redirected shortly.</div>
            )}
          </React.Fragment>
        )}
      {props.location.state && (
        // Cognito "unconfirmed" state - case where the user didnt enter their email confirmation code correctly then left the app and returned
        <React.Fragment>
          {isLoggedInREACH ? (
            <div role="alert">Success! You will be redirected shortly.</div>
          ) : (
            <>
              <div style={{ margin: 20 }}>
                A confirmation code will be emailed to you shortly to confirm
                your account. Please confirm your code and user info here.
              </div>
              <form onSubmit={handleSubmitConfirmAccountCode}>
                <TextField
                  required
                  fullWidth
                  onChange={handleChangeConfirmAccountCode}
                  label="Password"
                  name="password"
                  type="password"
                  id="password"
                  InputLabelProps={{
                    id: 'password'
                  }}
                />
                <TextField
                  required
                  fullWidth
                  onChange={handleChangeConfirmAccountCode}
                  label="Confirmation Code"
                  name="confirmationCode"
                  id="confirmation code"
                />
                <TextFieldHalfWidth
                  style={{ marginRight: 8 }}
                  required
                  onChange={handleChangeConfirmAccountCode}
                  label="First Name"
                  name="firstName"
                  id="first name"
                  InputLabelProps={{
                    id: 'first name'
                  }}
                />
                <TextFieldHalfWidth
                  required
                  onChange={handleChangeConfirmAccountCode}
                  label="Last Name"
                  name="lastName"
                  id="last name"
                  InputLabelProps={{
                    id: 'last name'
                  }}
                />
                <TextField
                  onChange={handleChangeConfirmAccountCode}
                  fullWidth
                  label="Address"
                  name="address"
                  id="address"
                  InputLabelProps={{
                    id: 'address'
                  }}
                />
                <TextFieldThirdWidth
                  style={{ marginRight: 8 }}
                  onChange={handleChangeConfirmAccountCode}
                  fullWidth
                  label="City"
                  name="city"
                  id="city"
                  InputLabelProps={{
                    id: 'city'
                  }}
                />
                <TextFieldThirdWidth
                  style={{ marginRight: 8 }}
                  onChange={handleChangeConfirmAccountCode}
                  fullWidth
                  label="State"
                  name="state"
                  id="state"
                  InputLabelProps={{
                    id: 'state'
                  }}
                />
                <TextFieldThirdWidth
                  required
                  onChange={handleChangeConfirmAccountCode}
                  fullWidth
                  label="Zip"
                  name="zipcode"
                  id="zipcode"
                  InputLabelProps={{
                    id: 'zipcode'
                  }}
                />
                <div style={{ height: 16 }}></div>
                <Button
                  disabled={loading}
                  aria-label="Confirm account."
                  style={{
                    backgroundColor: '#21890e',
                    color: 'white'
                  }}
                  variant="contained"
                  type="submit"
                >
                  Confirm
                </Button>
              </form>
              <PolicyAgreementsFixedBottom>
                {getEntityPrivacyPolicy()}
              </PolicyAgreementsFixedBottom>
            </>
          )}
        </React.Fragment>
      )}
      {hasErrors && errorMsg && (
        <div role="alert" style={{ marginTop: 10, color: 'red' }}>
          {errorMsg}
        </div>
      )}
      {loading && (
        <>
          <span
            role="alert"
            style={{ position: 'absolute', marginLeft: -9999 }}
          >
            Confirming account
          </span>
          <CircularProgress style={{ margin: 16 }} />
        </>
      )}{' '}
    </div>
  );
}
