import React, { useCallback, useEffect, useState } from 'react';
import {
  LoadMore,
  ProjectComments,
  ToggleFollowBottomDrawer,
  ProjectNotificationsPreferences,
  LanguageSelectionBottomDrawer,
  DynamicDialog,
  ProjectEvents
} from '../';
import {
  Button,
  CircularProgress,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ShareIcon from '@material-ui/icons/Share';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchSelectedProject,
  clearSelectedProject,
  fetchSelectedProjectEvents,
  fetchSelectedProjectComments,
  setFollowIntent,
  fetchFollowProject,
  setHasToggledFollow,
  clearEventSelectionState,
  setHasClickedMultiLanguageEvent,
  updateSelectedProjectState,
  fetchRegisterForEvent
} from '../../store/slices/projects';
import { setIsStakeCommentSubmitted } from '../../store/slices/participate';
import { setIsShowingSystemSnackbarMsg } from '../../store/slices/application';
import styled from 'styled-components';
import { setNavigationTab } from '../../store/slices/application';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RootState } from '../../store';
import { environment } from '../../environment';
import { Helmet } from 'react-helmet';
import SystemSuccessSnackbar from '../Shared/SystemSuccessSnackbar';

const ProjectDetailsTitle = styled.h1`
  text-align: left;
  margin-top: 8px;
  font-size: 16px;
`;

export const ProjectDetailsHeading = styled.h2`
  text-align: left;
  margin-top: 8px;
  font-size: 14px;
`;

const BasicFlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Spacer = styled.div`
  width: 4px;
`;

const FlexCenteredButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  overflow: hidden;
`;

const Project = (props) => {
  const dispatch = useDispatch();
  const {
    selectedProject,
    hasToggledFollow,
    selectedProjectEvents,
    selectedProjectComments,
    loading,
    isLoadingProjectEvents,
    isLoadingProjectComments,
    selectedEventDetails,
    hasClickedMultiLanguageEvent,
    hasErrors,
    errorMsg,
    isAllSelectedProjCommentsLoaded
  } = useSelector((state: RootState) => state).projectsSlice;
  const history = useHistory();
  const { currentPath, isShowingSystemSnackbarMsg } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  const { stakeholderInfo } = useSelector(
    (state: RootState) => state
  ).myProfileSlice;
  const { isStakeCommentSubmitted } = useSelector(
    (state: RootState) => state
  ).participateSlice;
  const { projectId } = props.match.params;
  const [isCopiedProjLink, setIsCopiedProjLink] = useState(false);
  const location = useLocation();
  const handleToggleFollow = async (project) => {
    if (project.IsFollowing) {
      // unfollow happens in ToggleFollowBottomDrawer so they can confirm.
      // only dispatch intent for ToggleFollowBottomDrawer state
      dispatch(setFollowIntent({ project, isFollowIntent: false }));
    } else {
      // follow immediately
      dispatch(setFollowIntent({ project, isFollowIntent: true }));
      dispatch(
        updateSelectedProjectState({
          ...selectedProject,
          IsEmail: true
        })
      );
      await dispatch(
        fetchFollowProject({
          projectId: project.ProjectId,
          isEmail: false,
          isSMS: false,
          isNotify: true // MAS-215 default to in-app notifications
        })
      );
    }
    if (!hasErrors) {
      handleClickOpen();
    }
  };

  const handleClickOpen = () => {
    dispatch(setHasToggledFollow(true));
  };

  const subscribeToHistory = useCallback(() => {
    const unlisten = history.listen((loc, action) => {
      if (action) {
        if (loc.pathname !== currentPath && !loc.pathname.includes(projectId)) {
          dispatch(clearSelectedProject());
          unlisten(); // no longer listen after clearing project on page leave
        }
      }
    });
  }, [currentPath, dispatch, history, projectId]);

  const renderSystemSuccessSnackbar = () => {
    if (isShowingSystemSnackbarMsg === true) {
      return (
        <>
          <SystemSuccessSnackbar
            snackbarText={'Comment successfully submitted!'}
          />
        </>
      );
    }
  };

  useEffect(() => {
    if (
      isStakeCommentSubmitted === true &&
      isShowingSystemSnackbarMsg === false
    ) {
      dispatch(setIsShowingSystemSnackbarMsg(true));
    }
  }, [isStakeCommentSubmitted]);

  useEffect(() => {
    if (!loading && !selectedProject && !selectedProjectEvents) {
      subscribeToHistory();
      dispatch(fetchSelectedProject(projectId));
      dispatch(setNavigationTab('projects'));
      dispatch(
        fetchSelectedProjectEvents({
          projectId
        })
      );
      dispatch(
        fetchSelectedProjectComments({
          projectId,
          start: 0,
          length: 20
        })
      );
    }
  }, [
    dispatch,
    hasErrors,
    loading,
    projectId,
    selectedProject,
    selectedProjectEvents,
    subscribeToHistory
  ]);
  const getEntityProjectTitle = () => {
    switch (environment.entityName) {
      case 'Iowa DOT':
        return selectedProject.ProjectDesc;
      default:
        return selectedProject.ProjectNr;
    }
  };

  return (
    <Container>
      {hasErrors && errorMsg && (
        <div role="alert" style={{ marginTop: 20, color: 'red' }}>
          {errorMsg}
        </div>
      )}
      {!selectedProject ? (
        <>
          <span
            role="alert"
            style={{ position: 'absolute', marginLeft: -9999 }}
          >
            Loading the selected project details
          </span>
          <CircularProgress style={{ margin: 24 }} />
        </>
      ) : (
        <div style={{ width: '90%', maxWidth: 575, margin: '0px 20px' }}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {`Project - ${
                selectedProject.ProjectDesc
                  ? selectedProject.ProjectDesc
                  : 'Project'
              }`}{' '}
              - {environment.entityName} REACH
            </title>
            <link
              rel="canonical"
              href={`${environment.clientUrl}/projects/${selectedProject.ProjectId}`}
            />
          </Helmet>
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 16
              }}
            >
              {/* <SvgIcon
                color="primary"
                component={CommuteIcon}
                // TODO: dynamic imagery based on api
                // {...{
                //   ...(selectedProject.projectImage === 'WarningIcon' && {
                //     component: WarningIcon
                //   }),
                //   ...(selectedProject.projectImage === 'DirectionsBikeIcon' && {
                //     component: DirectionsBikeIcon
                //   }),
                //   ...(selectedProject.projectImage === 'BuildIcon' && {
                //     component: BuildIcon
                //   }),
                //   ...(selectedProject.projectImage === 'CommuteIcon' && {
                //     component: CommuteIcon
                //   }),
                //   ...(selectedProject.projectImage === 'SecurityIcon' && {
                //     component: SecurityIcon
                //   })
                // }}
                style={{ height: 50, width: 50 }}
              ></SvgIcon> */}
              <ProjectDetailsTitle style={{ margin: 0 }}>
                {getEntityProjectTitle()}
              </ProjectDetailsTitle>
              <div>
                <BasicFlexContainer>
                  <ChatBubbleIcon color="primary" />
                  <Spacer />
                  <div
                    aria-label={`Number of comments on project: ${selectedProject.CommentCount}`}
                  >
                    {`${selectedProject.CommentCount}`}
                  </div>
                </BasicFlexContainer>
                <BasicFlexContainer>
                  <ThumbUpIcon color="primary" />
                  <Spacer />
                  <div
                    aria-label={`Project favorability: ${selectedProject.Favorability}%`}
                  >
                    {`${selectedProject.Favorability}%`}
                  </div>
                </BasicFlexContainer>
              </div>
            </div>
            <div
              style={{
                margin: '18px 0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Button
                aria-label={
                  selectedProject.IsFollowing ? 'FOLLOWING' : 'FOLLOW'
                }
                onClick={async () => {
                  await handleToggleFollow(selectedProject);
                }}
                color="secondary"
                style={{
                  fontSize: 10,
                  maxWidth: 75,
                  minWidth: 75,
                  backgroundColor: selectedProject.IsFollowing ? '#21890e' : ''
                }}
                variant="contained"
              >
                {selectedProject.IsFollowing ? 'FOLLOWING' : 'FOLLOW'}
              </Button>{' '}
              <Link
                onClick={() => dispatch(clearSelectedProject())}
                to={`/participate/comment/${projectId}`}
                style={{ textDecoration: 'none' }}
              >
                <Tooltip title="Comment on this project.">
                  <FlexCenteredButton>
                    <div>Comment</div>
                    <IconButton
                      style={{ marginRight: 16 }}
                      aria-label="Comment on this project."
                      color="primary"
                    >
                      <InsertCommentIcon style={{ fontSize: 32 }} />
                    </IconButton>
                  </FlexCenteredButton>
                </Tooltip>
              </Link>
              <Tooltip title="Copy project link to clipboard.">
                <CopyToClipboard
                  text={`${environment.clientUrl}${location.pathname}`}
                  onCopy={() => setIsCopiedProjLink(true)}
                >
                  <FlexCenteredButton>
                    <div>Share</div>
                    <IconButton aria-label="Copy project link to clipboard.">
                      <ShareIcon style={{ fontSize: 32 }} color="primary" />
                    </IconButton>
                  </FlexCenteredButton>
                </CopyToClipboard>
              </Tooltip>
            </div>
            <ProjectNotificationsPreferences />
            <div style={{ textAlign: 'left' }}>
              <ProjectDetailsHeading>
                Location:
                {selectedProject.District > -1 && (
                  <span
                    style={{ fontWeight: 'normal' }}
                  >{` District ${selectedProject.District}`}</span>
                )}
              </ProjectDetailsHeading>
              <ProjectDetailsHeading>
                Description:
                {selectedProject.ProjectDesc.length && (
                  <span
                    style={{ fontWeight: 'normal' }}
                  >{` ${selectedProject.ProjectDesc}`}</span>
                )}
              </ProjectDetailsHeading>
              <ProjectEvents projectId={projectId} />
            </div>
            {selectedProject.MapId && (
              <div style={{ marginBottom: 18 }}>
                <Link
                  style={{ textDecoration: 'none', margin: 20 }}
                  to={{
                    pathname: `/projects/projectmap/${selectedProject.ProjectId}`,
                    state: { mapId: selectedProject.MapId }
                  }}
                >
                  <Button
                    aria-label="View project map"
                    color="primary"
                    variant="contained"
                  >
                    Project Map
                  </Button>
                </Link>
              </div>
            )}
            <div style={{ maxWidth: 575 }}>
              {selectedProjectComments &&
                selectedProjectComments.length > 0 && <ProjectComments />}
              {selectedProjectComments && renderSystemSuccessSnackbar()}
            </div>
          </React.Fragment>
        </div>
      )}
      {hasToggledFollow && selectedProject && (
        <ToggleFollowBottomDrawer project={selectedProject} />
      )}
      {hasClickedMultiLanguageEvent && selectedEventDetails && (
        <LanguageSelectionBottomDrawer />
      )}
      {selectedProject && !isAllSelectedProjCommentsLoaded && <LoadMore />}
      {isCopiedProjLink && (
        <DynamicDialog
          text="Project link copied to clipboard."
          setIsCopiedProjLink={setIsCopiedProjLink}
        />
      )}
    </Container>
  );
};

export default Project;
