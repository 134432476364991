import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { styled as styledMUI } from '@material-ui/core/styles';
import { Spacer } from '../../styled-elements';
import {
  fetchFollowProject,
  setIsNotificationPreferencesOpen,
  setNotificationPrefsSaveOrFailMsg,
  updateSelectedProjectState
} from '../../store/slices/projects';
import { RootState } from '../../store';

const MyAccordionDetails = styledMUI(AccordionDetails)({
  display: 'inline-block',
  width: '100%',
  textAlign: 'left',
  paddingTop: 0
});

const MyAccordion = styledMUI(Accordion)({
  margin: '20px 0px'
});

export default function ProjectNotificationsPreferences(): ReactElement {
  const {
    hasErrors,
    selectedProject,
    isNotificationPreferencesOpen,
    notificationPrefsSaveOrFailMsg,
    loading
  } = useSelector((state: RootState) => state).projectsSlice;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    isEmailNotify: selectedProject.IsEmail,
    isSMSNotify: selectedProject.IsSMS,
    isNotify: selectedProject.IsNotify
  });
  const [isEdit, setIsEdit] = useState(isNotificationPreferencesOpen);
  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleSave = async () => {
    dispatch(
      updateSelectedProjectState({
        ...selectedProject,
        IsEmail: state.isEmailNotify,
        IsSMS: state.isSMSNotify,
        IsNotify: state.isNotify
      })
    );
    await dispatch(
      fetchFollowProject({
        projectId: selectedProject.ProjectId,
        isEmail: state.isEmailNotify,
        isSMS: state.isSMSNotify,
        isNotify: state.isNotify
      })
    );
    if (!hasErrors) {
      dispatch(
        setNotificationPrefsSaveOrFailMsg(
          'Successfully updated notification settings.'
        )
      );
    } else {
      dispatch(
        setNotificationPrefsSaveOrFailMsg(
          'Error saving notification preferences.'
        )
      );
    }
    setTimeout(() => {
      dispatch(setNotificationPrefsSaveOrFailMsg(''));
    }, 2000);
  };
  return (
    <MyAccordion
      expanded={isEdit}
      onChange={() => {
        dispatch(
          setIsNotificationPreferencesOpen(!isNotificationPreferencesOpen)
        );
        setIsEdit(!isEdit);
      }}
    >
      <AccordionSummary
        aria-label="Expand Notification Preferences Panel"
        expandIcon={<ExpandMoreIcon />}
        aria-controls="additional-actions-content"
        id="notification preferences"
      >
        <Typography>Notification Preferences</Typography>
      </AccordionSummary>
      <MyAccordionDetails>
        {/* 
        MAS-244 Hide SMS and Email Notification types from UI
        <FormControlLabel
          control={
            <Checkbox
              checked={state.isEmailNotify}
              onChange={handleChangeCheckbox}
              name="isEmailNotify"
              color="primary"
            />
          }
          label="Email"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.isSMSNotify}
              onChange={handleChangeCheckbox}
              name="isSMSNotify"
              color="primary"
            />
          }
          label="Text/SMS"
        /> */}
        <FormControlLabel
          control={
            <Checkbox
              inputProps={{
                'aria-label': 'toggle in app notifications on or off'
              }}
              
              checked={state.isNotify}
              onChange={handleChangeCheckbox}
              name="isNotify"
              color="primary"
              id="in app"
            />
          }
          label="In App"
          id="in app"
          htmlFor="in app"
        />
        <Spacer />
        <div>
          <Button
            aria-label="Save notification preferences"
            style={{ marginRight: 8 }}
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
          >
            <div>Save</div>
          </Button>
          <Button
            aria-label="Cancel edit notification preferences"
            variant="contained"
            color="secondary"
            onClick={() => {
              setState({
                isEmailNotify: selectedProject.IsEmail,
                isSMSNotify: selectedProject.IsSMS,
                isNotify: selectedProject.IsNotify
              });
              setIsEdit(false);
              dispatch(setIsNotificationPreferencesOpen(false));
            }}
          >
            Cancel
          </Button>
          <div style={{ marginTop: 16 }}>
            {loading && (
              <>
                <span
                  role="alert"
                  style={{ position: 'absolute', marginLeft: -9999 }}
                >
                  Updating notification preferences
                </span>
                <CircularProgress style={{ margin: 24 }} />
              </>
            )}
            {!!notificationPrefsSaveOrFailMsg.length &&
              (notificationPrefsSaveOrFailMsg.includes('Success') ? (
                <div role="alert" style={{ color: 'green' }}>
                  {notificationPrefsSaveOrFailMsg}
                </div>
              ) : (
                <div role="alert" style={{ color: 'red', marginTop: 16 }}>
                  {notificationPrefsSaveOrFailMsg}
                </div>
              ))}
          </div>
        </div>
      </MyAccordionDetails>
    </MyAccordion>
  );
}
