import React, { useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import '../../css/ProjectSelectionFilter.css';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Tooltip
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from '@date-io/moment';
import { useHistory } from 'react-router-dom';
import { SubHeaderGoBack } from '../';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchLUCounties,
  fetchProjects,
  setProjectsFilterSelection,
  setSelectedCounties,
  setSearchText,
  setStartDate,
  setEndDate
} from '../../store/slices/projects';
import {
  setIsShowingSystemSnackbarMsg,
  setNavigationTab
} from '../../store/slices/application';
import Select from 'react-select';
import GenericLookup from '../../models/shared/GenericLookup';
import { environment } from '../../environment';
import { Helmet } from 'react-helmet';

const customSelectStyles = {
  control: (base) => ({
    ...base,
    height: 50,
    minHeight: 50
  })
};

const FormControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px;
  & > * {
    margin-top: 12px;
  }
`;

const MyFormControl = styled(FormControl)`
  text-align: center;
  max-width: 575px;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
`;

export default function ProjectSelectionFilter() {
  const dispatch = useDispatch();
  const { lookups, loading, hasErrors, searchText, startDate, endDate } =
    useSelector((state: RootState) => state).projectsSlice;
  const { isLoadingStakeholder } = useSelector(
    (state: RootState) => state
  ).myProfileSlice;
  const { navigationTab } = useSelector(
    (state: RootState) => state
  ).applicationSlice;

  useEffect(() => {
    if (!loading && !hasErrors && !lookups.LUCounties.length) {
      dispatch(fetchLUCounties());
    }
  }, [dispatch, hasErrors, loading, lookups.LUCounties.length]);

  useEffect(() => {
    if (navigationTab !== 'projects') {
      dispatch(setNavigationTab('projects'));
    }
  }, [dispatch, navigationTab]);

  const [formState, setFormState] = useState<{
    selectedCountiesLocal: GenericLookup[];
    isFollowing: boolean;
    hasEvents: boolean;
  }>({
    selectedCountiesLocal: [],
    isFollowing: false,
    hasEvents: false
  });
  const history = useHistory();
  const handleChange = (event: any, key: string) => {
    setFormState({ ...formState, [key]: event });
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchText(event.target.value));
  };

  const handleDateChange = (date: Date | null, key: string) => {
    if (key === 'startDate') {
      dispatch(setStartDate(date));
    } else if (key === 'endDate') {
      dispatch(setEndDate(date));
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(setProjectsFilterSelection('custom'));
    dispatch(setSelectedCounties(formState.selectedCountiesLocal));
    dispatch(
      fetchProjects({
        isMine: formState.isFollowing,
        start: 0,
        length: 50,
        hasEvents: formState.hasEvents,
        countyIds: formState.selectedCountiesLocal?.map((c) => c.value)
      })
    );
    dispatch(setIsShowingSystemSnackbarMsg(true));
    history.push('/projects/');
  };
  return (
    <form onSubmit={handleSubmit}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project Filters - {environment.entityName} REACH</title>
        <link
          rel="canonical"
          href={`${environment.clientUrl}/projects/filters`}
        />
      </Helmet>
      <SubHeaderGoBack
        goBackLink={'/projects'}
        isArrowBack={true}
        heading="Project Filters"
      />
      <FormControlsContainer>
        {loading || isLoadingStakeholder ? (
          <CircularProgress style={{ margin: '8px 0px' }} />
        ) : (
          <>
            {!!lookups.LUCounties.length &&
              environment.entityName === 'Iowa DOT' && ( // counties only applies to Iowa
                <MyFormControl variant="outlined">
                  <InputLabel shrink htmlFor="counties-select">
                    Counties
                  </InputLabel>
                  <Select
                    styles={customSelectStyles}
                    placeholder=""
                    inputId="counties-select"
                    isMulti
                    id="counties-checkbox"
                    multiple
                    onChange={(selectedOption, triggeredAction) => {
                      handleChange(selectedOption, 'selectedCountiesLocal');
                    }}
                    options={lookups.LUCounties.map((c) => ({
                      value: c.COUNTY_ID,
                      label: c.COUNTY
                    }))}
                    value={formState.selectedCountiesLocal}
                  />
                </MyFormControl>
              )}
            <FlexContainer>
              <FormControlLabel
                control={
                  <Switch
                    checked={formState.isFollowing}
                    onChange={(e) =>
                      handleChange(e.target.checked, 'isFollowing')
                    }
                    color="primary"
                  />
                }
                label="Following"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formState.hasEvents}
                    onChange={(e) =>
                      handleChange(e.target.checked, 'hasEvents')
                    }
                    color="primary"
                  />
                }
                label="Meetings"
              />
            </FlexContainer>

            <MyFormControl variant="outlined">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    margin: '10px'
                  }}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Filter Projects From"
                    value={startDate}
                    onChange={(date) => handleDateChange(date, 'startDate')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    style={{ flex: 1 }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleDateChange(null, 'startDate')}
                    style={{ marginLeft: '10px' }}
                  >
                    Clear
                  </Button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    margin: '10px'
                  }}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Filter Projects To"
                    value={endDate}
                    onChange={(date) => handleDateChange(date, 'endDate')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    minDate={startDate}
                    style={{ flex: 1 }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleDateChange(null, 'endDate')}
                    style={{ marginLeft: '10px' }}
                  >
                    Clear
                  </Button>
                </div>
              </MuiPickersUtilsProvider>
              <div style={{ margin: '10px' }}>
                <Tooltip
                  title="Search by project name, project description, PIN, town/city, or county."
                  placement="top"
                >
                  <TextField
                    fullWidth
                    label="Search Text"
                    variant="outlined"
                    value={searchText}
                    onChange={handleTextChange}
                  />
                </Tooltip>
              </div>
            </MyFormControl>

            <Button
              type="submit"
              aria-label="Go to filtered projects."
              style={{ maxWidth: 575 }}
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmit(e)}
            >
              GO TO FILTERED PROJECTS
            </Button>
          </>
        )}
        {/*
        IOWA-213 - Hide, will add this geospatial functionality at later date.
        <MyFormControl disabled variant="outlined">
          <div className="switchContainer">
            <div>Only show projects within 50 miles of my home</div>
            <Switch
              color="primary"
              checked={nearMyHome}
              onChange={(event) => handleChange(event, 'nearMyHome')}
              value="nearMyHome"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
        </MyFormControl>
        */}
        {/* DEPRECATED FILTER OPTIONS */}
        {/* <MyFormControl variant="outlined" >
            <div className="switchContainer">
              <div>Only show projects I follow</div>
              <Switch
                color="primary"
                checked={following}
                onChange={(event) => handleChange(event, 'following')}
                value="following"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          </MyFormControl> */}
        {/* <MyFormControl variant="outlined" >
            <InputLabel ref={inputLabel} id="outlined-label-town">
              Town
            </InputLabel>
            <Select
              labelId="outlined-label-town"
              id="outlined"
              value={town}
              onChange={(event) => handleChange(event, 'town')}
              labelWidth={labelWidth}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'Town X'}>Town X</MenuItem>
              <MenuItem value={'Town Y'}>Town Y</MenuItem>
            </Select>
          </MyFormControl>
          <MyFormControl variant="outlined" >
            <InputLabel ref={inputLabel} id="outlined-label-mpo">
              MPO
            </InputLabel>
            <Select
              labelId="outlined-label-mpo"
              id="outlined"
              value={mpo}
              onChange={(event) => handleChange(event, 'mpo')}
              labelWidth={labelWidth}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'MPO X'}>MPO X</MenuItem>
              <MenuItem value={'MPO Y'}>MPO Y</MenuItem>
            </Select>
          </MyFormControl> */}
        {/* END DEPRECATED FILTER OPTIONS */}
      </FormControlsContainer>
    </form>
  );
}
