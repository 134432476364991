import styled from 'styled-components';

export default styled.form`
  display: flex;
  border: 1px solid #ddd;
  flex-direction: column;
  padding: 20px;
  margin: 10vw;
  input {
    margin: 15px 0;
    padding: 10px;
  }
`;
