import {
  Grid,
  makeStyles,
  Paper,
  Theme,
  createStyles
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { MyProfileHeading } from './MyProfile';
import { useSelector, useDispatch } from 'react-redux';
import { setIsMine } from '../../store/slices/feed';
import { RootState } from '../../store';
import styled from 'styled-components';

const MyPaper = styled(Paper)`
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 86px;
  width: ${(props: { isMobile: boolean }) =>
    props.isMobile ? '95%' : '575px'};
`;

export default function MyActivity(): ReactElement {
  const { isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      formControl: {
        margin: theme.spacing(0, 2)
      },
      formControlDropdown: {
        width: '100%',
        marginTop: 12
      },
      formControlLabel: {
        textAlign: 'left',
        fontSize: 6
      },
      formLabel: {
        margin: theme.spacing(3),
        color: 'black',
        fontSize: 6
      },
      text: {
        margin: theme.spacing(1, 2),
        listStyle: 'none',
        textAlign: 'left',
        paddingLeft: 0
      },
      inputRoot: {
        '&$disabled': {
          color: '#767676'
        }
      },
      disabled: {}
    })
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const { stakeholderInfo } = useSelector(
    (state: RootState) => state
  ).myProfileSlice;
  return (
    <MyPaper isMobile={isMobile}>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item xs zeroMinWidth>
          <MyProfileHeading>My Activity </MyProfileHeading>
          <ul className={classes.text}>
            <li>
              <Link
                onClick={() => {
                  dispatch(setIsMine(true));
                }}
                to={{
                  pathname: '/feed',
                  search: `?isMine=${true}`
                }}
              >
                {stakeholderInfo.CommentCount} project comments
              </Link>
            </li>
            {/* <li>
              <Link to="/feed">4 comment responses received</Link>
            </li>
            <li>
              <Link to="/participate">2 public meetings attended</Link>
            </li>
            <li>
              <Link to="/participate">1 online public meeting attended</Link>
            </li> */}
            <li>
              <Link to="/projects">
                {stakeholderInfo.ProjectCount} projects followed
              </Link>
            </li>
          </ul>
        </Grid>
      </Grid>
    </MyPaper>
  );
}
