import React, { ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import '../../css/ProjectSelectionFilter.css';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SubHeaderGoBack } from '..';
import Switch from '@material-ui/core/Switch';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Helmet } from 'react-helmet';
import { environment } from '../../environment';

export default function ParticipateCommentFilter(props): ReactElement {
  const { isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        overflow: 'hidden',
        padding: theme.spacing(1, 1),
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: isMobile ? 'space-between' : 'center'
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: '100%'
      },
      selectEmpty: {
        marginTop: theme.spacing(2)
      }
    })
  );

  const classes = useStyles();
  const [following, setFollowing] = React.useState(true);
  const [nearMyHome, setNearMyHome] = React.useState(false);
  const [district, setDistrict] = React.useState('All');
  const [town, setTown] = React.useState('All');
  const [mpo, setMpo] = React.useState('All');
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  const handleChange = (
    event: React.ChangeEvent<{ value: any; checked?: any }>,
    type: any
  ) => {
    const { value, checked } = event.target;
    switch (type) {
      case 'following':
        return setFollowing(checked);
      case 'nearMyHome':
        return setNearMyHome(checked);
      case 'district':
        return setDistrict(value);
      case 'town':
        return setTown(value);
      case 'mpo':
        return setMpo(value);
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Project Comment Selection - {environment.entityName} REACH
        </title>
        <link
          rel="canonical"
          href={`${environment.clientUrl}/participate/comment`}
        />
      </Helmet>
      <div
        style={{
          backgroundColor: '#2b79c6'
        }}
      >
        <SubHeaderGoBack isArrowBack={true} heading="Project Filters" />
      </div>
      <div className={classes.root}>
        <div
          style={{
            maxWidth: 575,
            marginRight: 5,
            paddingRight: 12,
            marginTop: 8
          }}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <div className="switchContainer">
              <div>Only show projects I follow</div>
              <Switch
                color="primary"
                checked={following}
                onChange={(event) => handleChange(event, 'following')}
                value="following"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <div className="switchContainer">
              <div>Only show projects within 50 miles of my home</div>
              <Switch
                color="primary"
                checked={nearMyHome}
                onChange={(event) => handleChange(event, 'nearMyHome')}
                value="nearMyHome"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} id="outlined-label-division">
              District
            </InputLabel>
            <Select
              labelId="outlined-label-district"
              id="outlined"
              value={district}
              onChange={(event) => handleChange(event, 'district')}
              labelWidth={labelWidth}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'District 1 (Lenox)'}>
                District 1 (Lenox)
              </MenuItem>
              <MenuItem value={'District 2 (Northampton'}>
                District 2 (Northampton)
              </MenuItem>
              <MenuItem value={'District 3 (Worcester)'}>
                District 3 (Worcester)
              </MenuItem>
              <MenuItem value={'District 4 (Arlington)'}>
                District 4 (Arlington)
              </MenuItem>
              <MenuItem value={'District 5 (Taunton)'}>
                Distict 5 (Taunton)
              </MenuItem>
              <MenuItem value={'District 6 (Boston)'}>
                District 6 (Boston)
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} id="outlined-label-town">
              Town
            </InputLabel>
            <Select
              labelId="outlined-label-town"
              id="outlined"
              value={town}
              onChange={(event) => handleChange(event, 'town')}
              labelWidth={labelWidth}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'Town X'}>Town X</MenuItem>
              <MenuItem value={'Town Y'}>Town Y</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} id="outlined-label-mpo">
              MPO
            </InputLabel>
            <Select
              labelId="outlined-label-mpo"
              id="outlined"
              value={mpo}
              onChange={(event) => handleChange(event, 'mpo')}
              labelWidth={labelWidth}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'MPO X'}>MPO X</MenuItem>
              <MenuItem value={'MPO Y'}>MPO Y</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <Link
        to={{
          pathname: '/projects',
          search: `?isProjectCommentSelection=${true}`
        }}
        style={{ textDecoration: 'none' }}
      >
        <Button
          aria-label="Go to filtered projects."
          style={{ maxWidth: 575 }}
          variant="contained"
          color="primary"
        >
          GO TO FILTERED PROJECTS
        </Button>
      </Link>
    </React.Fragment>
  );
}
