import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Theme, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetSelectedNotificationState } from '../../store/slices/notifications';
import { ArrowBack } from '@material-ui/icons';

interface Props {
  isArrowBack: boolean;
  heading: string;
  goBackLink?: string;
  isNotificationDetails?: boolean; // this edge case is because notifications doesn't use routing - the pages are changing via state
}

const Container = styled.div`
  width: 100vw;
  display: flex;
  background-color: ${(props: { theme: Theme }) =>
    props.theme.palette.primary.main};
  align-items: center;
  padding: 0px;
`;
const MyH1 = styled.h1`
  font-size: 18px;
  color: white;
  margin: 8px;
  padding: 8px 0px 4px 0px;
  @media only screen and (max-width: 720px) {
    padding: 0px;
  }
`;
const MyArrowBack = styled(ArrowBack)`
  color: white;
  margin-top: 8px;
  @media only screen and (max-width: 720px) {
    margin-top: 4px;
    margin-left: 4px;
  }
`;

export default function GoBack({
  isArrowBack,
  heading,
  goBackLink,
  isNotificationDetails
}: Props): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Container
      style={{ justifyContent: isArrowBack ? 'flex-start' : 'center' }}
    >
      {isArrowBack && (
        <div>
          <a
            href="#"
            onClick={() => {
              if (isNotificationDetails) {
                // this edge case is because notifications doesn't use routing - the pages are changing via state
                dispatch(resetSelectedNotificationState());
              } else {
                goBackLink ? history.push(goBackLink) : history.goBack();
              }
            }}
            aria-label="Back to previous page"
          >
            <Tooltip
              title="Back to previous page"
              style={{ marginLeft: '8px' }}
            >
              <MyArrowBack />
            </Tooltip>
          </a>
        </div>
      )}
      <MyH1>{heading}</MyH1>
    </Container>
  );
}
