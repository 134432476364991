import React, { useEffect } from 'react';
import Routes from './routes.js';
import './App.css';
import { BottomNavbar, Header } from './components/';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import grey from '@material-ui/core/colors/grey';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPath, setIsMobile } from './store/slices/application';
import { RootState } from './store/index.js';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2b79c6'
    },
    secondary: {
      main: '#8D8D8D'
      // main: grey[700]
    }
  },
  // GLOBAL MUI OVERRIDES
  overrides: {
    MuiButton: {
      label: {
        whiteSpace: 'normal'
      }
    },
    MuiSlider: {
      markLabel: {
        fontWeight: 'bold',
        maxWidth: 70,
        whiteSpace: 'break-spaces'
      },
      valueLabel: {
        display: 'flex',
        justifyContent: 'center',
        left: 'calc(-50%) - 4px',
        top: -45,
        padding: '6px 0px',
        borderRadius: 5,
        width: 100,
        background: '#2b79c6',
        color: 'white',
        '& *': {
          width: '100%',
          background: 'transparent'
        }
      }
    },
    MuiTypography: {
      subtitle1: {
        fontWeight: 'bold'
      },
      h6: {
        fontWeight: 'bold'
      },
      h5: {
        fontSize: 15
      }
    },
    MuiInputLabel: {
      outlined: {
        '&$shrink': {
          padding: '0px 4px',
          fontSize: 16,
          backgroundColor: 'white !important'
        }
      }
    },
    MuiDialogTitle: {
      root: {
        color: '#2b79c6'
      }
    }
  }
});

const App: React.FC = () => {
  const { isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  const dispatch = useDispatch();

  const handleResize = () => {
    if (window.innerWidth <= 760 && !isMobile) {
      dispatch(setIsMobile(true));
    }
    if (window.innerWidth > 760 && isMobile) {
      dispatch(setIsMobile(false));
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const history = useHistory();

  useEffect(() => {
    dispatch(setCurrentPath(window.location.pathname));
    history.listen((location, action) => {
      dispatch(setCurrentPath(location.pathname));
    });
  }, [dispatch, history]);

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <StyledComponentsThemeProvider theme={theme}>
          <Header />
          <Routes />
          <BottomNavbar />
        </StyledComponentsThemeProvider>
      </MuiThemeProvider>
    </div>
  );
};

export default App;
