import React, { ReactElement, useEffect } from 'react';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchLoginAmplify,
  fetchLoginREACH,
  fetchLoginOpenID,
  fetchCheckExistingPIMAUserById,
  fetchResendConfirmationCode,
  setUsername
} from '../../../store/slices/user';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from '../../../store';
import DOMPurify from 'dompurify';
// import 'cross-fetch/polyfill';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { config } from '../../../cognito/aws-config';

export default function AmplifyLoginForm(): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedInREACH, loading, hasErrors, errorMsg, foundStakeId } =
    useSelector((state: RootState) => state).userSlice;

  const [formState, setFormState] = React.useState({
    username: '',
    password: ''
  });

  useEffect(() => {
    if (isLoggedInREACH) {
      setTimeout(() => {
        history.push('/projects');
      }, 2000);
    }
  }, [history, isLoggedInREACH]);

  useEffect(() => {
    if (hasErrors && errorMsg) {
      console.error('errorMsg:', errorMsg);
    }
  }, [hasErrors, errorMsg]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = formState;
    await dispatch(fetchLoginAmplify({ username, password }));
    const cognitoId = localStorage.getItem('sub');
    if (cognitoId && foundStakeId) {
      dispatch(fetchLoginOpenID());
    } else if (cognitoId) {
      await dispatch(fetchCheckExistingPIMAUserById());
      await dispatch(fetchLoginOpenID());
    }
  };
  const handleChange = (e) => {
    if (e.target.name === 'username') {
      setFormState({
        ...formState,
        [e.target.name]: DOMPurify.sanitize(e.target.value.toLowerCase())
      });
    } else {
      setFormState({
        ...formState,
        [e.target.name]: DOMPurify.sanitize(e.target.value)
      });
    }
  };

  return (
    <div
      style={{
        marginTop: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {isLoggedInREACH ? (
        <div role="alert">Success! You will be redirected shortly.</div>
      ) : (
        <React.Fragment>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 300
            }}
            onSubmit={handleSubmit}
          >
            <TextField
              inputProps={{ style: { textTransform: 'lowercase' } }}
              fullWidth
              value={formState.username}
              onChange={handleChange}
              label="Email"
              id="email"
              name="username"
              InputLabelProps={{
                id: 'email'
              }}
            />
            <br></br>
            <TextField
              fullWidth
              value={formState.password}
              onChange={handleChange}
              label="Password"
              name="password"
              type="password"
              id="password"
              InputLabelProps={{
                id: 'password'
              }}
            />
            <div style={{ height: 16 }}></div>

            <Button
              disabled={loading}
              aria-label="Log in"
              style={{
                backgroundColor: '#21890e',
                color: 'white'
              }}
              variant="contained"
              type="submit"
            >
              Log in
            </Button>
            {loading && (
              <>
                <span
                  role="alert"
                  style={{ position: 'absolute', marginLeft: -9999 }}
                >
                  Logging in
                </span>
                <CircularProgress style={{ margin: 16 }} />
              </>
            )}
          </form>

          {hasErrors && errorMsg && (
            <React.Fragment>
              <div role="alert" style={{ marginTop: 10, color: 'red' }}>
                {errorMsg === 'User is not confirmed.' ||
                errorMsg === 'Incorrect username or password.' ||
                errorMsg === 'User does not exist.' ||
                errorMsg === 'User is disabled.' ||
                errorMsg === 'Request failed with status code 401'
                  ? errorMsg
                  : ''}
              </div>
              {errorMsg === 'User is not confirmed.' && (
                <React.Fragment>
                  <Link
                    to={{
                      pathname: '/signup/confirmaccount',
                      state: {
                        isConfirmAccount: true
                      }
                    }}
                    style={{ textDecoration: 'none' }}
                    onClick={() => {
                      dispatch(fetchResendConfirmationCode(formState.username));
                      dispatch(setUsername(formState.username));
                    }}
                  >
                    <Button
                      aria-label="Confirm account"
                      style={{
                        backgroundColor: '#21890e',
                        color: 'white',
                        marginRight: 8
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Confirm account
                    </Button>
                  </Link>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
}
