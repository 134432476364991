import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ExistingPIMAUser } from '..';
import { RootState } from '../../store';

export default function ExistingPIMAUserList(): ReactElement {
  const { pimaUsersMatchingSignupForm } = useSelector((state: RootState) => {
    return state;
  }).userSlice;
  return (
    <div>
      <p style={{ textAlign: 'center', fontSize: 14 }}>
        We found you in our records. Please verify that your contact information
        below is correct by selecting "This is me". If the contact is not
        correct, select "This is NOT me".
      </p>
      {pimaUsersMatchingSignupForm.map((user, idx) => {
        return <ExistingPIMAUser key={idx} user={user} />;
      })}
    </div>
  );
}
