import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { RootState } from '..';
import { environment } from '../../environment';
import { fetchLoginREACH, getREACHAuthHeaders, isTokenExpired } from './user';

interface DashComment {
  ProjectId: number;
  ProjectNr: string;
  StakeComment: string;
  StakeCommentDate: string;
  StakeCommentId: number;
  StakeholderName: string;
  CommentLat: number;
  CommentLon: number;
  StakeholderSupportId: number;
}
interface DashboardState {
  loading: boolean;
  hasErrors: boolean;
  errorMsg: string;
  dashComments: DashComment[] | null;
  lookups: {
    stakeholderSupport: any[];
  };
}

const initialState: DashboardState = {
  loading: false,
  hasErrors: false,
  errorMsg: '',
  dashComments: null,
  lookups: {
    stakeholderSupport: []
  }
};

const dashboardlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearDashboardState: (state) => {
      state.dashComments = initialState.dashComments;
    },
    getLUStakeSupport: (state) => {
      state.loading = true;
    },
    getLUStakeSupportSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.lookups.stakeholderSupport = payload;
    },
    getLUStakeSupportFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getDashComments: (state) => {
      state.loading = true;
    },
    getDashCommentsSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.dashComments = payload;
    },
    getDashCommentsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    }
  }
});
export const {
  clearDashboardState,
  getLUStakeSupport,
  getLUStakeSupportSuccess,
  getLUStakeSupportFailure,
  getDashComments,
  getDashCommentsSuccess,
  getDashCommentsFailure
} = dashboardlice.actions;

export const getDashboardState = (state: RootState) => state.dashboard;

export const fetchLUStakeSupport = createAsyncThunk(
  'dashboard/fetchLUStakeSupport',
  async (input, thunkAPI: any) => {
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(getLUStakeSupport());
      const { data } = await axios.get(
        `${environment.apiUrl}/lu_stakeholder_support/get`,
        config
      );
      if (data) {
        thunkAPI.dispatch(getLUStakeSupportSuccess(data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getLUStakeSupportFailure(err.message));
    }
  }
);

export const fetchDashComments = createAsyncThunk(
  'dashboard/fetchDashComments',
  async (input, thunkAPI: any) => {
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(getDashComments());
      const { data } = await axios.get(
        `${environment.apiUrl}/Reach/GetDashboard?userOnly=true`,
        config
      );
      if (data) {
        thunkAPI.dispatch(getDashCommentsSuccess(data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getDashCommentsFailure(err.message));
    }
  }
);

export default dashboardlice.reducer;
