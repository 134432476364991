import React, { ReactElement } from 'react';
import { Button, Drawer } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSelectedProject,
  fetchUnfollowProject,
  setFollowIntent,
  setHasToggledFollow,
  setIsNotificationPreferencesOpen
} from '../../store/slices/projects';
import styled from 'styled-components';
import { Spacer } from '../../styled-elements';
import { styled as styledMUI } from '@material-ui/core/styles';
import { RootState } from '../../store';

const Container = styled.div`
  margin: 16px;
`;
interface Props {
  project;
}
export default function ToggleFollowBottomDrawer({
  project
}: Props): ReactElement {
  const { hasToggledFollow, isFollowIntent, isFollowedProjects } = useSelector(
    (state: RootState) => state
  ).projectsSlice;
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState(hasToggledFollow);

  const closeDrawer = () => (event) => {
    // resource cleanup
    dispatch(setFollowIntent({ project, isFollowIntent: false }));
    dispatch(setHasToggledFollow(false));
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsOpen(false);
  };

  const MyButton = styledMUI(Button)({
    height: 35,
    fontSize: 14,
    marginBottom: 4
  });

  return (
    <Drawer anchor="bottom" open={isOpen} onClose={closeDrawer()}>
      <Container>
        {isFollowIntent ? (
          <React.Fragment>
            <div>Project followed!</div>
            <Spacer />
            <div>
              Do you want to update your notification preferences for this
              project?
            </div>
            <Spacer />
            <MyButton
              aria-label="Accept default notification preferences."
              onClick={() => {
                dispatch(setHasToggledFollow(false));
                closeDrawer();
              }}
              fullWidth
              color="primary"
              variant="contained"
            >
              ACCEPT DEFAULT PREFERENCES
            </MyButton>
            <Link
              to={{
                pathname: `/projects/${project.ProjectId}`,
                state: { project }
              }}
              style={{ textDecoration: 'none' }}
            >
              <MyButton
                aria-label="Update notification preferences."
                onClick={() => {
                  dispatch(clearSelectedProject());
                  dispatch(setIsNotificationPreferencesOpen(true));
                  dispatch(setHasToggledFollow(false));
                  closeDrawer();
                }}
                fullWidth
                color="secondary"
                variant="contained"
              >
                UPDATE PREFERENCES
              </MyButton>
            </Link>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              If you unfollow this project, you will no longer receive project
              updates or notifications of upcoming projects.
            </div>
            <Spacer />
            <div>Are you sure you want to unfollow?</div>
            <Spacer />

            <MyButton
              aria-label="Confirm unfollow project."
              onClick={() => {
                dispatch(setHasToggledFollow(false));
                dispatch(
                  fetchUnfollowProject({
                    projectId: project.ProjectId,
                    isOnFollowingOnlyView: isFollowedProjects // determines how to handle in state
                  })
                );
                closeDrawer();
              }}
              fullWidth
              color="primary"
              variant="contained"
            >
              UNFOLLOW PROJECT
            </MyButton>
            <Link
              to={{
                pathname: `/projects/${project.ProjectId}`,
                state: { project }
              }}
              style={{ textDecoration: 'none' }}
            >
              <MyButton
                aria-label="Update notification preferences."
                onClick={() => {
                  dispatch(clearSelectedProject());
                  dispatch(setIsNotificationPreferencesOpen(true));
                  dispatch(setHasToggledFollow(false));
                  closeDrawer();
                }}
                fullWidth
                color="secondary"
                variant="contained"
              >
                UPDATE PREFERENCES
              </MyButton>
            </Link>
          </React.Fragment>
        )}
      </Container>
    </Drawer>
  );
}
