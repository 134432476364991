import React, { ReactElement, useEffect, useRef } from 'react';
import { loadModules } from 'esri-loader';
import styled from 'styled-components';
import { MapPinImage } from '../../images';
import { environment } from '../../environment';

interface Props {
  MAP_ID: string;
  setProjCommentLatLon?: any; // used in comment form WebMap, not project WebMap
  isProjectCommentMap: boolean;
}

export default React.memo(function WebMap({
  // memoize to prevent rerenders when clicking map
  MAP_ID,
  setProjCommentLatLon,
  isProjectCommentMap // this component is also used in the ProjectMap component
}: Props): ReactElement {
  const mapRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const MapViewComponent = styled.div`
  height: ${isProjectCommentMap ? '500px;' : '100%;'}
  width: 100%;
`; // proj comment form map is smaller
  useEffect(() => {
    let commentPin: any = null;
    const startUp = async () => {
      const [
        MapView,
        WebMap,
        Graphic,
        PictureMarkerSymbol,
        Search,
        LocatorSearchSource,
        SpatialReference,
        Locator
      ] = await loadModules(
        [
          'esri/views/MapView',
          'esri/WebMap',
          'esri/Graphic',
          'esri/symbols/PictureMarkerSymbol',
          'esri/widgets/Search',
          'esri/widgets/Search/LocatorSearchSource',
          'esri/geometry/SpatialReference',
          'esri/tasks/Locator'
        ],
        { css: true }
      );
      let userLocationPin: any = new PictureMarkerSymbol({
        url: MapPinImage,
        height: 45,
        width: 28,
        yoffset: 22.5
      });
      const map = new WebMap({
        portalItem: {
          id: MAP_ID
        },
        basemap: 'topo-vector'
      });

      // load the map view at the ref's DOM node
      let view;
      if (MAP_ID) {
        view = new MapView({
          container: mapRef.current,
          map: map
        });
      } else {
        // if no map ID was provided - set to entity default
        view = new MapView({
          container: mapRef.current,
          map: map,
          center: environment.dashboardMapCenterCoords.split(','),
          zoom: 7
        });
      }

      // search sources
      var worldGeocoder = new LocatorSearchSource({
        locator: new Locator({
          url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',
          outSpatialReference: new SpatialReference({ wkid: 102100 })
        }),
        name: 'US Address/Place',
        categories: ['Address', 'POI', 'Populated Place'],
        countryCode: 'US',
        locationType: 'rooftop',
        maxResults: 25,
        maxSuggestions: 6,
        placeholder: 'Find address or place',
        withinViewEnabled: true
      });

      // search widget
      var searchWidget = new Search({
        view: view,
        sources: [worldGeocoder],
        includeDefaultSources: false,
        autoSelect: false // we handle the zoom/select ourselves
      });

      // search complete callback
      searchWidget.on('search-complete', function (event) {
        searchComplete(event);
      });

      // Adds the search widget below other elements in
      // the top left corner of the view
      view.ui.add(searchWidget, {
        position: 'top-right'
      });

      function searchComplete(event) {
        // check for errors
        if (event.errors.length > 0) {
          // scope.inputSearchError = true;
          // scope.$apply();
          alert(
            'No location matches found. Check the spelling and add more details such as city, state, or zip code.'
          );
          return;
        }
        var results = event.results[0].results;
        // Retrieved GPS from Geo Location service.
        for (var i = 0; i < results.length; i++) {
          if (results[i].feature) {
            if (commentPin) {
              view.graphics.remove(commentPin);
            }

            const x = results[i].feature.geometry.x;
            const y = results[i].feature.geometry.y;
            setProjCommentLatLon({
              lat: y,
              lon: x
            });
            commentPin = new Graphic(
              results[i].feature.geometry,
              userLocationPin
            );
            view.graphics.add(commentPin);
            view.center = results[i].feature.geometry;
            view.zoom = 12;
            return;
          }
        }
      }

      if (isProjectCommentMap) {
        view.on('click', function (evt) {
          const x = evt.mapPoint.x;
          const y = evt.mapPoint.y;
          setProjCommentLatLon({
            lat: y,
            lon: x
          });
          if (commentPin) {
            // create hidden role alert element to give disabled users feedback
            let hiddenAlertDiv = document.createElement('div');
            hiddenAlertDiv.textContent = `map pin set that will be associated to your project comment. latitude ${y} longitude ${x}`;
            hiddenAlertDiv.setAttribute('visibility', 'hidden');
            hiddenAlertDiv.setAttribute('role', 'alert');
            hiddenAlertDiv.style.visibility = 'hidden'; // Actually hides the element, but still works with accessibility tools
            document.body.appendChild(hiddenAlertDiv);
            //
            view.graphics.remove(commentPin);
          }
          commentPin = new Graphic(evt.mapPoint, userLocationPin);
          view.graphics.add(commentPin);
        });
      }

      return () => {
        if (view) {
          // destroy the map view
          view.container = null;
        }
      };
    };
    startUp();
  });
  return <MapViewComponent ref={mapRef} />;
});
