import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface Props {
  localState: any;
  setLocalState: Function;
}

export default function ProjectCommentFormChoices({
  localState,
  setLocalState
}: Props): ReactElement {
  const { project, commentFormState } = useSelector(
    (state: RootState) => state
  ).participateSlice;

  return (
    <>
      {project &&
      commentFormState.commentChoices &&
      project.SelectedCommentChoices.length ? (
        <div>
          <FormControl component="fieldset">
            <FormGroup>
              {project.SelectedCommentChoices.map((c, idx: number) => (
                <FormControlLabel
                  key={idx}
                  control={
                    <Checkbox
                      checked={localState.commentChoices.includes(
                        c.COMMENT_CHOICE_ID
                      )}
                      onChange={(e: any) => {
                        const idToAddOrRemove = project.SelectedCommentChoices.find(
                          (c) => c.COMMENT_CHOICE === e.target.name
                        ).COMMENT_CHOICE_ID;
                        if (e.target.checked) {
                          // add
                          setLocalState({
                            ...localState,
                            commentChoices: [
                              ...localState.commentChoices,
                              idToAddOrRemove
                            ]
                          });
                        } else {
                          // remove
                          setLocalState({
                            ...localState,
                            commentChoices: localState.commentChoices.filter(
                              (id: number) => id !== idToAddOrRemove
                            )
                          });
                        }
                      }}
                      name={c.COMMENT_CHOICE}
                    />
                  }
                  label={c.COMMENT_CHOICE}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      ) : (
        <>
          <span role="alert" style={{ visibility: 'hidden' }}>
            Loading project comment form
          </span>
          <CircularProgress style={{ margin: 24 }} />
        </>
      )}
    </>
  );
}
