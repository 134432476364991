import { createSlice } from '@reduxjs/toolkit';
import { config } from '../../cognito/aws-config';
import Amplify from 'aws-amplify';
import { RootState } from '..';
import { isMobile } from 'react-device-detect'; // initial state from react-device detect, after determined by window width

Amplify.configure(config);
interface ApplicationState {
  navigationTab: string;
  currentPath: string;
  prevPath: string;
  isMobile: boolean;
  isShowingSystemSnackbarMsg: boolean;
}

const initialState: ApplicationState = {
  navigationTab: '',
  currentPath: '',
  prevPath: '',
  isMobile: isMobile,
  isShowingSystemSnackbarMsg: false
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setNavigationTab: (state, { payload }) => {
      state.navigationTab = payload;
    },
    setCurrentPath: (state, { payload }) => {
      state.prevPath = state.currentPath;
      state.currentPath = payload;
    },
    setIsMobile: (state, { payload }) => {
      state.isMobile = payload;
    },
    setIsShowingSystemSnackbarMsg: (state, { payload }) => {
      state.isShowingSystemSnackbarMsg = payload;
    }
  }
});

export const {
  setNavigationTab,
  setCurrentPath,
  setIsMobile,
  setIsShowingSystemSnackbarMsg
} = applicationSlice.actions;

export const getApplicationState = (state: RootState) => state.application;

export default applicationSlice.reducer;
