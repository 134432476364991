import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import {
  WebMap,
  SubHeaderGoBack,
  ProjectCommentFormTopics,
  ProjectCommentFormChoices
} from '../';
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  clearSelectedProject,
  fetchSelectedProject
} from '../../store/slices/projects';
import {
  fetchAllCommentFormLookups,
  fetchCommentFormQuestions,
  fetchPostStakeComment,
  fetchProject,
  fetchSubmitCaptcha,
  resetToInitialState,
  setCommentFormState,
  setProjCommentFormCurrentQuestionID,
  updateStakeCommentPayload
} from '../../store/slices/participate';
import ProjectSupportSlider from './ProjectSupportSlider';
import { VW_STAKEHOLDER_COMMENT } from '../../models/DTOs';
import { setNavigationTab } from '../../store/slices/application';
import { fetchLoggedInStakeholder } from '../../store/slices/myprofile';
import { RootState } from '../../store';
import styled from 'styled-components';
import { environment } from '../../environment';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';

// local state always takes precedence over redux state in terms of comment submission payload
const initialState: any = {
  stakeComment: '',
  commentTopics: [],
  commentChoices: [],
  projectSupportLevel: null,
  selectedResponseTypeId: null
};

const MyH2 = styled.h2`
  ${({ theme }: any) => `
    font-size: 24px;
    color: ${theme.palette.primary.main};
    margin-top: 0px;
  `}
`;

export default function ProjectCommentForm(props): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const [localState, setLocalState] = useState(initialState);

  const {
    project,
    hasErrors,
    errorMsg,
    projectCommentFormQuestions,
    projectCommentFormCurrentQuestionID,
    lookups,
    isStakeCommentSubmitted,
    loading: loadingParticipate,
    stakeCommentPayload,
    commentFormState
  } = useSelector((state: RootState) => state).participateSlice;
  const { selectedProject, loading: loadingProjects } = useSelector(
    (state: RootState) => state
  ).projectsSlice;
  const { isLoadingStakeholder } = useSelector(
    (state: RootState) => state
  ).myProfileSlice;
  const { stakeholderInfo } = useSelector(
    (state: RootState) => state
  ).myProfileSlice;
  const { currentPath, isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;

  const [projCommentLatLon, setProjCommentLatLon] = useState({
    lat: null,
    lon: null
  });
  const resetAllState = useCallback(() => {
    dispatch(resetToInitialState());
    dispatch(clearSelectedProject());
  }, [dispatch]);

  useEffect(() => {
    if (
      isStakeCommentSubmitted &&
      selectedProject &&
      selectedProject.ProjectId
    ) {
      setTimeout(() => {
        history.push(`/projects/${selectedProject.ProjectId}`);
      }, 2500);
    }
  }, [dispatch, isStakeCommentSubmitted, selectedProject]);

  useEffect(() => {
    if (
      project &&
      !commentFormState.commentTopics &&
      !commentFormState.commentChoices
    ) {
      let formattedTopicsResult = {};
      let formattedChoicesResult = {};
      // format comment topics in the way MUI checkboxes expects for local state
      project.SelectedCommentTopics.map(
        (t: any) => (formattedTopicsResult[t.COMMENT_TOPIC] = false)
      );
      project.SelectedCommentChoices.map(
        (c: any) => (formattedChoicesResult[c.COMMENT_CHOICE] = false)
      );
      dispatch(
        setCommentFormState({
          ...commentFormState,
          commentTopics: formattedTopicsResult,
          commentChoices: formattedChoicesResult
        })
      );
    }
  }, [commentFormState, dispatch, project]);

  const subscribeToHistory = useCallback(() => {
    const unlisten = history.listen((loc, action) => {
      if (action) {
        resetAllState();
        if (
          loc.pathname !== currentPath &&
          !loc.pathname.includes(props.match.params.projectId)
        ) {
          unlisten(); // no longer listen after clearing project on page leave
        }
      }
    });
  }, [currentPath, props.match.params.projectId, resetAllState]);

  useEffect(() => {
    if (!isLoadingStakeholder && !stakeholderInfo) {
      dispatch(fetchLoggedInStakeholder());
    }
  }, [dispatch, isLoadingStakeholder, stakeholderInfo]);
  useEffect(() => {
    const projectId = Number(props.match.params.projectId);
    async function asyncWrapper() {
      if (!project || project.PROJECT_ID !== projectId) {
        await dispatch(fetchProject({ projectId })); // fetchCommentFormQuestions depends on the result of fetchProject for filtering
      }
      if (!projectCommentFormQuestions.length) {
        dispatch(fetchCommentFormQuestions({ projectId }));
      }
    }
    if (
      !loadingParticipate &&
      !loadingProjects &&
      !hasErrors &&
      !selectedProject &&
      !projectCommentFormQuestions.length
    ) {
      if (!selectedProject || selectedProject.ProjectId !== projectId) {
        dispatch(fetchSelectedProject(projectId));
      }
      subscribeToHistory();
      dispatch(setNavigationTab('participate'));
      if (!lookups.length) {
        dispatch(fetchAllCommentFormLookups());
      }
      asyncWrapper();
    }
  }, [
    dispatch,
    hasErrors,
    loadingParticipate,
    loadingProjects,
    lookups.length,
    project,
    projectCommentFormQuestions.length,
    props.match.params,
    props.match.params.projectId,
    selectedProject,
    subscribeToHistory
  ]);

  const updateStakeCommentPayloadSwitch = (ModuleName: string) => {
    switch (ModuleName) {
      // need to switch on this to update the comment payload state object in store each click
      case 'Comment': // stake comment
        dispatch(
          updateStakeCommentPayload({
            property: 'STAKE_COMMENT',
            value: localState.stakeComment
          })
        );
        break;
      case 'Response': {
        // requested response type from DOT
        dispatch(
          updateStakeCommentPayload({
            property: 'RESPONSE_REQUEST_TYPE_ID',
            value: localState.selectedResponseTypeId
              ? localState.selectedResponseTypeId
              : commentFormState.selectedResponseTypeId
          })
        );
        break;
      }
      case 'Topics': {
        dispatch(
          updateStakeCommentPayload({
            property: 'CommentTopics',
            value: localState.commentTopics
          })
        );
        break;
      }
      case 'Choices': {
        dispatch(
          updateStakeCommentPayload({
            property: 'CommentChoices',
            value: localState.commentChoices
          })
        );
        break;
      }
      case 'Map':
        // map coordinates
        dispatch(
          updateStakeCommentPayload({
            property: 'COMMENT_LAT',
            value: projCommentLatLon.lat
          })
        );
        dispatch(
          updateStakeCommentPayload({
            property: 'COMMENT_LON',
            value: projCommentLatLon.lon
          })
        );
        break;
      case 'Rating': {
        // project support slider
        dispatch(
          updateStakeCommentPayload({
            property: 'STAKEHOLDER_SUPPORT_ID',
            value: localState.projectSupportLevel
          })
        );
        break;
      }
      default:
        throw new Error('unhandled ModuleName in ProjectCommentForm');
    }
  };

  const renderQuestionSpecificFormFields = (currentQuestionData: any) => {
    switch (currentQuestionData.ModuleName) {
      case 'Comment':
        return (
          <div>
            <TextField
              disabled={loadingParticipate || loadingProjects || hasErrors}
              value={localState.stakeComment}
              onChange={(e) =>
                setLocalState({
                  ...localState,
                  stakeComment: DOMPurify.sanitize(e.target.value)
                })
              }
              label="Comment:"
              helperText="Please do not place any personally identifiable information such as names, phone numbers, or email addresses within this field. Be aware that any comment placed in this field will be available for public consumption."
              fullWidth
              multiline
              rows="8"
              margin="normal"
              InputLabelProps={{
                shrink: true,
                id: 'comment'
              }}
              variant="outlined"
              id="comment"
            />
          </div>
        );
      case 'Topics':
        return (
          <ProjectCommentFormTopics
            localState={localState}
            setLocalState={setLocalState}
          />
        );
      case 'Choices':
        return (
          <ProjectCommentFormChoices
            localState={localState}
            setLocalState={setLocalState}
          />
        );
      case 'Response':
        // MAS-249 - if/else below is controlled by PIMA questions "Checkbox Email Response" field
        if (
          currentQuestionData.ModuleOptions.find(
            (m) => m.NAME === 'Checkbox Email Response'
          ).VALUE === 'true'
        ) {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={commentFormState.selectedResponseTypeId !== 4}
                  onChange={() => {
                    if (commentFormState.selectedResponseTypeId === 4) {
                      dispatch(
                        setCommentFormState({
                          ...commentFormState,
                          selectedResponseTypeId: 2 // respond to me by email
                        })
                      );
                    } else {
                      dispatch(
                        setCommentFormState({
                          ...commentFormState,
                          selectedResponseTypeId: 4 // do not send me a response
                        })
                      );
                    }
                  }}
                  color="primary"
                />
              }
              label="Send me a response"
            />
          );
        } else {
          return (
            <div>
              {lookups.responseTypes.length && (
                <React.Fragment>
                  <InputLabel>Select one</InputLabel>
                  <Select
                    value={commentFormState.selectedResponseTypeId}
                    onChange={(e) =>
                      dispatch(
                        setCommentFormState({
                          ...commentFormState,
                          selectedResponseTypeId: e.target.value
                        })
                      )
                    }
                  >
                    {lookups.responseTypes.map((r, idx) => (
                      <MenuItem key={idx} value={r.RESPONSE_REQUEST_TYPE_ID}>
                        {r.RESPONSE_REQUEST_TYPE}
                      </MenuItem>
                    ))}
                  </Select>
                </React.Fragment>
              )}
            </div>
          );
        }
      case 'Map':
        // this question is filtered out in participate.tsx thunk if no MAP_ID is supplied from PIMA
        return (
          <WebMap
            isProjectCommentMap={true}
            setProjCommentLatLon={setProjCommentLatLon}
            MAP_ID={project.MAP_ID}
          />
        );
      case 'Rating':
        return (
          <ProjectSupportSlider
            projectCommentFormLocalState={localState}
            setProjCommentFormLocalState={setLocalState}
            defaultValue={2}
          />
        );
      case 'Captcha':
        return <div>TODO: add Captcha form</div>;
      /*
      case 'Stakeholder': // this was filtered out in fetchAllCommentFormLookups as we already have the stakeholder info from who's logged in
          return <div>TODO: add Stakeholder form</div>;
      */
      default:
        return null;
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project Comment - {environment.entityName} REACH</title>
        {selectedProject && (
          <link
            rel="canonical"
            href={`${environment.clientUrl}/participate/comment/${selectedProject.ProjectId}`}
          />
        )}
      </Helmet>
      {selectedProject && (
        <SubHeaderGoBack
          isArrowBack={true}
          heading="Project Comment Form"
          goBackLink={`/projects/${selectedProject.ProjectId}`}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: !isMobile && 575,
          padding: 16
        }}
      >
        <MyH2 color="primary">
          {selectedProject && selectedProject.ProjectNr}
        </MyH2>
        <Typography variant="body1" align="left">
          {selectedProject && selectedProject.ProjectDesc}
        </Typography>
        {projectCommentFormCurrentQuestionID <
          projectCommentFormQuestions.length && (
          <div style={{ margin: '20px 0px' }}>
            <FormLabel component="legend">
              {
                projectCommentFormQuestions[projectCommentFormCurrentQuestionID]
                  .TEXT
              }
            </FormLabel>
            <div style={{ margin: '20px 0px' }}>
              {renderQuestionSpecificFormFields(
                projectCommentFormQuestions[projectCommentFormCurrentQuestionID]
              )}
            </div>
          </div>
        )}
      </div>
      {(loadingParticipate || loadingProjects) && (
        <>
          <span
            role="alert"
            style={{ position: 'absolute', marginLeft: -9999 }}
          >
            Loading project comment form
          </span>
          <CircularProgress style={{ margin: 24 }} />
        </>
      )}
      <div style={{ marginBottom: 100 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            aria-label="Go back to the previous question"
            disabled={
              loadingParticipate ||
              loadingProjects ||
              hasErrors ||
              isStakeCommentSubmitted ||
              projectCommentFormCurrentQuestionID === 0
            }
            onClick={() => {
              if (projectCommentFormCurrentQuestionID > 0) {
                dispatch(
                  setProjCommentFormCurrentQuestionID(
                    projectCommentFormCurrentQuestionID - 1
                  )
                );
              }
            }}
            color="secondary"
            variant="contained"
            style={{
              marginRight: '20px'
            }}
          >
            BACK
          </Button>
          <Button
            aria-label={
              projectCommentFormCurrentQuestionID ===
              projectCommentFormQuestions.length - 1
                ? 'Submit comment'
                : 'Save response and continue to next question'
            }
            disabled={
              loadingParticipate ||
              loadingProjects ||
              hasErrors ||
              isStakeCommentSubmitted ||
              (projectCommentFormCurrentQuestionID <
                projectCommentFormQuestions.length - 1 &&
                (!localState.stakeComment ||
                  localState.stakeComment.trim() === ''))
            }
            {...(projectCommentFormCurrentQuestionID ===
            projectCommentFormQuestions.length - 1
              ? {
                  // IS SUBMIT
                  onClick: async () => {
                    const { ModuleName } =
                      projectCommentFormQuestions[
                        projectCommentFormCurrentQuestionID
                      ];
                    updateStakeCommentPayloadSwitch(ModuleName);
                    dispatch(
                      fetchSubmitCaptcha({
                        projectId: selectedProject.ProjectId,
                        stakeholder: stakeholderInfo
                      })
                    );
                    // local state always takes precedence over redux store state, hence the ternaries in payload below
                    const postStakeCommentPayload: VW_STAKEHOLDER_COMMENT = {
                      COMMENT_CATEGORY_ID: 1,
                      COMMENT_LAT: projCommentLatLon.lat
                        ? projCommentLatLon.lat
                        : stakeCommentPayload.COMMENT_LAT,
                      COMMENT_LON: projCommentLatLon.lon
                        ? projCommentLatLon.lon
                        : stakeCommentPayload.COMMENT_LON,
                      COMMENT_SOURCE_ID: 3,
                      CommentChoices: localState.commentChoices.length
                        ? localState.commentChoices
                        : stakeCommentPayload.CommentChoices,
                      CommentTopics: localState.commentTopics.length
                        ? localState.commentTopics
                        : stakeCommentPayload.CommentTopics,
                      IS_RESPONSE_NEEDS_APPROVAL: 0,
                      NOTES: '',
                      PE_ID: null,
                      PE_GUID: null,
                      PROJECT_ID: selectedProject.ProjectId,
                      PROJECT_NR: null,
                      RESPONSE_REQUEST_TYPE_ID:
                        commentFormState.selectedResponseTypeId,
                      STAKE_COMMENT: localState.stakeComment.length
                        ? localState.stakeComment
                        : stakeCommentPayload.STAKE_COMMENT,
                      STAKE_ID: stakeholderInfo.STAKE_ID,
                      STAKEHOLDER_SUPPORT_ID:
                        localState.projectSupportLevel !== null
                          ? localState.projectSupportLevel
                          : stakeCommentPayload.STAKEHOLDER_SUPPORT_ID,
                      TONE_ID: null
                    };
                    dispatch(
                      fetchPostStakeComment({
                        stakeId: stakeholderInfo.STAKE_ID,
                        payload: postStakeCommentPayload
                      })
                    );
                  }
                }
              : {
                  // IS CONTINUE
                  onClick: () => {
                    window.scrollTo(0, 0);
                    const { ModuleName } =
                      projectCommentFormQuestions[
                        projectCommentFormCurrentQuestionID
                      ];
                    updateStakeCommentPayloadSwitch(ModuleName);
                    dispatch(
                      setProjCommentFormCurrentQuestionID(
                        projectCommentFormCurrentQuestionID + 1
                      )
                    );
                  }
                })}
            color="primary"
            variant="contained"
          >
            {projectCommentFormCurrentQuestionID <
            projectCommentFormQuestions.length - 1
              ? 'CONTINUE'
              : 'SUBMIT'}

            {projectCommentFormCurrentQuestionID <
              projectCommentFormQuestions.length - 1 && (
              <ArrowForwardIcon style={{ paddingLeft: 8 }} />
            )}
          </Button>
        </div>
        {isStakeCommentSubmitted && (
          <div
            role="alert"
            style={{ margin: '8px 0px', padding: 16, color: 'green' }}
          >
            Success! You will be redirected to the project you commented on
            shortly.
          </div>
        )}
        {hasErrors && errorMsg && (
          <div role="alert" style={{ margin: '8px 0px', color: 'red' }}>
            {errorMsg}
          </div>
        )}
        {!isStakeCommentSubmitted && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {projectCommentFormCurrentQuestionID <
              projectCommentFormQuestions.length && (
              <div
                style={{
                  width: 132,
                  borderRadius: 5,
                  color: 'white',
                  backgroundColor: '#767676',
                  marginTop: 16,
                  padding: 8
                }}
              >
                {`${projectCommentFormCurrentQuestionID} of ${projectCommentFormQuestions.length} Answered`}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
