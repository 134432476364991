import React, { ReactElement, useEffect } from 'react';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { ExistingPIMAUserList, SubHeaderGoBack } from '../../';
import {
  fetchAmplifySignup,
  fetchConfirmAmplifySignup,
  fetchCheckExistingPIMAUserAndSignUp,
  setUsername,
  setSignUpFormData,
  fetchSignupPIMA,
  fetchConfirmSignupAll
} from '../../../store/slices/user';
import history from '../../../history';
import { styled as styledMUI } from '@material-ui/core/styles';
import { PolicyAgreements } from '../../../styled-elements';
import { RootState } from '../../../store';
import styled from 'styled-components';
import { getEntityPrivacyPolicy } from '../LandingPage';
import DOMPurify from 'dompurify';

export const TextFieldHalfWidth = styledMUI(TextField)({
  width: 'calc(50% - 4px)'
});

export const TextFieldThirdWidth = styledMUI(TextField)({
  width: 'calc(33% - 5px)'
});

const MyH1 = styled.h1`
  text-align: left;
  font-size: 20px;
`;

export default function AmplifySignUpForm(): ReactElement {
  const {
    isLoggedInREACH,
    userInfo,
    loading,
    hasErrors,
    errorMsg,
    pimaUsersMatchingSignupForm,
    selectedPIMAUser
  } = useSelector((state: RootState) => {
    return state;
  }).userSlice;
  useEffect(() => {
    if (
      pimaUsersMatchingSignupForm &&
      pimaUsersMatchingSignupForm.length === 0
    ) {
      history.push('/signup/confirmaccount');
    }
  }, [pimaUsersMatchingSignupForm]);
  const dispatch = useDispatch();

  const [newAccountFormState, setNewAccountFormState] = React.useState({
    email: '',
    password: '',
    confirmationCode: ''
  });

  const [signUpFormState, setSignUpFormState] = React.useState({
    email: '',
    lastname: '',
    zipcode: '',
    firstname: '',
    address: '',
    city: '',
    state: ''
  });

  const handleChangeSignUpForm = (e) => {
    if (e.target.name === 'email') {
      setSignUpFormState({
        ...signUpFormState,
        [e.target.name]: DOMPurify.sanitize(e.target.value.toLowerCase())
      });
    } else {
      setSignUpFormState({
        ...signUpFormState,
        [e.target.name]: DOMPurify.sanitize(e.target.value)
      });
    }
  };

  const handleChangeNewAccount = (e) => {
    if (e.target.name === 'email') {
      setNewAccountFormState({
        ...newAccountFormState,
        [e.target.name]: DOMPurify.sanitize(e.target.value.toLowerCase())
      });
    } else {
      setNewAccountFormState({
        ...newAccountFormState,
        [e.target.name]: DOMPurify.sanitize(e.target.value)
      });
    }
  };

  const handleSubmitNewAccount = async (e) => {
    e.preventDefault();
    const { email, password, confirmationCode } = newAccountFormState;
    dispatch(
      fetchConfirmSignupAll({
        email,
        password,
        confirmationCode,
        firstName: userInfo.signUpFormData.firstName,
        lastName: userInfo.signUpFormData.lastName,
        address: userInfo.signUpFormData.address,
        city: userInfo.signUpFormData.city,
        state: userInfo.signUpFormData.state,
        zipcode: userInfo.signUpFormData.zipcode
      })
    );
  };

  const handleSignupCheckForExistingAccount = (e) => {
    e.preventDefault();
    const { email, lastname, zipcode } = signUpFormState;
    dispatch(setUsername(email)); // for other components to grab
    dispatch(
      setSignUpFormData({
        email: signUpFormState.email,
        firstName: signUpFormState.firstname,
        lastName: signUpFormState.lastname,
        address: signUpFormState.address,
        city: signUpFormState.city,
        state: signUpFormState.state,
        zipcode: signUpFormState.zipcode
      })
    );
    if (!userInfo.isSignedUpAmplify) {
      dispatch(
        fetchCheckExistingPIMAUserAndSignUp({
          email,
          lastname,
          zipcode
        })
      );
    }
  };

  return (
    <React.Fragment>
      <SubHeaderGoBack
        isArrowBack={true}
        goBackLink={''}
        heading="Sign up - REACH"
      />
      <div
        style={{
          marginTop: 24,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {isLoggedInREACH ? (
          <div role="alert">Success! You will be redirected shortly.</div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 575,
              margin: '0px 20px'
            }}
          >
            {userInfo.isSignedUpAmplify ? ( // CONFIRMATION SCREEN
              <React.Fragment>
                <div style={{ margin: 20 }}>
                  A confirmation code will be emailed to you shortly to confirm
                  your account. Please confirm your email address and enter the
                  code here.
                </div>
                <form onSubmit={handleSubmitNewAccount}>
                  <TextField
                    value={newAccountFormState.email}
                    inputProps={{ style: { textTransform: 'lowercase' } }}
                    fullWidth
                    onChange={handleChangeNewAccount}
                    label="Email"
                    name="email"
                    id="email"
                  />
                  <TextField
                    value={newAccountFormState.password}
                    fullWidth
                    onChange={handleChangeNewAccount}
                    label="Password"
                    name="password"
                    type="password"
                    id="password"
                  />
                  <TextField
                    value={newAccountFormState.confirmationCode}
                    fullWidth
                    onChange={handleChangeNewAccount}
                    label="Confirmation Code"
                    name="confirmationCode"
                    id="confirmation code"
                  />
                  <div style={{ height: 16 }}></div>
                  <Button
                    disabled={loading}
                    aria-label="Confirm"
                    style={{
                      backgroundColor: '#21890e',
                      color: 'white'
                    }}
                    variant="contained"
                    type="submit"
                  >
                    Confirm
                  </Button>
                </form>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!pimaUsersMatchingSignupForm && ( // SIGN UP SCREEN AT /SIGNUP
                  <div style={{ marginBottom: 100 }}>
                    <PolicyAgreements>
                      {getEntityPrivacyPolicy()}
                    </PolicyAgreements>
                    <form onSubmit={handleSignupCheckForExistingAccount}>
                      <TextField
                        value={signUpFormState.email}
                        inputProps={{ style: { textTransform: 'lowercase' } }}
                        required
                        onChange={handleChangeSignUpForm}
                        fullWidth
                        type="email"
                        label="Email"
                        name="email"
                        id="email"
                        InputLabelProps={{
                          id: 'email'
                        }}
                      />
                      <TextFieldHalfWidth
                        value={signUpFormState.firstname}
                        style={{ marginRight: 8 }}
                        required
                        onChange={handleChangeSignUpForm}
                        label="First Name"
                        name="firstname"
                        id="first name"
                        InputLabelProps={{
                          id: 'first name'
                        }}
                      />
                      <TextFieldHalfWidth
                        value={signUpFormState.lastname}
                        required
                        onChange={handleChangeSignUpForm}
                        label="Last Name"
                        name="lastname"
                        id="last name"
                        InputLabelProps={{
                          id: 'last name'
                        }}
                      />
                      <TextField
                        value={signUpFormState.address}
                        onChange={handleChangeSignUpForm}
                        fullWidth
                        label="Address"
                        name="address"
                        id="address"
                        InputLabelProps={{
                          id: 'address'
                        }}
                      />
                      <TextFieldThirdWidth
                        value={signUpFormState.city}
                        style={{ marginRight: 8 }}
                        onChange={handleChangeSignUpForm}
                        fullWidth
                        label="City"
                        name="city"
                        id="city"
                        InputLabelProps={{
                          id: 'city'
                        }}
                      />
                      <TextFieldThirdWidth
                        value={signUpFormState.state}
                        style={{ marginRight: 8 }}
                        onChange={handleChangeSignUpForm}
                        fullWidth
                        label="State"
                        name="state"
                        id="state"
                        InputLabelProps={{
                          id: 'state'
                        }}
                      />
                      <TextFieldThirdWidth
                        value={signUpFormState.zipcode}
                        required
                        onChange={handleChangeSignUpForm}
                        fullWidth
                        label="Zip"
                        name="zipcode"
                        id="zipcode"
                        InputLabelProps={{
                          id: 'zipcode'
                        }}
                      />
                      <div style={{ height: 16 }}></div>
                      <Button
                        aria-label="Sign up."
                        style={{
                          backgroundColor: '#21890e',
                          color: 'white'
                        }}
                        variant="contained"
                        type="submit"
                      >
                        Sign Up
                      </Button>
                    </form>
                  </div>
                )}
                {pimaUsersMatchingSignupForm &&
                  pimaUsersMatchingSignupForm.length && (
                    <div>
                      <h3 style={{ textAlign: 'left' }}>Connect</h3>
                      <PolicyAgreements>
                        {getEntityPrivacyPolicy()}
                      </PolicyAgreements>
                      <ExistingPIMAUserList />
                    </div>
                  )}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
      {hasErrors && errorMsg && (
        <div role="alert" style={{ marginTop: 10, color: 'red' }}>
          {errorMsg === 'User is not confirmed.' ||
          errorMsg === 'Incorrect username or password.' ||
          errorMsg === 'User does not exist.' ||
          errorMsg === 'User is disabled.' ||
          errorMsg === 'Request failed with status code 401'
            ? errorMsg
            : ''}
        </div>
      )}
      {loading && (
        <>
          <span
            role="alert"
            style={{ position: 'absolute', marginLeft: -9999 }}
          >
            Signing up
          </span>
          <CircularProgress style={{ margin: 16 }} />
        </>
      )}
    </React.Fragment>
  );
}
