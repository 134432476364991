import { Button, Grid, Paper, SvgIcon, Tooltip } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../../store';
import styled from 'styled-components';
import CommuteIcon from '@material-ui/icons/Commute';
import { Spacer } from '../../../styled-elements';
import { ThemeProps } from '../../../styled-elements/Icons';
// import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { ReactComponent as CalendarIconArrow } from '../../../images/calendar-icon-arrow.svg';
import { ReactComponent as CalendarIconCheckmark } from '../../../images/calendar-icon-checkmark.svg';
// import { ReactComponent as CalendarIconX } from '../../../images/calendar-icon-x.svg';

const MyH2 = styled.h2`
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin: 0px;
`;
const MyH3 = styled.h3`
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin: 0px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

interface Props {
  project;
  handleToggleFollow: Function;
}

const Container = styled.div`
  position: relative;
  margin: 0px 8px;
`;

const FollowButtonContainer = styled.div`
  padding-left: 12px;
  padding-right: 20px;
  display: flex;
  position: absolute;
  top: 20px;
  right: 0px;
`;

const MySvgIcon = styled(SvgIcon)`
  height: 25px;
  width: 25px;
`;

const ProjectInfoContainer = styled.div`
  font-size: 12px;
  text-align: left;
`;

export default function MaineDOTProjectCard({
  project,
  handleToggleFollow
}: Props): ReactElement {
  const { isProjectCommentSelection } = useSelector(
    (state: RootState) => state
  ).projectsSlice;
  const { isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;

  const MyPaper = styled(Paper)`
    ${({ theme }: ThemeProps) => `
    width: ${!isMobile && '575px'};
    margin: 8px auto;
    padding: 8px;
  `}
  `;

  const FollowButton = styled(Button)`
    position: relative;
    font-size: 10px;
    max-width: 75px;
    min-width: 75px;
    background-color: ${project.IsFollowing ? '#21890e' : ''};
  `;

  let isMeetingInPast = false;
  if (project.MeetingDate) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const meetingDateParts = project.MeetingDate.split('/');
    const meetingDate = new Date(
      +meetingDateParts[2],
      +meetingDateParts[0] - 1,
      +meetingDateParts[1]
    );
    isMeetingInPast = meetingDate < today;
  }

  return (
    <Container>
      <Link
        to={
          isProjectCommentSelection
            ? {
                pathname: `/participate/comment/${project.ProjectId}`,
                state: { project }
              }
            : {
                pathname: `/projects/${project.ProjectId}`,
                state: { project }
              }
        }
        style={{ textDecoration: 'none' }}
      >
        <MyPaper>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <IconContainer>
                {/* <MySvgIcon
                  color="primary"
                  component={CommuteIcon}
                  // TODO: conditionally determine project image based on attribute or get from API
                  // {...{
                  //   ...(project.projectImage === 'WarningIcon' && {
                  //     component: WarningIcon
                  //   }),
                  //   ...(project.projectImage ===
                  //     'DirectionsBikeIcon' && {
                  //     component: DirectionsBikeIcon
                  //   }),
                /> */}
                {project.MeetingDate && !isMeetingInPast && (
                  <Tooltip title="This project has an upcoming event.">
                    <div style={{ width: '25px', height: '25px' }}>
                      <CalendarIconArrow height="25px" width="25px" />
                    </div>
                  </Tooltip>
                )}
                {project.MeetingDate && isMeetingInPast && (
                  <Tooltip title="This project has an event in the past.">
                    <div style={{ width: '25px', height: '25px' }}>
                      <CalendarIconCheckmark height="25px" width="25px" />
                    </div>
                  </Tooltip>
                )}
                {!project.MeetingDate && (
                  <div style={{ width: '25px', height: '25px' }}></div>
                )}
              </IconContainer>
            </Grid>
            <Grid item xs={7} zeroMinWidth>
              <MyH2>{project.ProjectNr}</MyH2>
              <ProjectInfoContainer>
                <MyH3>{`Project #: ${project.Pin} `}</MyH3>
                <Spacer />
                <MyH3>{project.ProjectDesc}</MyH3>
              </ProjectInfoContainer>
            </Grid>
          </Grid>
        </MyPaper>
      </Link>
      <FollowButtonContainer>
        <FollowButton
          aria-label={project.IsFollowing ? 'FOLLOWING' : 'FOLLOW'}
          onClick={async () => {
            await handleToggleFollow(project);
          }}
          color="secondary"
          variant="contained"
        >
          {project.IsFollowing ? 'FOLLOWING' : 'FOLLOW'}
        </FollowButton>
      </FollowButtonContainer>
    </Container>
  );
}
