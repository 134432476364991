import {
  Button,
  ListItem,
  ListItemAvatar,
  Paper,
  SvgIcon,
  Typography
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import { NotificationDTO } from '../../models/DTOs';
import { styled as styledMUI } from '@material-ui/core/styles';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  fetchCommentDetails,
  fetchMarkReadNotification,
  setSelectedNotification
} from '../../store/slices/notifications';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import DoneIcon from '@material-ui/icons/Done';
import styled from 'styled-components';
interface Props {
  notification: NotificationDTO;
  data: any; // contains IDs for further action ie: CommentId, ResponseId, stakeId
}

const MyButton = styledMUI(Button)({
  display: 'block',
  textTransform: 'none',
  width: '100%'
});

const MyPaper = styled(Paper)`
  max-width: calc(100% - 16px);
  @media only screen and (min-width: 720px) {
    width: 575px;
  }
  margin: 8px auto;
`;

export default function Notification({
  notification,
  data
}: Props): ReactElement {
  const dispatch = useDispatch();
  const { NotificationType, Message, CreatedDate, NotificationId, IsNoteSeen } =
    notification;
  const handleClick = () => {
    dispatch(setSelectedNotification(notification));
    switch (NotificationType) {
      case 1:
        // reason for syntax below explained https://eslint.org/docs/rules/no-prototype-builtins
        if (Object.prototype.hasOwnProperty.call(data, 'CommentId')) {
          dispatch(fetchMarkReadNotification(NotificationId));
          dispatch(fetchCommentDetails(data.CommentId));
        }
        break;
      default:
        throw new Error('unhandled NotificationType case');
    }
  };

  return (
    <MyPaper>
      <MyButton aria-label="Open notification details" onClick={handleClick}>
        <ListItem
          style={{
            paddingBottom: 0,
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <ListItemAvatar>
            <SvgIcon
              color="primary"
              {...{
                ...(NotificationType === 1 && {
                  component: QuestionAnswerIcon
                })
                // ...(projectPhoto === 'DirectionsBikeIcon' && {
                //   component: DirectionsBikeIcon
                // }),
                // ...(projectPhoto === 'BuildIcon' && {
                //   component: BuildIcon
                // }),
                // ...(projectPhoto === 'CommuteIcon' && {
                //   component: CommuteIcon
                // }),
                // ...(projectPhoto === 'SecurityIcon' && {
                //   component: SecurityIcon
                // })
              }}
              style={{ height: 25, width: 25, paddingRight: 0 }}
            />
          </ListItemAvatar>
          <div>
            <Typography
              style={{ wordWrap: 'break-word' }}
              variant="body1"
              color="secondary"
            >
              {Message}
            </Typography>
            <br />
            <Typography component="span" variant="body2" color="secondary">
              {' '}
              {moment(CreatedDate).fromNow()}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div>
              <SvgIcon
                color="primary"
                component={IsNoteSeen ? DoneIcon : MarkunreadMailboxIcon}
              />
            </div>
            <Typography
              color="secondary"
              variant="body2"
              style={{ position: 'absolute', bottom: 0 }}
            >
              {IsNoteSeen ? 'Read' : 'Unread'}
            </Typography>
          </div>
        </ListItem>
      </MyButton>
    </MyPaper>
  );
}
