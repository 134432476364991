import React, { SyntheticEvent } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setIsShowingSystemSnackbarMsg } from '../../store/slices/application';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {
  snackbarText: string;
}
export default function SystemSuccessSnackbar({ snackbarText }: Props) {
  const { isShowingSystemSnackbarMsg } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  const dispatch = useDispatch();
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setIsShowingSystemSnackbarMsg(false));
  };

  return (
    <div>
      <Snackbar
        key={'top center'}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isShowingSystemSnackbarMsg}
        autoHideDuration={3000}
        onClose={(event: SyntheticEvent<Element, Event>) => {
          handleClose(event);
          dispatch(setIsShowingSystemSnackbarMsg(false));
        }}
      >
        <Alert
          onClose={(event: SyntheticEvent<Element, Event>) => {
            handleClose(event);
            dispatch(setIsShowingSystemSnackbarMsg(false));
          }}
          severity="success"
        >
          {snackbarText}
        </Alert>
      </Snackbar>
    </div>
  );
}
