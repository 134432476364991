import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import ProjectHeaderNav from './ProjectHeaderNav';
import {
  LoadMore,
  SubHeaderGoBack,
  ToggleFollowBottomDrawer
} from '../';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchFollowProject,
  fetchProjects,
  setFollowIntent,
  setHasToggledFollow,
  setIsProjectCommentSelection
} from '../../store/slices/projects';
import { setNavigationTab } from '../../store/slices/application';
import { resetFeed } from '../../store/slices/feed';
import { AccountForBottomNavMargin, PaddedDiv } from '../../styled-elements';
import { RootState } from '../../store';
import styled from 'styled-components';
import SystemSuccessSnackbar from '../Shared/SystemSuccessSnackbar';
import { environment } from '../../environment';
import IowaDOTProjectCard from './ProjectCard/IowaDOTProjectCard';
import MaineDOTProjectCard from './ProjectCard/MaineDOTProjectCard';
import MassDOTProjectCard from './ProjectCard/MassDOTProjectCard';
import CTDOTProjectCard from './ProjectCard/CTDOTProjectCard';
import { Helmet } from 'react-helmet';

const HiddenH1 = styled.h1`
  display: none; // MAS-283 this is hidden for accessibility purposes
`;

const Container = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ProjectListContainer = styled.div`
  width: 100%;
  @media only screen and (min-width: 720px) {
    width: 575px;
  }
`;

export default function AllProjectsList(props) {
  const handleToggleFollow = async (project) => {
    if (project.IsFollowing) {
      // unfollow happens in ToggleFollowBottomDrawer so they can confirm.
      // only dispatch intent for ToggleFollowBottomDrawer state
      dispatch(setFollowIntent({ project, isFollowIntent: false }));
    } else {
      // follow immediately
      dispatch(setFollowIntent({ project, isFollowIntent: true }));
      await dispatch(
        fetchFollowProject({
          projectId: project.ProjectId,
          isEmail: false,
          isSMS: false,
          isNotify: true // MAS-215 default to in-app notifications
        })
      );
    }
    if (!hasErrors) {
      handleClickOpen();
    }
  };

  const getEntityProjectCard = (project, idx) => {
    switch (environment.entityName) {
      case 'Iowa DOT':
        return (
          <IowaDOTProjectCard
            handleToggleFollow={handleToggleFollow}
            project={project}
            key={idx}
          />
        );
      case 'Mass DOT':
        return (
          <MassDOTProjectCard
            handleToggleFollow={handleToggleFollow}
            project={project}
            key={idx}
          />
        );
      case 'Maine DOT':
        return (
          <MaineDOTProjectCard
            handleToggleFollow={handleToggleFollow}
            project={project}
            key={idx}
          />
        );
      case 'Connecticut DOT':
        return (
          <CTDOTProjectCard
            handleToggleFollow={handleToggleFollow}
            project={project}
            key={idx}
          />
        );
      default:
        throw new Error(
          'unhandled case in AllProjectsList -> getEntityProjectCard()'
        );
    }
  };

  const dispatch = useDispatch();
  const { isLoggedInREACH } = useSelector(
    (state: RootState) => state
  ).userSlice;
  const {
    loading,
    hasErrors,
    projects,
    errorMsg,
    isFollowedProjects,
    hasToggledFollow,
    projectToFollowOrUnfollow,
    isProjectCommentSelection,
    isAllProjectsLoaded,
    projectsFilterSelection,
    customProjectsFilterSelection
  } = useSelector((state: RootState) => state).projectsSlice;

  const { currentPath, prevPath, isShowingSystemSnackbarMsg } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  const { comments } = useSelector((state: RootState) => state).feedSlice;

  useEffect(() => {
    // reset feed when coming to projects page from feed
    // need updated feed in case user modifies their followed projects
    if (currentPath === '/projects' && prevPath === '/feed' && comments) {
      dispatch(resetFeed());
    }
  }, [comments, currentPath, dispatch, prevPath]);

  useEffect(() => {
    const isCommentSelection = new URLSearchParams(props.location.search).get(
      'isProjectCommentSelection'
    );
    if (isCommentSelection) {
      dispatch(setIsProjectCommentSelection(true));
    } else {
      dispatch(setIsProjectCommentSelection(false));
    }
  }, [projects, isProjectCommentSelection, props.location.search, dispatch]);
  useEffect(() => {
    if (
      localStorage.getItem('reachAuth') &&
      isLoggedInREACH &&
      !projects &&
      !hasErrors &&
      !loading
    ) {
      dispatch(setNavigationTab('projects'));
      //v2
      dispatch(
        fetchProjects({
          isMine: false,
          start: 0,
          length: 50,
          hasEvents: false,
          countyIds: []
        })
      ); // initial fetch (default to all)
      //v1
      // dispatch(
      //   fetchProjects({
      //     isMine: true,
      //     start: 0,
      //     length: 50,
      //     hasEvents: false,
      //     countyIds: []
      //   })
      // ); // initial fetch (default to following)
    }
  }, [dispatch, projects, hasErrors, isLoggedInREACH, loading]);

  const handleClickOpen = () => {
    dispatch(setHasToggledFollow(true));
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Projects - {environment.entityName} REACH</title>
        <link rel="canonical" href={`${environment.clientUrl}/projects`} />
      </Helmet>
      <Container>
        <ProjectListContainer>
          {!isProjectCommentSelection && !loading && <ProjectHeaderNav />}
          {isProjectCommentSelection && (
            <SubHeaderGoBack
              isArrowBack={false}
              heading="Choose a Project to Comment"
            />
          )}
          {loading && (
            <>
              <span
                role="alert"
                style={{ position: 'absolute', marginLeft: -9999 }}
              >
                Loading projects list
              </span>
              <CircularProgress style={{ marginTop: 16 }} />
            </>
          )}
          {hasErrors && errorMsg && (
            <div role="alert" style={{ marginTop: 10, color: 'red' }}>
              {errorMsg}
            </div>
          )}
          {Array.isArray(projects) &&
            projects.map((project, idx) => getEntityProjectCard(project, idx))}
        </ProjectListContainer>
        <HiddenH1 />
        {hasToggledFollow && projectToFollowOrUnfollow && (
          <ToggleFollowBottomDrawer project={projectToFollowOrUnfollow} />
        )}
        {isFollowedProjects && projects && projects.length === 0 && (
          <PaddedDiv>
            You aren't following any projects. Click "All" to see a list of
            projects you can follow.
          </PaddedDiv>
        )}

        {/* MAS-224 - temporarily hide items before UAT - Plus button for "quick project comment" */}
        {/* {!isProjectCommentSelection && (
        <div>
          <PlusButtonBottomDrawer />
        </div>
      )} */}
        {/* to allow full vertical scroll */}
        {/* end */}
      </Container>
      <div>
        {!loading && (
          <>
            {!isAllProjectsLoaded && <LoadMore />}
            {isShowingSystemSnackbarMsg && (
              <>
                {customProjectsFilterSelection && (
                  <SystemSuccessSnackbar snackbarText="Done" />
                )}
              </>
            )}
            {/* Paginator */}
            {projects && projects.length === 0 && (
              <div>
                Project list is empty based on the current filter selection.
              </div>
            )}
          </>
        )}
      </div>
      <AccountForBottomNavMargin />
    </>
  );
}
