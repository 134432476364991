import React, { ReactElement } from 'react';
import { Button, Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearEventSelectionState,
  fetchRegisterForEvent
} from '../../store/slices/projects';
import styled from 'styled-components';
import languages from '../../lookups/languages';
import { styled as styledMUI } from '@material-ui/core/styles';
import { RootState } from '../../store';

const Container = styled.div`
  margin: 16px;
`;

export default function LanguageSelectionBottomDrawer(): ReactElement {
  const { hasClickedMultiLanguageEvent, selectedEventDetails } = useSelector(
    (state: RootState) => state
  ).projectsSlice;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(hasClickedMultiLanguageEvent);

  const closeDrawer = () => (event) => {
    // resource cleanup
    dispatch(clearEventSelectionState());
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsOpen(false);
  };

  const MyButton = styledMUI(Button)({
    height: 35,
    fontSize: 14,
    marginBottom: 4
  });

  return (
    <Drawer anchor="bottom" open={isOpen} onClose={closeDrawer()}>
      <Container>
        <MyButton
          aria-label="Attend Virtual Meeting (English)"
          onClick={() => {
            window.open(`${selectedEventDetails.OnlineResource}`);
            const { PeId: eventId } = selectedEventDetails;
            dispatch(fetchRegisterForEvent({ eventId, attendanceCount: 1 }));
            closeDrawer();
          }}
          fullWidth
          color="primary"
          variant="contained"
        >
          {'Attend Virtual Meeting (English)'}
        </MyButton>
        {selectedEventDetails.OnlineMeetings.length &&
          selectedEventDetails.OnlineMeetings.map((e, idx) => (
            <MyButton
              aria-label={`Attend Virtual Meeting (${
                languages.find((l) => l.symbol === e.LANGUAGE).name
              })`}
              key={idx}
              onClick={() => {
                window.open(`${e.OnlineResource}`);
                const { PeId: eventId } = selectedEventDetails;
                dispatch(
                  fetchRegisterForEvent({ eventId, attendanceCount: 1 })
                );
                closeDrawer();
              }}
              fullWidth
              color="primary"
              variant="contained"
            >
              {`Attend Virtual Meeting (${
                languages.find((l) => l.symbol === e.LANGUAGE).name
              })`}
            </MyButton>
          ))}
      </Container>
    </Drawer>
  );
}
