import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { loadModules } from 'esri-loader';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { environment } from '../../environment';
import '../../css/ESRIMap.css';
import { Button } from '@material-ui/core';
const MapView = styled.div`
  height: 100%;
  width: 100%;
`;

const PALETTE = {
  Green: [76, 175, 80, 0.8],
  LightGreen: [205, 220, 57, 0.8],
  Yellow: [255, 193, 7, 0.8],
  Orange: [255, 120, 17, 0.8],
  Red: [244, 67, 54, 0.8],
  Unknown: [96, 105, 155, 0.5]
};

const COMMENTS_RENDERER = {
  type: 'unique-value',
  field: 'StakeSupportId',
  // visualVariables: [SUPPORT_COLOR_DEF], inserts range slider graphic but it interferes with legend color
  uniqueValueInfos: [
    {
      value: 1,
      label: 'In Favor',
      symbol: {
        type: 'simple-marker',
        size: 20,
        color: PALETTE.Green,
        style: 'circle'
      }
    },
    {
      value: 2,
      label: 'Leaning in Favor',
      symbol: {
        type: 'simple-marker',
        size: 20,
        color: PALETTE.LightGreen,
        style: 'circle'
      }
    },
    {
      value: 3,
      label: 'Neutral',
      symbol: {
        type: 'simple-marker',
        size: 20,
        color: PALETTE.Yellow,
        style: 'circle'
      }
    },
    {
      value: 4,
      label: 'Less in Favor',
      symbol: {
        type: 'simple-marker',
        size: 20,
        color: PALETTE.Orange,
        style: 'circle'
      }
    },
    {
      value: 5,
      label: 'Not in Favor',
      symbol: {
        type: 'simple-marker',
        size: 20,
        color: PALETTE.Red,
        style: 'circle'
      }
    }
  ]
};

interface Props {
  isMapLoading: boolean;
  setIsMapLoading: Function;
}

export default (function DashboardMap({
  isMapLoading,
  setIsMapLoading
}: Props): ReactElement {
  const [isLegendExpanded, setIsLegendExpanded] = useState(false);
  const viewRef = useRef(null);
  const legendRef = useRef(null);
  const { dashComments, lookups } = useSelector(
    (state: RootState) => state
  ).dashboardSlice;
  const { isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;

  const [isInfoPanelVisible, setIsInfoPanelVisible] = useState(true);

  const toggleInfoPanel = () => {
    setIsInfoPanelVisible((prevState) => !prevState);
  };

  const mapRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  useEffect(() => {
    const startUp = async () => {
      const [
        MapView,
        FeatureLayer,
        Graphic,
        PopupTemplate,
        Locate,
        Search,
        SpatialReference,
        WebMap,
        Legend
      ] = await loadModules(
        [
          'esri/views/MapView',
          'esri/layers/FeatureLayer',
          'esri/Graphic',
          'esri/PopupTemplate',
          'esri/widgets/Locate',
          'esri/widgets/Search',
          'esri/geometry/SpatialReference',
          'esri/WebMap',
          'esri/widgets/Legend'
        ],
        { css: true }
      );
      const map = await new WebMap({
        portalItem: {
          id: '17e9aac323c64cd19673602918062068'
        },
        basemap: 'topo-vector'
      });
      // const map = new ArcGISMap({
      //   basemap: 'topo-vector'
      // });

      let commentsLayer;
      if (dashComments && dashComments.length > 0) {
        const commentGraphics = dashComments.map(
          (x) =>
            new Graphic({
              attributes: {
                OBJECTID: x.StakeCommentId,
                StakeComment: x.StakeComment,
                StakeCommentId: x.StakeCommentId,
                StakeSupportId: x.StakeholderSupportId,
                StakeSupport: lookups.stakeholderSupport.find(
                  (entry) =>
                    entry.STAKEHOLDER_SUPPORT_ID === x.StakeholderSupportId
                ).STAKEHOLDER_SUPPORT,
                ProjectDesc: x.ProjectDesc
              },
              geometry: {
                type: 'point',
                x: x.CommentLon,
                y: x.CommentLat,
                spatialReference: new SpatialReference({ wkid: 102100 })
              }
            })
        );
        const commentPopupTemplate = new PopupTemplate({
          content: `
                    <div style="
                          text-align: left;
                          line-height: normal;
                        ">
                      <div style="font-size: 12px;">
                        Support Level: {StakeSupport}
                      </div>
                      <p style="font-size: 14px; margin-top: 8px;">{StakeComment}</p>
                    </div>
                    `,
          title: '<div style="text-align: left;">{ProjectDesc}</div>'
        });
        commentsLayer = new FeatureLayer({
          source: commentGraphics,
          objectIdField: 'OBJECTID',
          title: 'Project Comments',
          fields: [
            {
              name: 'OBJECTID',
              type: 'oid'
            },
            {
              name: 'StakeCommentId',
              alias: 'Stake Comment Id',
              type: 'integer'
            },
            {
              name: 'StakeComment',
              alias: 'Comment',
              type: 'string'
            },
            {
              name: 'StakeSupport',
              alias: 'Support Level',
              type: 'string'
            },
            {
              name: 'StakeSupportId',
              alias: 'Support Level',
              type: 'integer'
            },
            {
              name: 'ProjectDesc',
              label: 'Project Name',
              type: 'string'
            }
          ],
          geometryType: 'point',
          popupTemplate: commentPopupTemplate,
          renderer: COMMENTS_RENDERER
        });
        await map.load();
        map.add(commentsLayer);
      }

      // load the map view at the ref's DOM node
      const view = new MapView({
        container: mapRef.current,
        map: map,
        center: environment.dashboardMapCenterCoords.split(','),
        zoom: isMobile ? 7.5 : 8.5
      });
      viewRef.current = view;

      const locateWidget = new Locate({
        view: view,
        useHeadingEnabled: false,
        goToOverride: function (view, options) {
          options.target.scale = 1500;
          return view.goTo(options.target);
        }
      });

      /*
      // if we wanted real-time tracking as you move
      const trackWidget = new Track({
        view: view,
        graphic: new Graphic({
          symbol: {
            type: 'simple-marker',
            size: '12px',
            color: 'green',
            outline: {
              color: '#efefef',
              width: '1.5px'
            }
          }
        }),
        useHeadingEnabled: false
      });
      */

      var searchWidget = new Search({
        view: view
      });
      view.ui.add(locateWidget, 'top-left');

      // Info Button
      const infoButton = document.createElement('div');
      infoButton.className =
        'esri-widget--button esri-icon-description esri-interactive';
      infoButton.title = 'Info';
      infoButton.onclick = toggleInfoPanel;
      view.ui.add(infoButton, 'top-left');

      // Old stuff
      // view.ui.add(trackWidget, 'top-left'); if we wanted real-time tracking as you move
      view.ui.add(searchWidget, {
        position: 'top-right'
      });

      view.on('click', function (evt) {
        // alert('TODO - show popup on click');
      });

      const popup = view.popup;
      view.when(function () {
        // expand popup by default
        popup.watch('collapsed', function (value) {
          popup.collapsed = false;
        });
        setIsLegendExpanded(true);
        const legend = new Legend({
          view
        });
        legendRef.current = legend;
        view.ui.add(legend, 'bottom-left');
      });
      view.watch('updating', function (mapLoading: boolean) {
        if (mapLoading !== isMapLoading) {
          mapLoading ? setIsMapLoading(true) : setIsMapLoading(false);
        }
      });
      return () => {
        if (view) {
          // destroy the map view
          view.container = null;
        }
      };
    };
    startUp();
  }, [dashComments, lookups.stakeholderSupport]);

  return (
    <MapView ref={mapRef}>
      {!isMapLoading && (
        <>
          {isLegendExpanded ? (
            <Button
              size="small"
              onClick={() => {
                viewRef.current.ui.remove(legendRef.current);
                setIsLegendExpanded(false);
              }}
              variant="contained"
              color="primary"
              style={{ position: 'absolute', bottom: 40, left: 216 }}
            >
              Collapse
            </Button>
          ) : (
            <Button
              size="small"
              onClick={() => {
                viewRef.current.ui.add(legendRef.current, 'bottom-left');
                setIsLegendExpanded(true);
              }}
              variant="contained"
              color="primary"
              style={{ position: 'absolute', bottom: 40, left: 15 }}
            >
              Expand Legend
            </Button>
          )}
          {isInfoPanelVisible ? (
            <div
              style={{
                display: 'block',
                position: 'absolute',
                top: '120px',
                left: '70px',
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '5px',
                boxShadow: '0 0 5px rgba(0,0,0,0.3)',
                width: '250px',
                height: 'auto',
                maxWidth: '500px',
                boxSizing: 'border-box'
              }}
            >
              <p>
                Welcome to the Public Involvement Dashboard. This Dashboard
                shows you comments for the project(s) that you are following.
              </p>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </MapView>
  );
});
