import React, { useCallback, useEffect } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { SelectedNotificationDetails, SubHeaderGoBack } from '..';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchNotifications,
  resetSelectedNotificationState
} from '../../store/slices/notifications';
import { useHistory } from 'react-router-dom';
import { setNavigationTab } from '../../store/slices/application';
import { CircularProgress } from '@material-ui/core';
import { Notification } from '../';
import styled from 'styled-components';
import { ArrowBack } from '@material-ui/icons';
import { RootState } from '../../store';
import { environment } from '../../environment';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 100
    },
    list: {
      paddingTop: 0
    }
  })
);

const MyArrowBack = styled(ArrowBack)`
  color: white;
  position: absolute;
  left: 0;
  margin-left: 16px;
  margin-top: 4px;
`;

const NoNotificationsMsg = styled.div`
  padding: 16px;
`;

export default function NotificationsContainer(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading,
    hasErrors,
    errorMsg,
    notifications,
    selectedNotificationDetails
  } = useSelector((state: RootState) => state).notificationsSlice;

  const subscribeToHistory = useCallback(() => {
    const unlisten = history.listen((loc, action) => {
      if (action) {
        dispatch(resetSelectedNotificationState());
      }
    });
  }, [dispatch, history]);

  useEffect(() => {
    if (!hasErrors && !loading) {
      // runs first as map favorability depends on it
      subscribeToHistory();
      dispatch(setNavigationTab('notifications'));
    }
  }, [dispatch, hasErrors, loading, notifications, subscribeToHistory]);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);
  const classes = useStyles({});
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Notifications - {environment.entityName} REACH</title>
        <link rel="canonical" href={`${environment.clientUrl}/notifications`} />
      </Helmet>
      {/* {selectedNotificationDetails.notification && (
        <div onClick={handleArrowBackClick}>
          <MyArrowBack />
        </div>
      )} */}
      <SubHeaderGoBack
        isArrowBack={selectedNotificationDetails.notification ? true : false}
        heading={
          selectedNotificationDetails.notification
            ? 'Notification Details'
            : 'Notifications'
        }
        isNotificationDetails={true}
      />
      {!notifications ||
        (notifications && !notifications.length && (
          <NoNotificationsMsg>
            You don't have any notifications yet.
            <br />
            <br />
            You will receive notifications when your project comments are
            replied to as long as the notification preference is checked for
            that project.
          </NoNotificationsMsg>
        ))}
      {notifications && (
        <div className={classes.root}>
          <List className={classes.list}>
            {selectedNotificationDetails.notification ? (
              <SelectedNotificationDetails />
            ) : (
              notifications.map((n, idx: number) => (
                <Notification notification={n} data={n.Data} key={idx} />
              ))
            )}
          </List>
        </div>
      )}
      {loading && (
        <>
          <span
            role="alert"
            style={{ position: 'absolute', marginLeft: -9999 }}
          >
            Loading your notifications
          </span>
          <CircularProgress style={{ margin: 24 }} />
        </>
      )}
      {hasErrors && errorMsg && (
        <div role="alert" style={{ marginTop: 10, color: 'red' }}>
          {errorMsg}
        </div>
      )}
    </>
  );
}
