import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue
} from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import history from '../../history';
import { Auth } from 'aws-amplify';
import { RootState } from '..';
import { environment } from '../../environment';
interface UserState {
  isLoggedInAmplify: boolean;
  isLoggedInREACH: boolean;
  isLoggedInOpenID: boolean;
  loading: boolean;
  hasErrors: boolean;
  errorMsg: string;
  userInfo: {
    username: string;
    isSignedUpAmplify: boolean;
    isSignedUpPIMA: boolean;
    isConfirmed: boolean;
    selectedPIMAUser: any;
    token: string;
    firstAccessToken: string;
    openIDtoken: string;
    signUpFormData: {
      email: string;
      firstName: string;
      lastName: string;
      address: string;
      city: string;
      state: string;
    };
  };
  cognitoLoginResult: any;
  pimaUsersMatchingSignupForm: any[] | null;
  foundStakeId: number;
}

const initialState: UserState = {
  isLoggedInAmplify: false,
  isLoggedInREACH: false,
  isLoggedInOpenID: false,
  loading: false,
  hasErrors: false,
  errorMsg: '',
  cognitoLoginResult: null,
  pimaUsersMatchingSignupForm: null,
  foundStakeId: null,
  userInfo: {
    isSignedUpAmplify: false,
    isSignedUpPIMA: false,
    isConfirmed: false,
    username: null,
    selectedPIMAUser: null,
    token: null,
    firstAccessToken: null,
    openIDtoken: null,
    signUpFormData: null
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginAmplify: (state) => {
      state.loading = true;
    },
    loginAmplifyFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    loginAmplifySuccess: (state, { payload }) => {
      state.cognitoLoginResult = payload;
      state.userInfo.username = payload.email;
      state.isLoggedInAmplify = true;
      state.loading = false;
      state.hasErrors = false;
      localStorage.setItem('email', payload.email);
      localStorage.setItem('sub', payload.sub);
    },
    loginREACH: (state) => {
      state.loading = true;
    },
    loginREACHSuccess: (state, { payload }) => {
      let newPayload = payload;
      newPayload.tokenExpirationTime *= 1000;
      newPayload.tokenExpirationTime += new Date().getTime(); //this is to handle API sending incorrect expiration time.
      localStorage.setItem('reachAuth', JSON.stringify(newPayload));
      state.isLoggedInREACH = true;
      state.userInfo.token = payload.token;
      state.loading = false;
      state.hasErrors = false;
      setTimeout(() => {
        history.push('/projects');
      }, 2000);
    },
    loginREACHFailure: (state, { payload }) => {
      localStorage.clear();
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    loginOpenID: (state) => {
      state.loading = true;
    },
    loginOpenIDSuccess: (state, { payload }) => {
      //below is temporary
      let newPayload = payload;
      newPayload.tokenExpirationTime *= 1000;
      newPayload.tokenExpirationTime += new Date().getTime(); //this is to handle API sending incorrect expiration time.
      localStorage.setItem('reachAuth', JSON.stringify(newPayload));
      state.userInfo.token = payload.firstAccessToken;
      state.isLoggedInREACH = true;
      //above is temporary
      localStorage.setItem('first_token', JSON.stringify(newPayload));
      state.isLoggedInOpenID = true;
      state.userInfo.firstAccessToken = payload.firstAccessToken;
      state.loading = false;
      state.hasErrors = false;
      setTimeout(() => {
        history.push('/projects');
      }, 2000);
    },
    loginOpenIDFailure: (state, { payload }) => {
      localStorage.clear();
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    signupAmplify: (state) => {
      state.loading = true;
    },
    signupAmplifyFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    signupAmplifySuccess: (state) => {
      // user's Cognito account is created in UNCONFIRMED state
      state.userInfo.isSignedUpAmplify = true;
      state.loading = false;
      state.hasErrors = false;
    },
    signupPIMA: (state) => {
      state.loading = true;
    },
    signupPIMAFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    signupPIMASuccess: (state) => {
      state.userInfo.isSignedUpPIMA = true;
      state.loading = false;
      state.hasErrors = false;
    },
    confirmSignup: (state) => {
      state.loading = true;
    },
    confirmSignupAmplifyFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    confirmSignupAmplifySuccess: (state) => {
      // user's Cognito account switched to CONFIRMED state
      state.userInfo.isConfirmed = true;
      state.loading = false;
      state.hasErrors = false;
    },
    resendConfirmationCode: (state) => {
      state.loading = true;
    },
    resendConfirmationCodeFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    resendConfirmationCodeSuccess: (state) => {
      state.loading = false;
      state.hasErrors = false;
    },
    checkExistingPIMAUser: (state) => {
      state.loading = true;
    },
    checkExistingPIMAUserSuccess: (state, { payload }) => {
      state.hasErrors = false;
      state.pimaUsersMatchingSignupForm = payload;
      state.loading = false;
    },
    checkExistingPIMAUserFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    checkExistingPIMAUserByCognitoId: (state) => {
      state.loading = true;
    },
    checkExistingPIMAUserByCognitoIdSuccess: (state, { payload }) => {
      state.hasErrors = false;
      state.foundStakeId = payload;
      state.loading = false;
    },
    checkExistingPIMAUserByCognitoIdFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    setSelectedPIMAUser: (state, { payload }) => {
      state.userInfo.selectedPIMAUser = payload;
    },
    postExistingPIMAUser: (state) => {
      state.loading = true;
    },
    postExistingPIMAUserSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
    },
    postExistingPIMAUserFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    logout: (state) => {
      state.loading = true;
    },
    logoutFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    logoutSuccess: (state) => {
      // localStorage.setItem('accessToken', null);
      // localStorage.setItem('username', null);
      localStorage.clear();
      state.isLoggedInREACH = false;
      state.loading = false;
      state.hasErrors = false;
      history.push('/');
    },
    setisUserSignedUpAmplify: (state) => {
      // this is used when the user wants to confirm their registered email by entering the code from the email
      state.userInfo.isSignedUpAmplify = true;
    },
    setisUserSignedUpPIMA: (state) => {
      state.userInfo.isSignedUpPIMA = true;
    },
    setIsLoggedOut: (state) => {
      localStorage.clear();
      state.isLoggedInREACH = false;
    },
    setIsAlreadyLoggedIn: (state, { payload }) => {
      state.isLoggedInREACH = true;
      state.userInfo.username = payload;
    },
    setUsername: (state, { payload }) => {
      state.userInfo.username = payload;
    },
    setSignUpFormData: (state, { payload }) => {
      state.userInfo.signUpFormData = payload;
    }
  }
});

export const {
  loginAmplify,
  loginAmplifySuccess,
  loginAmplifyFailure,
  signupAmplify,
  signupAmplifySuccess,
  signupAmplifyFailure,
  signupPIMA,
  signupPIMASuccess,
  signupPIMAFailure,
  confirmSignup,
  confirmSignupAmplifySuccess,
  confirmSignupAmplifyFailure,
  resendConfirmationCode,
  resendConfirmationCodeSuccess,
  resendConfirmationCodeFailure,
  checkExistingPIMAUser,
  checkExistingPIMAUserSuccess,
  checkExistingPIMAUserFailure,
  checkExistingPIMAUserByCognitoId,
  checkExistingPIMAUserByCognitoIdSuccess,
  checkExistingPIMAUserByCognitoIdFailure,
  postExistingPIMAUser,
  postExistingPIMAUserSuccess,
  postExistingPIMAUserFailure,
  logout,
  logoutSuccess,
  logoutFailure,
  setisUserSignedUpAmplify,
  setisUserSignedUpPIMA,
  setIsAlreadyLoggedIn,
  setIsLoggedOut,
  setSelectedPIMAUser,
  setUsername,
  loginREACH,
  loginREACHSuccess,
  loginREACHFailure,
  loginOpenID,
  loginOpenIDSuccess,
  loginOpenIDFailure,
  setSignUpFormData
} = userSlice.actions;

export const getLoginState = (state: RootState) => state.user;

export const createPIMAStakeholder = createAsyncThunk(
  'user/createPIMAStakeholder',
  async (
    input: {
      email: string;
      firstName: string;
      lastName: string;
      address: string;
      city: string;
      state: string;
      zipcode: string;
    },
    thunkAPI
  ) => {
    const { email, firstName, lastName, address, city, state, zipcode } = input;
    // TODO - ADD TRY/CATCH AND LOADING/ERROR STATES
    await axios.post(`${environment.apiUrl}/REACH/RegisterNewStakeholder`, {
      STAKE_EMAIL: email,
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
      MAIL1_ADDRESS_LINE1: address,
      MAIL1_CITY: city,
      MAIL1_STATE: state,
      MAIL1_ZIP_CODE: zipcode,
      EXTERNAL_ID: localStorage.getItem('sub')
    });
  }
);
export const fetchPostExistingPIMAUser = createAsyncThunk(
  'user/PostExistingPIMAUser',
  async (input: { stakeId: string; externalId: string }, thunkAPI: any) => {
    const { stakeId, externalId } = input;
    thunkAPI.dispatch(postExistingPIMAUser());
    try {
      const { data } = await axios.post(
        `${environment.apiUrl}/STAKEHOLDER_FILTER/PostExternalUser`,
        { StakeId: stakeId, ExternalId: externalId }
      );
      if (data) {
        thunkAPI.dispatch(postExistingPIMAUserSuccess(data));
      } else {
        thunkAPI.dispatch(postExistingPIMAUserFailure('no result from server'));
      }
    } catch (err) {
      thunkAPI.dispatch(postExistingPIMAUserFailure(err.message));
    }
  }
);

export const fetchCheckExistingPIMAUserAndSignUp = createAsyncThunk(
  'user/checkExistingPIMAUserAndSignUp',
  async (
    input: { lastname: string; zipcode: string; email: string },
    thunkAPI: any
  ) => {
    const { lastname, zipcode, email } = input;
    thunkAPI.dispatch(checkExistingPIMAUser());
    try {
      const { data } = await axios.get(
        `${environment.apiUrl}/STAKEHOLDER_FILTER/GetByLastZipEmail?lastName=${lastname}&zipCode=${zipcode}&email=${email}`
      );
      if (data) {
        thunkAPI.dispatch(checkExistingPIMAUserSuccess(data));
      } else {
        thunkAPI.dispatch(
          checkExistingPIMAUserFailure('no result from server')
        );
      }
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(checkExistingPIMAUserFailure(error.message));
    }
  }
);

export const fetchCheckExistingPIMAUserById = createAsyncThunk(
  'user/checkExistingPIMAUserById',
  async (input, thunkAPI: any) => {
    // const { lastname, zipcode, email } = input;
    const state = thunkAPI.getState();
    const CognitoId = state.userSlice.cognitoLoginResult.sub;
    thunkAPI.dispatch(checkExistingPIMAUserByCognitoId());

    try {
      const { data } = await axios.get(
        `${environment.apiUrl}/STAKEHOLDER/GetStakeId?id=${CognitoId}`
      );
      if (data && data !== 0) {
        thunkAPI.dispatch(checkExistingPIMAUserByCognitoIdSuccess(data));
      } else {
        thunkAPI.dispatch(
          checkExistingPIMAUserByCognitoIdFailure('no result from server')
        );
      }
    } catch (err) {
      thunkAPI.dispatch(checkExistingPIMAUserByCognitoIdFailure(err.message));
    }
  }
);

export const fetchLoginAmplify = createAsyncThunk(
  'user/fetchLoginAmplify',
  async (input: any, thunkAPI: any) => {
    thunkAPI.dispatch(loginAmplify());
    try {
      const data = await Auth.signIn({
        username: input.username,
        password: input.password
      });
      if (data) {
        await thunkAPI.dispatch(loginAmplifySuccess(data.attributes));
      }
    } catch (err) {
      thunkAPI.dispatch(loginAmplifyFailure(err.message));
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const getFirstAccessTokenHeaders = (): AxiosRequestConfig => {
  const { firstAccessToken } = JSON.parse(localStorage.getItem('first_token'));
  return {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${firstAccessToken}`
    }
  };
};

export const getREACHAuthHeaders = (): AxiosRequestConfig => {
  const { token } = JSON.parse(localStorage.getItem('reachAuth'));
  return {
    headers: { Authorization: `Bearer ${token}` }
  };
};

export const isTokenExpired = (thunkAPI: any) => {
  if (localStorage.getItem('reachAuth')) {
    const { tokenExpirationTime } = JSON.parse(
      localStorage.getItem('reachAuth')
    );
    const currentTime: number = new Date().getTime();
    const tokenExpTime: number = new Date(tokenExpirationTime).getTime();
    if (tokenExpTime < currentTime) {
      alert('Your login session has expired. Please login again.');
      thunkAPI.dispatch(setIsLoggedOut());
      return true;
    } else {
      return false;
    }
  } else {
    throw new Error('no auth token found');
  }
};

// // old pre-existing login reach call
// export const fetchLoginREACH = createAsyncThunk(
//   'user/fetchLoginREACH',
//   async (cognitoId: string, thunkAPI: any) => {
//     thunkAPI.dispatch(loginREACH());
//     try {
//       const { data } = await axios.post(
//         `${environment.apiUrl}/Token/LoginReachUser`,
//         {
//           CognitoId: cognitoId
//         }
//       );
//       if (data) {
//         thunkAPI.dispatch(
//           loginREACHSuccess({
//             token: data.Token,
//             tokenExpirationTime: data.TokenExpiration
//           })
//         );
//       } else {
//         thunkAPI.dispatch(loginREACHFailure('no result from server'));
//       }
//     } catch (err) {
//       const state = thunkAPI.getState();
//       if (state.userSlice.hasErrors) {
//         // previous cognito error is more articulate - display that
//         thunkAPI.dispatch(loginREACHFailure(state.userSlice.errorMsg));
//       } else {
//         thunkAPI.dispatch(loginREACHFailure(err.message));
//       }
//     }
//   }
// );

//new login reach call
export const fetchLoginOpenID = createAsyncThunk(
  'user/fetchLoginOpenID',
  async (input, thunkAPI: any) => {
    const state = thunkAPI.getState();

    const StakeId = state.userSlice.userInfo.selectedPIMAUser
      ? state.userSlice.userInfo.selectedPIMAUser.STAKE_ID
      : state.userSlice.foundStakeId
      ? state.userSlice.foundStakeId
      : '0';
    const clientSecret =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_CLIENT_SECRET
        : environment.client_secret;
    const clientId =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_CLIENT_ID
        : environment.client_id;
    const CognitoId = state.userSlice.cognitoLoginResult.sub;
    const loginInfo = `grant_type=password&username=${CognitoId}&password=${StakeId}&scope=openid api&client_id=${clientId}&client_secret=${clientSecret}`;
    const options = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        Accept: 'application/json'
      }
    };
    // if (CognitoId && StakeId) {
    thunkAPI.dispatch(loginOpenID());

    try {
      const response = await axios.post(
        `${environment.authority}/connect/token`,
        loginInfo,
        options
      );
      if (response.data) {
        thunkAPI.dispatch(
          loginOpenIDSuccess({
            firstAccessToken: response.data.access_token,
            token: response.data.access_token,
            tokenExpirationTime: response.data.expires_in
          })
        );
      } else {
        thunkAPI.dispatch(loginOpenIDFailure('no result from server'));
      }
    } catch (err) {
      const state = thunkAPI.getState();
      if (state.userSlice.hasErrors) {
        // previous cognito error is more articulate - display that
        thunkAPI.dispatch(loginOpenIDFailure(state.userSlice.errorMsg));
        return thunkAPI.rejectWithValue(state.userSlice.errorMsg);
      } else {
        thunkAPI.dispatch(loginOpenIDFailure(err.message));
        return thunkAPI.rejectWithValue(err.message);
      }
    }
    //}
  }
);

// //new login reach call
// // this works fine, but we want to test a password grant of this above
// export const fetchLoginOpenID = createAsyncThunk(
//   'user/fetchLoginOpenID',
//   async (input, thunkAPI: any) => {
//     thunkAPI.dispatch(loginOpenID());
//     const loginInfo = `grant_type=client_credentials&scope=${environment.scope}&client_id=${environment.client_id}&client_secret=${environment.client_secret}`;
//     const options = {
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
//         Accept: 'application/json'
//       }
//     };
//     try {
//       const response = await axios.post(
//         `${environment.authority}/connect/token`,
//         loginInfo,
//         options
//       );
//       if (response.data) {
//         thunkAPI.dispatch(
//           loginOpenIDSuccess({
//             firstAccessToken: response.data.access_token,
//             tokenExpirationTime: response.data.expires_in
//           })
//         );
//       } else {
//         thunkAPI.dispatch(loginOpenIDFailure('no result from server'));
//       }
//     } catch (err) {
//       const state = thunkAPI.getState();
//       if (state.userSlice.hasErrors) {
//         // previous cognito error is more articulate - display that
//         thunkAPI.dispatch(loginOpenIDFailure(state.userSlice.errorMsg));
//       } else {
//         thunkAPI.dispatch(loginOpenIDFailure(err.message));
//       }
//     }
//   }
// );

//new login reach call
export const fetchLoginREACH = createAsyncThunk(
  'user/fetchLoginREACH',
  async (input, thunkAPI: any) => {
    thunkAPI.dispatch(loginREACH());
    const config: AxiosRequestConfig = getFirstAccessTokenHeaders();
    const state = thunkAPI.getState();
    const stakeId = state.userSlice.userInfo.selectedPIMAUser
      ? state.userSlice.userInfo.selectedPIMAUser.STAKE_ID
      : state.userSlice.foundStakeId
      ? state.userSlice.foundStakeId
      : '';
    const externalId = state.userSlice.cognitoLoginResult.sub;
    try {
      const response = await axios.post(
        `${environment.authority}/Token/LoginReachUser`,
        { ExternalId: externalId, StakeId: stakeId },
        config
      );
      if (response.data) {
        thunkAPI.dispatch(
          loginREACHSuccess({
            token: response.data.Token,
            tokenExpirationTime: response.data.expires_in
          })
        );
      } else {
        thunkAPI.dispatch(loginREACHFailure('no result from server'));
      }
    } catch (err) {
      const state = thunkAPI.getState();
      if (state.userSlice.hasErrors) {
        // previous cognito error is more articulate - display that
        thunkAPI.dispatch(loginREACHFailure(state.userSlice.errorMsg));
      } else {
        thunkAPI.dispatch(loginREACHFailure(err.message));
      }
    }
  }
);

export const fetchLogout = createAsyncThunk(
  'user/fetchLogout',
  async (input, thunkAPI: any) => {
    try {
      thunkAPI.dispatch(logoutSuccess());
    } catch (err) {
      thunkAPI.dispatch(logoutFailure(err.message));
    }
  }
);

export const fetchAmplifySignup = createAsyncThunk(
  'user/fetchAmplifySignup',
  async (input: any, thunkAPI: any) => {
    thunkAPI.dispatch(signupAmplify());
    try {
      const data = await Auth.signUp({
        username: input.email,
        password: input.password,
        attributes: {
          email: input.attributes.email
        }
      });
      if (data) {
        thunkAPI.dispatch(signupAmplifySuccess());
        return;
      }
    } catch (err) {
      thunkAPI.dispatch(signupAmplifyFailure(err.message));
      return;
    }
  }
);
export const fetchConfirmAmplifySignup = createAsyncThunk(
  'user/fetchConfirmAmplifySignup',
  async (input: any, thunkAPI: any) => {
    thunkAPI.dispatch(confirmSignup());
    try {
      const data = await Auth.confirmSignUp(
        input.email,
        input.confirmationCode
      );
      if (data) {
        thunkAPI.dispatch(confirmSignupAmplifySuccess());
        await thunkAPI.dispatch(
          fetchLoginAmplify({
            username: input.email,
            password: input.password
          })
        );
      }
    } catch (err) {
      thunkAPI.dispatch(confirmSignupAmplifyFailure(err.message));
    }
  }
);

export const fetchResendConfirmationCode = createAsyncThunk(
  'user/fetchResendConfirmationCode',
  async (username: string, thunkAPI: any) => {
    thunkAPI.dispatch(resendConfirmationCode());
    try {
      await Auth.resendSignUp(username);
      thunkAPI.dispatch(resendConfirmationCodeSuccess());
      console.log('code resent successfully');
    } catch (err) {
      thunkAPI.dispatch(resendConfirmationCodeFailure(err.message));
    }
  }
);

export const fetchSignupPIMA = createAsyncThunk(
  'user/fetchSignupPIMA',
  async (input, thunkAPI: any) => {
    thunkAPI.dispatch(signupPIMA());
    const state = thunkAPI.getState();
    const stakeId = state.userSlice.userInfo.selectedPIMAUser
      ? state.userSlice.userInfo.selectedPIMAUser.STAKE_ID
      : state.userSlice.foundStakeId
      ? state.userSlice.foundStakeId
      : '';
    try {
      const externalId = state.userSlice.cognitoLoginResult.sub;
      await axios.post(`${environment.apiUrl}/STAKEHOLDER/PostExternalUser`, {
        StakeId: stakeId,
        ExternalId: externalId
      });
      thunkAPI.dispatch(signupPIMASuccess());
      console.log('PIMA account linked successfully.');
    } catch (err) {
      thunkAPI.dispatch(signupPIMAFailure(err.message));
    }
  }
);

export const fetchConfirmSignupAll = createAsyncThunk(
  'user/fetchConfirmSignupAll',

  async (
    input: {
      email: string;
      password: string;
      confirmationCode: string;
      firstName: string;
      lastName: string;
      zipcode: string;
      address: string;
      city: string;
      state: string;
    },
    thunkAPI: any
  ) => {
    const {
      email,
      password,
      confirmationCode,
      firstName,
      lastName,
      zipcode,
      address,
      city,
      state
    } = input;
    thunkAPI.dispatch(confirmSignup());
    try {
      const data = await Auth.confirmSignUp(email, confirmationCode);
      if (data) {
        thunkAPI.dispatch(confirmSignupAmplifySuccess());
        await thunkAPI.dispatch(
          fetchLoginAmplify({
            username: email,
            password: password
          })
        );
        const reduxState = thunkAPI.getState();
        if (reduxState.userSlice.userInfo.selectedPIMAUser) {
          await thunkAPI.dispatch(fetchSignupPIMA());
        } else {
          await thunkAPI.dispatch(
            createPIMAStakeholder({
              email,
              firstName,
              lastName,
              address,
              city,
              state,
              zipcode
            })
          );
        }

        const cognitoId = localStorage.getItem('sub');
        const stakeId = reduxState.userSlice.userInfo.selectedPIMAUser
          ? reduxState.userSlice.userInfo.selectedPIMAUser.STAKE_ID
          : reduxState.userSlice.foundStakeId
          ? reduxState.userSlice.foundStakeId
          : '';

        if (cognitoId && stakeId) {
          thunkAPI.dispatch(fetchLoginOpenID());
        } else if (!stakeId) {
          await thunkAPI.dispatch(fetchCheckExistingPIMAUserById());
          await thunkAPI.dispatch(fetchLoginOpenID());
          //await thunkAPI.dispatch(fetchLoginREACH());        // Bobby wants to try something else for now, returning to this later
        } else {
          throw new Error('no cognito id');
        }
      }
    } catch (err) {
      thunkAPI.dispatch(confirmSignupAmplifyFailure(err.message));
    }
  }
);

export default userSlice.reducer;
