import styled from 'styled-components';

export const PolicyAgreementsFixedBottom = styled.div`
  width: 300px;
  position: fixed;
  margin: 12px;
  font-size: 14px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%); // fixes bottom center on desktop too
`;

export const PolicyAgreements = styled.div`
  text-align: left;
  margin: 12px 0px;
`;
