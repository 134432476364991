import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { useSelector } from 'react-redux';
import { ProjectComment } from '..';
import { RootState } from '../../store';

// this component acts as a middle-man between Project and ProjectComment components to reduce the size of the Project component
export default function ProjectComments(props) {
  const { isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0, 0)
      },
      paper: {
        width: !isMobile && 575,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(0)
      },
      list: {
        paddingTop: 0
      }
    })
  );
  const classes = useStyles();
  const { selectedProjectComments } = useSelector(
    (state: RootState) => state
  ).projectsSlice;

  return (
    <div className={classes.root}>
      <List className={classes.list}>
        {selectedProjectComments.map((comment, idx) => (
          <ProjectComment comment={comment} key={idx} />
        ))}
      </List>
    </div>
  );
}
