import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { RootState } from '..';
import { environment } from '../../environment';
import { fetchLoginREACH, getREACHAuthHeaders, isTokenExpired } from './user';
interface FeedState {
  loading: boolean;
  hasErrors: boolean;
  errorMsg: string;
  comments: any[] | null;
  // feed api filter options
  isFollowedComments: boolean;
  isAllComments: boolean;
  isMine: boolean;
  // end feed api filter options
  isLoadingMoreComments: boolean;
  isAllCommentsLoaded: boolean;
  isLoadingProjCommentDetails: boolean;
  hasCheckedInitialState: boolean;
}

const initialState: FeedState = {
  loading: false,
  hasErrors: false,
  errorMsg: '',
  comments: null,
  isFollowedComments: true,
  isAllComments: false,
  isMine: false,
  isLoadingMoreComments: false,
  isAllCommentsLoaded: false,
  isLoadingProjCommentDetails: false,
  hasCheckedInitialState: false
};

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    setHasCheckedInitialState: (state, { payload }) => {
      state.hasCheckedInitialState = payload;
    },
    setUpdateFeedComment: (state, { payload }) => {
      state.comments.find(
        (c) => c.StakeCommentId === payload.StakeCommentId
      ).IsVisible = payload.IsVisible;
    },
    setAreFeedCommentResponsesExpanded: (state, { payload }) => {
      state.comments.find(
        (c) => c.StakeCommentId === payload.StakeCommentId
      ).AreResponsesExpanded = payload.AreResponsesExpanded;
    },
    addStakeCommentResponseToFeedState: (state, { payload }) => {
      const selectedComment = state.comments.find(
        (c) => c.StakeCommentId === payload.StakeCommentId
      );
      if (selectedComment && selectedComment.Responses) {
        selectedComment.Responses.push(payload);
      }
    },
    resetFeed: (state) => {
      // used when coming to projects page from feed
      // need updated feed in case user modified their followed projects
      state.loading = initialState.loading;
      state.hasErrors = initialState.hasErrors;
      state.errorMsg = initialState.errorMsg;
      state.comments = initialState.comments;
      state.isFollowedComments = initialState.isFollowedComments;
      state.isMine = initialState.isMine;
      state.hasCheckedInitialState = initialState.hasCheckedInitialState;
      state.isLoadingMoreComments = initialState.isLoadingMoreComments;
      state.isAllCommentsLoaded = initialState.isAllCommentsLoaded;
    },
    getComments: (state) => {
      state.isAllCommentsLoaded = false;
      state.loading = true;
    },
    getCommentsSuccess: (state, { payload }) => {
      state.comments = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getCommentsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    loadMoreComments: (state) => {
      state.isLoadingMoreComments = true;
    },
    loadMoreCommentsSuccess: (state, { payload }) => {
      if (!payload.length) {
        state.isAllCommentsLoaded = true;
      } else {
        state.comments = [...state.comments, ...payload];
      }
      state.isLoadingMoreComments = false;
      state.hasErrors = false;
    },
    loadMoreCommentsFailure: (state, { payload }) => {
      state.isLoadingMoreComments = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    setIsFollowedComments: (state, { payload }) => {
      state.isFollowedComments = payload;
      state.isAllComments = false;
      state.isMine = false;
    },
    setIsAllComments: (state, { payload }) => {
      state.isAllComments = payload;
      state.isMine = false;
      state.isFollowedComments = false;
    },
    setIsAllCommentsLoaded: (state, { payload }) => {
      state.isAllCommentsLoaded = payload;
    },
    setIsMine: (state, { payload }) => {
      state.isMine = payload;
      state.isAllComments = false;
      state.isFollowedComments = false;
    }
  }
});

export const {
  setHasCheckedInitialState,
  setIsAllComments,
  setUpdateFeedComment,
  setAreFeedCommentResponsesExpanded,
  addStakeCommentResponseToFeedState,
  resetFeed,
  getComments,
  getCommentsSuccess,
  getCommentsFailure,
  loadMoreComments,
  loadMoreCommentsSuccess,
  loadMoreCommentsFailure,
  setIsFollowedComments,
  setIsMine,
  setIsAllCommentsLoaded
} = feedSlice.actions;

export const getFeedState = (state: RootState) => state.feed;

export const fetchComments = createAsyncThunk(
  'feed/fetchComments',
  async (
    // isMine means that you are asking for only YOUR comments
    // isFollowedComments refers to comments from projects that you follow
    input: {
      isMine: boolean;
      isFollowedComments: boolean;
      start: number;
      length: number;
    },
    thunkAPI: any
  ) => {
    const { isMine, isFollowedComments, start, length } = input;
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(getComments());
      let { data } = await axios.get(
        `${environment.apiUrl}/Reach/GetComments?isMine=${isMine}&isSubscribed=${isFollowedComments}&start=${start}&length=${length}`,
        config
      );
      if (data) {
        if (data.length < length) {
          await thunkAPI.dispatch(setIsAllCommentsLoaded(true));
        } else {
          await thunkAPI.dispatch(setIsAllCommentsLoaded(false));
        }
        data.forEach((d) => (d.AreResponsesExpanded = false));
        thunkAPI.dispatch(getCommentsSuccess(data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getCommentsFailure(err.message));
    }
  }
);

export const fetchMoreComments = createAsyncThunk(
  'feed/fetchMoreComments',
  async (
    input: {
      isMine: boolean;
      isFollowedComments: boolean;
      start: number;
      length: number;
    },
    thunkAPI: any
  ) => {
    const { isMine, isFollowedComments, start, length } = input;
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      thunkAPI.dispatch(loadMoreComments());
      let { data } = await axios.get(
        `${environment.apiUrl}/Reach/GetComments?isMine=${isMine}&isSubscribed=${isFollowedComments}&start=${start}&length=${length}`,
        config
      );
      if (data) {
        if (data.length < length) {
          await thunkAPI.dispatch(setIsAllCommentsLoaded(true));
        } else {
          await thunkAPI.dispatch(setIsAllCommentsLoaded(false));
        }
        data.forEach((d) => (d.AreResponsesExpanded = false));
        thunkAPI.dispatch(loadMoreCommentsSuccess(data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(loadMoreCommentsFailure(err.message));
    }
  }
);

export default feedSlice.reducer;
