import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { RootState } from '..';
import { environment } from '../../environment';
import {
  CommentDetailsDTO,
  VW_COMMENT_RESPONSE,
  VW_STAKEHOLDER_COMMENT
} from '../../models/DTOs';
import { fetchLoginREACH, getREACHAuthHeaders, isTokenExpired } from './user';

interface ResponseType {
  RESPONSE_REQUEST_TYPE_ID: number;
  RESPONSE_REQUEST_TYPE: string;
}

interface CommentCategory {
  COMMENT_CATEGORY_ID: number;
  COMMENT_CATEGORY: string;
}

interface OrganizationType {
  ORGANIZATION_TYPE_ID: number;
  ORGANIZATION_TYPE: string;
}

interface StakeholderType {
  STAKEHOLDER_TYPE_ID: number;
  STAKEHOLDER_TYPE_GUID: string;
  STAKEHOLDER_TYPE: string;
}

interface ParticipateState {
  loading: boolean;
  hasErrors: boolean;
  errorMsg: string;
  project: any;
  commentFormState: any;
  projectCommentFormQuestions: any[];
  projectCommentFormCurrentQuestionID: number;
  lookups: {
    responseTypes: ResponseType[];
    commentCategories: CommentCategory[];
    organizationTypes: OrganizationType[];
    stakeholderTypes: StakeholderType[];
  };
  stakeCommentPayload?: any | VW_STAKEHOLDER_COMMENT;
  isCaptchaSubmitted: boolean;
  isStakeCommentSubmitted: boolean;
}

const initialState: ParticipateState = {
  loading: false,
  hasErrors: false,
  errorMsg: '',
  project: null,
  commentFormState: {
    stakeComment: '',
    commentTopics: null,
    commentChoices: null,
    projectSupportLevel: null,
    selectedResponseTypeId: 4 // defaults "Do not send me a response"
  },
  projectCommentFormQuestions: [],
  projectCommentFormCurrentQuestionID: 0,
  lookups: {
    responseTypes: [],
    commentCategories: [],
    organizationTypes: [],
    stakeholderTypes: []
  },
  stakeCommentPayload: {},
  isCaptchaSubmitted: false,
  isStakeCommentSubmitted: false
};

const participateSlice = createSlice({
  name: 'participate',
  initialState,
  reducers: {
    resetToInitialState: (state) => initialState,
    setCommentFormState: (state, { payload }) => {
      state.commentFormState = payload;
    },
    updateStakeCommentPayload: (state, { payload }) => {
      state.stakeCommentPayload[payload.property] = payload.value;
    },
    updateComment: (state) => {
      state.loading = true;
    },
    updateCommentSuccess: (state, { payload }) => {
      state.loading = false;
    },
    updateCommentFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getProject: (state) => {
      state.loading = true;
    },
    getProjectSuccess: (state, { payload }) => {
      state.project = payload;
      state.loading = false;
    },
    getProjectFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getAllCommentFormLookups: (state) => {
      state.loading = true;
    },
    getAllCommentFormLookupsSuccess: (state, { payload }) => {
      state.lookups.responseTypes = payload.responseTypes;
      state.lookups.commentCategories = payload.commentCategories;
      state.lookups.organizationTypes = payload.organizationTypes;
      state.lookups.stakeholderTypes = payload.stakeholderTypes;
    },
    getAllCommentFormLookupsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getProjCommentFormQuestions: (state) => {
      state.loading = false;
    },
    getProjCommentFormQuestionsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getProjCommentFormQuestionsSuccess: (state, { payload }) => {
      state.projectCommentFormQuestions = payload;
      state.hasErrors = false;
    },
    setProjCommentFormCurrentQuestionID: (state, { payload }) => {
      state.projectCommentFormCurrentQuestionID = payload;
    },
    submitCaptcha: (state) => {},
    submitCaptchaSuccess: (state) => {
      state.isCaptchaSubmitted = true;
    },
    submitCaptchaFailure: (state, { payload }) => {
      state.isCaptchaSubmitted = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    postStakeComment: (state) => {
      state.loading = true;
    },
    postStakeCommentSuccess: (state) => {
      state.isStakeCommentSubmitted = true;
      state.loading = false;
    },
    postStakeCommentFailure: (state, { payload }) => {
      state.isStakeCommentSubmitted = false;
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    postStakeCommentReply: (state) => {
      state.loading = true;
    },
    postStakeCommentReplySuccess: (state) => {
      state.loading = false;
    },
    postStakeCommentReplyFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    setIsStakeCommentSubmitted: (state, { payload }) => {
      state.isStakeCommentSubmitted = payload;
    }
  }
});

export const {
  updateStakeCommentPayload,
  getProject,
  getProjectSuccess,
  getProjectFailure,
  updateComment,
  updateCommentSuccess,
  updateCommentFailure,
  getProjCommentFormQuestions,
  getProjCommentFormQuestionsSuccess,
  getProjCommentFormQuestionsFailure,
  setProjCommentFormCurrentQuestionID,
  getAllCommentFormLookups,
  getAllCommentFormLookupsSuccess,
  getAllCommentFormLookupsFailure,
  submitCaptcha,
  submitCaptchaSuccess,
  submitCaptchaFailure,
  postStakeComment,
  postStakeCommentSuccess,
  postStakeCommentFailure,
  postStakeCommentReply,
  postStakeCommentReplySuccess,
  postStakeCommentReplyFailure,
  setIsStakeCommentSubmitted,
  resetToInitialState,
  setCommentFormState
} = participateSlice.actions;

export const getParticipateState = (state: RootState) => state.participate;

export const fetchCommentFormQuestions = createAsyncThunk(
  'participate/fetchCommentFormQuestions',
  async (input: { projectId: number }, thunkAPI: any) => {
    const { projectId } = input;
    thunkAPI.dispatch(getProjCommentFormQuestions());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const { data } = await axios.get(
        `${environment.apiUrl}/PublicQuestion/get?project_id=${projectId}`,
        config
      );
      const { participateSlice } = thunkAPI.getState();
      const filterUnneededQuestions = (result) =>
        result.filter((question: any) => {
          if (question.ModuleName === 'Stakeholder') {
            // these are related to submitting your stakeholder information from PIMA - we already have that since the user is logged into REACH
            return false;
          } else if (
            question.ModuleName === 'Topics' && // only include if configured in PIMA
            !participateSlice.project.SelectedCommentTopics.length
          ) {
            return false;
          } else if (
            question.ModuleName === 'Choices' && // only include if configured in PIMA
            !participateSlice.project.SelectedCommentChoices.length
          ) {
            return false;
          } else {
            return true;
          }
        });
      if (data) {
        thunkAPI.dispatch(
          getProjCommentFormQuestionsSuccess(filterUnneededQuestions(data))
        );
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getProjCommentFormQuestionsFailure(err.message));
    }
  }
);

export const fetchAllCommentFormLookups = createAsyncThunk(
  'participate/fetchAllCommentFormLookups',
  async (input, thunkAPI: any) => {
    thunkAPI.dispatch(getAllCommentFormLookups());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const lookupUrls = [
        // aliases are so redux store can associate data to the correct lookup state properties
        {
          url: `${environment.apiUrl}/lu_response_request_type/get`,
          alias: 'responseTypes'
        },
        {
          url: `${environment.apiUrl}/lu_comment_category/get`,
          alias: 'commentCategories'
        },
        {
          url: `${environment.apiUrl}/lu_organization_type/get`,
          alias: 'organizationTypes'
        },
        {
          url: `${environment.apiUrl}/lu_stakeholder_type/get`,
          alias: 'stakeholderTypes'
        }
      ];
      const allLookupData = await Promise.all(
        lookupUrls.map((item) => axios.get(item.url, config))
      );
      const res = {};
      lookupUrls.forEach((l: any, idx: number) => {
        // map object keys to aliases
        res[l.alias] = allLookupData[idx].data;
      });
      if (res) {
        thunkAPI.dispatch(getAllCommentFormLookupsSuccess(res));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getAllCommentFormLookupsFailure(err.message));
    }
  }
);

export const fetchProject = createAsyncThunk(
  'participate/fetchProject',
  async (input: { projectId: number }, thunkAPI: any) => {
    const { projectId } = input;
    thunkAPI.dispatch(getProject());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const { data } = await axios.get(
        `${environment.apiUrl}/project/get?id=${projectId}`,
        config
      );
      if (data) {
        thunkAPI.dispatch(getProjectSuccess(data));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(getProjectFailure(err.message));
    }
  }
);

export const fetchPostStakeComment = createAsyncThunk(
  'participate/fetchPostStakeComment',
  async (
    input: { stakeId: number; payload: VW_STAKEHOLDER_COMMENT },
    thunkAPI: any
  ) => {
    const { stakeId, payload } = input;
    if (!payload.STAKEHOLDER_SUPPORT_ID) {
      payload.STAKEHOLDER_SUPPORT_ID = 3; // default state is neutral before user interacts with slider
    }
    thunkAPI.dispatch(postStakeComment());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const { status } = await axios.post(
        `${environment.apiUrl}/stakeholder_comment/Post?stake_id=${stakeId}`,
        payload,
        config
      );
      if (status === 200) {
        thunkAPI.dispatch(postStakeCommentSuccess());
      } else {
        thunkAPI.dispatch(
          postStakeCommentFailure('Error submitting stakeholder comment.')
        );
      }
    } catch (err) {
      thunkAPI.dispatch(
        postStakeCommentFailure('Error submitting stakeholder comment.')
      );
    }
  }
);

export const fetchPostStakeCommentReponse = createAsyncThunk(
  'participate/fetchPostStakeCommentReponse',
  async (input: { commentId: number; response: any }, thunkAPI: any) => {
    // TODO: update response type to VW_COMMENT_RESPONSE when api is complete
    const { commentId } = input;
    let { response } = input;
    thunkAPI.dispatch(postStakeCommentReply());
    try {
      response = {
        COMMENT_RESPONSE_GUID: null,
        COMMENT_RESPONSE_ID: 0,
        IS_ON_BEHALF: 0,
        IS_RESPONSE_NEEDS_APPROVAL: 0,
        IsWorkflow: false,
        LAST_UPDATED_DATE: moment().format('YYYY-MM-DD HH:mm:ss'),
        LAST_UPDATED_PERSON: null,
        LAST_UPDATED_PERSON_ID: null,
        NEEDS_RESPONSE: null,
        PERSON_NAME: null,
        RESPONDED_BY: null,
        RESPONDED_BY_PERSON: null,
        RESPONSE: response,
        RESPONSE_DATE: moment().format('YYYY-MM-DD HH:mm:ss'),
        RESPONSE_DATE2: moment().format('YYYY-MM-DD'),
        RESPONSE_SENT_DATE: null,
        RESPONSE_TYPE: null,
        RESPONSE_TYPE_ID: 2,
        STAKE_COMMENT_GUID: '00000000-0000-0000-0000-000000000000',
        STAKE_COMMENT_ID: commentId,
        STAKE_ID: 0,
        STAKEHOLDER_NAME: '',
        Workflow: null,
        WORKFLOW_AUTHOR_ID: null
      };

      const { status } = await axios.post(
        `${environment.apiUrl}/comment_response/UpdateResponse?comment_id=${commentId}`,
        response
      );
      if (status === 200) {
        thunkAPI.dispatch(postStakeCommentReplySuccess());
      } else {
        thunkAPI.dispatch(
          postStakeCommentReplyFailure(
            'Error submitting stakeholder comment response.'
          )
        );
      }
    } catch (err) {
      thunkAPI.dispatch(
        postStakeCommentReplyFailure(
          'Error submitting stakeholder comment response.'
        )
      );
    }
  }
);

export const fetchUpdateComment = createAsyncThunk(
  'participate/updateComment',
  async (input: CommentDetailsDTO, thunkAPI: any) => {
    thunkAPI.dispatch(updateComment());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const { status } = await axios.put(
        `${environment.apiUrl}/reach/PutComment`,
        input,
        config
      );
      if (status === 200) {
        thunkAPI.dispatch(updateCommentSuccess(input));
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(updateCommentFailure(err.message));
    }
  }
);
export const fetchSubmitCaptcha = createAsyncThunk(
  'participate/submitCaptcha',
  async (input: { projectId: number; stakeholder: any }, thunkAPI: any) => {
    const { projectId, stakeholder } = input;
    thunkAPI.dispatch(submitCaptcha());
    const hasTokenExpired = isTokenExpired(thunkAPI);
    try {
      if (hasTokenExpired) {
        return;
      }
      const config: AxiosRequestConfig = getREACHAuthHeaders();
      const { status } = await axios.put(
        `${environment.apiUrl}/stakeholder/put?captchaResponse=hello&id=${projectId}`,
        stakeholder,
        config
      );
      if (status === 200) {
        thunkAPI.dispatch(submitCaptchaSuccess());
      } else {
        throw new Error('no result from server');
      }
    } catch (err) {
      thunkAPI.dispatch(submitCaptchaFailure(err.message));
    }
  }
);
export default participateSlice.reducer;

/*
LIST OF REQUESTS IN PIMA COMMENT FORM

// NEED TO GET LOGGED IN STAKEHOLDER AND PREFILL FIRST QUESTION - exists already in myprofile slice under stakeholderInfo

https://apicf.hntbpima.com/api/commentformimage/get?project_id=14387
https://apicf.hntbpima.com/api/PublicQuestion/get?project_id=14387

// TOPICS INCLUDED HERE
https://apicf.hntbpima.com/api/project/get?id=14389

LOOKUPS
https://apicf.hntbpima.com/api/lu_comment_category/get
https://apicf.hntbpima.com/api/lu_response_request_type/get
https://apicf.hntbpima.com/api/lu_organization_type/get
https://apicf.hntbpima.com/api/lu_stakeholder_type/get

*/

//https://uploads.hntbpima.com/61573a50-1b18-4d09-83e5-714a81bb067d.jpg
