import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Affiliations, MyActivity, SubHeaderGoBack } from '..';
import Paper from '@material-ui/core/Paper';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearMyProfileState,
  fetchLoggedInStakeholder,
  fetchLUOrgTypes,
  fetchUpdateStakeholder
} from '../../store/slices/myprofile';
import { styled as styledMUI } from '@material-ui/core/styles';
import { ThemeProps } from '../../styled-elements/Icons';
import { resetFeed } from '../../store/slices/feed';
import { setNavigationTab } from '../../store/slices/application';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../store';
import { environment } from '../../environment';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';

const MyPaper = styled(Paper)`
  padding: 8px;
  margin-top: 8px;
  width: ${(props: { isMobile: boolean }) =>
    props.isMobile ? '95%' : '575px'};
`;

const PrivacyContainer = styled.div`
  ${({ theme }: ThemeProps) => `
      background-color: ${theme.palette.secondary.main};
      color: white;
      margin: 0;
      padding: 0;
  `}
`;

const CenteredContainer = styled.div`
  ${({ theme }: any) => `
    margin: ${theme.spacing(0, 2)};
    padding: 2px;
    list-style: none;
    text-align: center;
    font-weight: normal;
  `}
`;

export const MyProfileHeading = styled.h2`
  ${({ theme }: any) => `
  width: 100%;
  margin-left: 8px;
  list-style: none;
  text-align: left;
  font-weight: bold;
  color: ${theme.palette.primary.main};
  font-size: 16px;
  `}
`;

const ContactForm = styled.form`
  width: 100%;
`;

const TextFieldHalfWidth = styledMUI(TextField)({
  width: 'calc(50% - 4px)',
  marginTop: 12,
  '& .MuiFormLabel-root.Mui-disabled': {
    color: '#767676'
  },
  '& .MuiFormLabel-root': {
    color: '#767676'
  }
});

export const MyTextField = styledMUI(TextField)({
  marginTop: 12,
  '& .MuiFormLabel-root.Mui-disabled': {
    color: '#767676'
  },
  '& .MuiFormLabel-root': {
    color: '#767676'
  }
});
interface ContactFormState {
  FIRST_NAME: string;
  LAST_NAME: string;
  STAKE_EMAIL: string;
  HOME_PHONE_NR: string;
  MAIL1_ADDRESS_LINE1: string;
  MAIL1_ADDRESS_LINE2: string;
  MAIL1_CITY: string;
  MAIL1_STATE: string;
  MAIL1_ZIP_CODE: string;
}

export default function MyProfile() {
  const {
    stakeholderInfo,
    isLoadingStakeholder,
    isLoadingOrgTypes,
    hasErrors,
    errorMsg,
    orgTypes
  } = useSelector((state: RootState) => state).myProfileSlice;
  const history = useHistory();
  const [contactFormState, setContactFormState] = useState<ContactFormState>({
    FIRST_NAME: '',
    LAST_NAME: '',
    STAKE_EMAIL: '',
    HOME_PHONE_NR: '',
    MAIL1_ADDRESS_LINE1: '',
    MAIL1_ADDRESS_LINE2: '',
    MAIL1_CITY: '',
    MAIL1_STATE: '',
    MAIL1_ZIP_CODE: ''
  });
  const [checkboxesState, setCheckboxesState] = useState({
    notifyOnNewProjectsWhereILive: true,
    notifyOnNewProjectsWhereIWork: true,
    notifyOnNewProjectsInMyDistrict: false
  });
  const { currentPath, isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  const [editState, setEditState] = useState({
    isEditHomeContact: false
  });

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      formControl: {
        margin: theme.spacing(0, 2)
      },
      formControlDropdown: {
        width: '100%',
        marginTop: 12
      },
      formControlLabel: {
        textAlign: 'left',
        fontSize: 6
      },
      formLabel: {
        margin: theme.spacing(3),
        color: 'black',
        fontSize: 6
      },
      text: {
        margin: theme.spacing(1, 2),
        listStyle: 'none',
        textAlign: 'left',
        paddingLeft: 0
      },
      inputRoot: {
        '&$disabled': {
          color: '#767676'
        }
      },
      disabled: {}
    })
  );
  const classes = useStyles();

  const dispatch = useDispatch();

  const subscribeToHistory = useCallback(() => {
    const unlisten = history.listen((loc, action) => {
      if (action) {
        if (loc.pathname !== currentPath) {
          dispatch(clearMyProfileState());
          unlisten(); // no longer listen after clearing my profile state
        }
      }
    });
  }, [currentPath, dispatch, history]);

  useEffect(() => {
    if (!isLoadingOrgTypes && !orgTypes.length) {
      dispatch(fetchLoggedInStakeholder());
      subscribeToHistory();
      dispatch(setNavigationTab(''));
      dispatch(fetchLUOrgTypes());
      dispatch(resetFeed());
    }
  }, [dispatch, isLoadingOrgTypes, orgTypes, subscribeToHistory]);

  const handleChangeCheckbox =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckboxesState({ ...checkboxesState, [name]: event.target.checked });
    };

  useEffect(() => {
    // Set contact form initial form state so we can revert when cancel is clicked
    if (
      !isLoadingStakeholder &&
      !isLoadingOrgTypes &&
      !hasErrors &&
      stakeholderInfo
    ) {
      if (!contactFormState.STAKE_EMAIL && stakeholderInfo) {
        const {
          FIRST_NAME,
          LAST_NAME,
          STAKE_EMAIL,
          HOME_PHONE_NR,
          MAIL1_ADDRESS_LINE1,
          MAIL1_ADDRESS_LINE2,
          MAIL1_CITY,
          MAIL1_STATE,
          MAIL1_ZIP_CODE
        } = stakeholderInfo;
        setContactFormState({
          FIRST_NAME: FIRST_NAME || '',
          LAST_NAME: LAST_NAME || '',
          STAKE_EMAIL: STAKE_EMAIL || '',
          HOME_PHONE_NR: HOME_PHONE_NR || '',
          MAIL1_ADDRESS_LINE1: MAIL1_ADDRESS_LINE1 || '',
          MAIL1_ADDRESS_LINE2: MAIL1_ADDRESS_LINE2 || '',
          MAIL1_CITY: MAIL1_CITY || '',
          MAIL1_STATE: MAIL1_STATE || '',
          MAIL1_ZIP_CODE: MAIL1_ZIP_CODE || ''
        });
      }
    }
  }, [
    contactFormState.STAKE_EMAIL,
    hasErrors,
    isLoadingOrgTypes,
    isLoadingStakeholder,
    stakeholderInfo
  ]);

  const handleChangeEdit = async (propertyName: any) => {
    const { isEditHomeContact } = editState;
    if (isEditHomeContact && propertyName === 'isEditHomeContact') {
      const {
        // STAKE_EMAIL, not current editable
        FIRST_NAME,
        LAST_NAME,
        HOME_PHONE_NR,
        MAIL1_ADDRESS_LINE1,
        MAIL1_ADDRESS_LINE2,
        MAIL1_CITY,
        MAIL1_STATE,
        MAIL1_ZIP_CODE
      } = contactFormState;
      try {
        await dispatch(
          fetchUpdateStakeholder({
            ...stakeholderInfo,
            // STAKE_EMAIL, not current editable
            FIRST_NAME,
            LAST_NAME,
            HOME_PHONE_NR,
            MAIL1_ADDRESS_LINE1,
            MAIL1_ADDRESS_LINE2,
            MAIL1_CITY,
            MAIL1_STATE,
            MAIL1_ZIP_CODE
          })
        );
        setEditState({ ...editState, isEditHomeContact: false });
      } catch (err) {
        console.log(err);
      }
    } else {
      setEditState({ ...editState, [propertyName]: !editState[propertyName] });
    }
  };

  const {
    notifyOnNewProjectsWhereILive,
    notifyOnNewProjectsWhereIWork,
    notifyOnNewProjectsInMyDistrict
  } = checkboxesState;
  const { isEditHomeContact } = editState;

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Profile - {environment.entityName} REACH</title>
        <link rel="canonical" href={`${environment.clientUrl}/myprofile`} />
      </Helmet>
      <SubHeaderGoBack isArrowBack={false} heading="My Profile" />
      <PrivacyContainer>
        <CenteredContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white' }}
            href={environment.privacyPolicyUrl}
          >
            {`${environment.entityName} Privacy Policy`}
          </a>
        </CenteredContainer>
      </PrivacyContainer>
      {hasErrors && errorMsg && (
        <div role="alert" style={{ marginTop: 10, color: 'red' }}>
          {errorMsg}
        </div>
      )}
      {!stakeholderInfo ? (
        <>
          <span
            role="alert"
            style={{ position: 'absolute', marginLeft: -9999 }}
          >
            Updating user information
          </span>
          <CircularProgress style={{ margin: 16 }} />
        </>
      ) : (
        <div className={classes.root}>
          <MyPaper isMobile={isMobile}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
              >
                <MyProfileHeading>
                  Home Contact Information <br></br>(if applicable)
                </MyProfileHeading>
                {isEditHomeContact && (
                  <Button
                    aria-label="Edit Home Contact Information"
                    style={{
                      height: 25,
                      marginLeft: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      const {
                        FIRST_NAME,
                        LAST_NAME,
                        STAKE_EMAIL,
                        HOME_PHONE_NR,
                        MAIL1_ADDRESS_LINE1,
                        MAIL1_ADDRESS_LINE2,
                        MAIL1_CITY,
                        MAIL1_STATE,
                        MAIL1_ZIP_CODE
                      } = stakeholderInfo;
                      setEditState({ ...editState, isEditHomeContact: false });
                      setContactFormState({
                        FIRST_NAME: FIRST_NAME || '',
                        LAST_NAME: LAST_NAME || '',
                        STAKE_EMAIL: STAKE_EMAIL || '',
                        HOME_PHONE_NR: HOME_PHONE_NR || '',
                        MAIL1_ADDRESS_LINE1: MAIL1_ADDRESS_LINE1 || '',
                        MAIL1_ADDRESS_LINE2: MAIL1_ADDRESS_LINE2 || '',
                        MAIL1_CITY: MAIL1_CITY || '',
                        MAIL1_STATE: MAIL1_STATE || '',
                        MAIL1_ZIP_CODE: MAIL1_ZIP_CODE || ''
                      });
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  aria-label={isEditHomeContact ? 'Save' : 'Edit'}
                  style={{
                    margin: '4px 8px',
                    height: 25,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: isEditHomeContact ? '#21890e' : ''
                  }}
                  onClick={() => handleChangeEdit('isEditHomeContact')}
                  variant="contained"
                  color="primary"
                >
                  <div>{isEditHomeContact ? 'Save' : 'Edit'}</div>
                </Button>
              </div>
              <div>
                {isLoadingStakeholder && (
                  <>
                    <span
                      role="alert"
                      style={{ position: 'absolute', marginLeft: -9999 }}
                    >
                      Updating user information
                    </span>
                    <CircularProgress style={{ margin: 16 }} />
                  </>
                )}
                <ContactForm noValidate autoComplete="off">
                  <TextFieldHalfWidth
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      id: 'first name'
                    }}
                    style={{ marginRight: 4 }}
                    disabled={!isEditHomeContact}
                    fullWidth
                    value={contactFormState.FIRST_NAME}
                    onInput={(e: any) => {
                      setContactFormState({
                        ...contactFormState,
                        FIRST_NAME: DOMPurify.sanitize(e.target.value)
                      });
                    }}
                    label="First Name"
                    variant="outlined"
                    id="first name"
                  />
                  <TextFieldHalfWidth
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      id: 'last name'
                    }}
                    disabled={!isEditHomeContact}
                    fullWidth
                    value={contactFormState.LAST_NAME}
                    onInput={(e: any) =>
                      setContactFormState({
                        ...contactFormState,
                        LAST_NAME: DOMPurify.sanitize(e.target.value)
                      })
                    }
                    label="Last Name"
                    variant="outlined"
                    id="last name"
                  />
                  <MyTextField
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      id: 'email'
                    }}
                    disabled
                    fullWidth
                    value={contactFormState.STAKE_EMAIL}
                    label="Email"
                    variant="outlined"
                    id="email"
                  />

                  <MyTextField
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      id: 'phone'
                    }}
                    disabled={!isEditHomeContact}
                    fullWidth
                    value={contactFormState.HOME_PHONE_NR}
                    onInput={(e: any) =>
                      setContactFormState({
                        ...contactFormState,
                        HOME_PHONE_NR: DOMPurify.sanitize(e.target.value)
                      })
                    }
                    label="Phone"
                    variant="outlined"
                    id="phone"
                  />
                  <MyTextField
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      id: 'address line 1'
                    }}
                    disabled={!isEditHomeContact}
                    fullWidth
                    value={contactFormState.MAIL1_ADDRESS_LINE1}
                    onInput={(e: any) =>
                      setContactFormState({
                        ...contactFormState,
                        MAIL1_ADDRESS_LINE1: DOMPurify.sanitize(e.target.value)
                      })
                    }
                    label="Address Line 1"
                    variant="outlined"
                    id="address line 1"
                  />
                  <MyTextField
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      id: 'address line 2'
                    }}
                    disabled={!isEditHomeContact}
                    fullWidth
                    value={contactFormState.MAIL1_ADDRESS_LINE2}
                    onInput={(e: any) =>
                      setContactFormState({
                        ...contactFormState,
                        MAIL1_ADDRESS_LINE2: DOMPurify.sanitize(e.target.value)
                      })
                    }
                    label="Address Line 2"
                    variant="outlined"
                    id="address line 2"
                  />
                  <MyTextField
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      id: 'city or town'
                    }}
                    disabled={!isEditHomeContact}
                    fullWidth
                    value={contactFormState.MAIL1_CITY}
                    onInput={(e: any) =>
                      setContactFormState({
                        ...contactFormState,
                        MAIL1_CITY: DOMPurify.sanitize(e.target.value)
                      })
                    }
                    label="City/Town"
                    variant="outlined"
                    id="city or town"
                  />
                  <TextFieldHalfWidth
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      id: 'state'
                    }}
                    style={{ marginRight: 4 }}
                    disabled={!isEditHomeContact}
                    fullWidth
                    value={contactFormState.MAIL1_STATE}
                    onInput={(e: any) =>
                      setContactFormState({
                        ...contactFormState,
                        MAIL1_STATE: DOMPurify.sanitize(e.target.value)
                      })
                    }
                    label="State"
                    variant="outlined"
                    id="state"
                  />
                  <TextFieldHalfWidth
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled
                      }
                    }}
                    InputLabelProps={{
                      id: 'zip'
                    }}
                    disabled={!isEditHomeContact}
                    fullWidth
                    value={contactFormState.MAIL1_ZIP_CODE}
                    onInput={(e: any) =>
                      setContactFormState({
                        ...contactFormState,
                        MAIL1_ZIP_CODE: DOMPurify.sanitize(e.target.value)
                      })
                    }
                    label="Zip"
                    variant="outlined"
                    id="zip"
                  />
                </ContactForm>
              </div>
            </div>
          </MyPaper>
          {/*
          MAS-138 Hide Work Contact Details (requires back end updates to implement)
          <Paper className={classes.paper}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
              >
                <MyProfileHeading>
                  Work Contact Information (if applicable)
                </MyProfileHeading>
                <MyButton aria-label=""
                  style={{
                    margin: '4px 16px',
                    height: 25,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                  variant="contained"
                  color="primary"
                >
                  <div>Edit</div>
                </Button>
              </div>
              <div>
                <hr></hr>
                <ul className={classes.text}>
                  <li>Email: joesmith@state.ma.us</li>
                  <li>Phone:</li>
                  <li>Address: 10 Park Plaza #4160</li>
                  <li>City/Town: Boston​</li>
                  <li>State: MA</li>
                  <li>Zip: 02166</li>
                </ul>
              </div>
            </div>
          </Paper> */}
          <Affiliations />
          <MyActivity />
          {/* MAS-224 - temporarily hide items before UAT */}
          {/* <Paper className={classes.paper}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
              >
                <MyProfileHeading>Notifications</MyProfileHeading>
                <MyButton aria-label=""
                  style={{
                    margin: '4px 16px',
                    height: 25,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                  variant="contained"
                  color="primary"
                >
                  <div>Edit</div>
                </Button>
              </div>
              <div>
                <div>
                  <FormControl
                    style={{ margin: 0 }}
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        className={classes.formControlLabel}
                        control={
                          <BlackCheckbox
                            checked={notifyOnNewProjectsWhereILive}
                            onChange={handleChangeCheckbox(
                              'notifyOnNewProjectsWhereILive'
                            )}
                            value="notifyOnNewProjectsWhereILive"
                          />
                        }
                        label={
                          <span style={{ fontSize: 14 }}>
                            Notify me of new projects near where I live
                          </span>
                        }
                      />
                      {notifyOnNewProjectsWhereILive && <Slider default={30} />}

                      <FormControlLabel
                        className={classes.formControlLabel}
                        control={
                          <BlackCheckbox
                            checked={notifyOnNewProjectsWhereIWork}
                            onChange={handleChangeCheckbox(
                              'notifyOnNewProjectsWhereIWork'
                            )}
                            value="notifyOnNewProjectsWhereIWork"
                          />
                        }
                        label={
                          <span style={{ fontSize: 14 }}>
                            Notify me of new projects near where I work
                          </span>
                        }
                      />
                      {notifyOnNewProjectsWhereIWork && <Slider default={20} />}
                      <FormControlLabel
                        control={
                          <BlackCheckbox
                            checked={notifyOnNewProjectsInMyDistrict}
                            onChange={handleChangeCheckbox(
                              'notifyOnNewProjectsInMyDistrict'
                            )}
                            value="notifyOnNewProjectsInMyDistrict"
                            className={classes.formControlLabel}
                          />
                        }
                        label={
                          <span style={{ fontSize: 14 }}>
                            Notify me of new projects in my district
                          </span>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </Paper>
          <Paper className={classes.paper} style={{ marginBottom: 100 }}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs zeroMinWidth>
                <MyProfileHeading>Account Integrations </MyProfileHeading>
                <ul className={classes.text}>
                  <li>Connect Twitter</li>
                  <li>Connect Instagram</li>
                  <li>Connect Facebook</li>
                </ul>
              </Grid>
            </Grid>
          </Paper> */}
        </div>
      )}
    </React.Fragment>
  );
}
