import React, { ReactElement } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core/';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchProjects,
  setProjectsFilterSelection,
  fetchLUCounties,
  setSelectedCounties,
  setSearchText,
  setStartDate,
  setEndDate,
  setSortField,
  setCustomProjectsFilterSelection
} from '../../store/slices/projects';
import { RootState } from '../../store';
import FilterListIcon from '@material-ui/icons/FilterList';
import { MyLink } from '../../styled-elements';
import ProjectSelectionFilterDialog from './ProjectSelectionFilterDialog';
import GenericLookup from '../../models/shared/GenericLookup';
import {
  setIsShowingSystemSnackbarMsg,
  setNavigationTab
} from '../../store/slices/application';
import Cancel from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForward from '@material-ui/icons/ArrowForward';
import SortIcon from '@material-ui/icons/Sort';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const MyButtonChild = styled(Button)`
  border-radius: 15px;
  height: 40px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${MyButtonChild}:not(:first-child) {
    margin-left: 4px;
  }
  margin: 8px 8px 8px 0px;
  @media only screen and (min-width: 600px) {
    margin: 12px 8px 8px 0px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function ProjectHeaderNav(props): ReactElement {
  const dispatch = useDispatch();
  const {
    projectsFilterSelection,
    customProjectsFilterSelection,
    lookups,
    loading,
    hasErrors,
    searchText,
    startDate,
    endDate,
    sortField
  } = useSelector((state: RootState) => state).projectsSlice;

  const [formState, setFormState] = React.useState<{
    selectedCountiesLocal: GenericLookup[];
    isFollowing: boolean;
    hasEvents: boolean;
  }>({
    selectedCountiesLocal: [],
    isFollowing: false,
    hasEvents: false
  });

  const handleFilterClick = (projectsFilterSelection: string) => {
    dispatch(setSearchText(inputValue)); // Move local state search text to redux
    // generically handle project filter options
    switch (projectsFilterSelection) {
      case 'all':
        dispatch(setProjectsFilterSelection('all')); // Now handles isMine and hasEvents
        dispatch(
          fetchProjects({
            isMine: false,
            start: 0,
            length: 50,
            hasEvents: false,
            countyIds: []
          })
        );
        break;
      case 'following':
        dispatch(setProjectsFilterSelection('following'));
        dispatch(
          fetchProjects({
            isMine: true,
            start: 0,
            length: 50,
            hasEvents: false,
            countyIds: []
          })
        );
        break;
      case 'events': //aka MEETINGS button
        dispatch(setProjectsFilterSelection('events'));
        dispatch(
          fetchProjects({
            isMine: false,
            start: 0,
            length: 50,
            hasEvents: true,
            countyIds: []
          })
        );
        break;
      // 'custom' filter case handled in ProjectSelectionFilter component
      default:
        throw new Error('unhandle case in handle project filter click');
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    dispatch(setSearchText(''));
    // dispatch(setSelectedCounties([]));
    // dispatch(setStartDate(null));
    // dispatch(setEndDate(null));
    dispatch(
      fetchProjects({
        isMine: false, // Overwritten by redux
        start: 0,
        length: 50,
        hasEvents: false, // Overwritten by redux
        countyIds: []
      })
    );
    // dispatch(setProjectsFilterSelection('all'));
    dispatch(setCustomProjectsFilterSelection(false));
    dispatch(setIsShowingSystemSnackbarMsg(true));
  };

  // This is to prevent input lag when using redux state
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // dispatch(setSearchText(event.target.value));
    // const newText = event.target.value;
    setInputValue(event.target.value);
  };
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    setInputValue(searchText);
  }, [searchText]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitForm();
  };

  const submitForm = () => {
    dispatch(setSearchText(inputValue));
    dispatch(setSelectedCounties(formState.selectedCountiesLocal));
    dispatch(
      fetchProjects({
        isMine: formState.isFollowing, // Overwritten by redux
        start: 0,
        length: 50,
        hasEvents: formState.hasEvents, // Overwritten by redux
        countyIds: formState.selectedCountiesLocal?.map((c) => c.value)
      })
    );
    // dispatch(setProjectsFilterSelection('custom'));
    if (sortField !== '') {
      dispatch(setCustomProjectsFilterSelection(true));
    } else {
      dispatch(setCustomProjectsFilterSelection(false));
    }
    // if (searchText === '' && startDate === null && endDate === null) {
    //   dispatch(setCustomProjectsFilterSelection(false));
    // } else {
    //   dispatch(setCustomProjectsFilterSelection(true));
    // }
    dispatch(setIsShowingSystemSnackbarMsg(true));
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [selectedSort, setSelectedSort] = React.useState('Date (newest)');

  const handleSortClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (sortOption) => {
    dispatch(setSortField(sortOption));
    handleSortClose();
    submitForm();
  };

  return (
    <>
      <Container style={{ display: 'flex' }}>
        <div
          style={{
            flexGrow: 5,
            display: 'flex',
            justifyContent: 'space-around'
          }}
        >
          <h1 style={{ display: 'none' }}>Projects</h1>
          <MyButtonChild
            aria-selected={projectsFilterSelection === 'all' ? true : false}
            aria-label="Filter projects - display all projects."
            style={{ flexWrap: 'nowrap' }}
            color={projectsFilterSelection === 'all' ? 'primary' : 'secondary'}
            variant="contained"
            onClick={() => handleFilterClick('all')}
          >
            All
          </MyButtonChild>
          <MyButtonChild
            aria-selected={projectsFilterSelection === 'events' ? true : false}
            aria-label="Filter projects - display only projects with events."
            color={
              projectsFilterSelection === 'events' ? 'primary' : 'secondary'
            }
            variant="contained"
            onClick={() => handleFilterClick('events')}
          >
            Events
          </MyButtonChild>
          <MyButtonChild
            aria-selected={
              projectsFilterSelection === 'following' ? true : false
            }
            aria-label="Filter projects - display only projects I follow."
            color={
              projectsFilterSelection === 'following' ? 'primary' : 'secondary'
            }
            variant="contained"
            onClick={() => handleFilterClick('following')}
          >
            Following
          </MyButtonChild>
        </div>
        <div style={{ flexGrow: 1 }} />
        {/* <ProjectSelectionFilterDialog open={open} onClose={handleClose} /> */}

        {/* v2 This works, leads to filter modal */}
        {/* <Tooltip title="View more project filter options">
        <MyLink
          role="button"
          onClick={(event) => {
            event.preventDefault();
            handleOpen();
          }}
          aria-labelledby="filters-label"
          id="project-filters"
          aria-label="View more project filter options."
        >
          <FlexContainer>
            <FilterListIcon
              aria-hidden={true}
              color={
                customProjectsFilterSelection === true ? 'primary' : 'secondary'
              }
            />
            <Typography
              id="filters-label"
              variant="caption"
              color={
                customProjectsFilterSelection === true ? 'primary' : 'secondary'
              }
              style={{ marginTop: 3 }}
            >
              Filter
            </Typography>
          </FlexContainer>
        </MyLink>
      </Tooltip> */}
        {/* v1 This works, leads to old filter page */}
        {/* <Tooltip title="View more project filter options">
        <MyLink
          role="button"
          to="/projects/filters"
          aria-labelledby="filters-label"
          id="project-filters"
          aria-label="View more project filter options."
        >
          <FlexContainer>
            <FilterListIcon
              aria-hidden={true}
              color={
                projectsFilterSelection === 'custom' ? 'primary' : 'secondary'
              }
            />
            <Typography
              id="filters-label"
              variant="caption"
              color={
                projectsFilterSelection === 'custom' ? 'primary' : 'secondary'
              }
              style={{ marginTop: 3 }}
            >
              Old Filter
            </Typography>
          </FlexContainer>
        </MyLink>
      </Tooltip> */}
      </Container>
      <Container style={{ display: 'flex' }}>
        <form onSubmit={handleSubmit} style={{ flexGrow: 5 }}>
          <div style={{ margin: '10px' }}>
            <Box style={{ margin: '10px' }}>
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                // value={searchText}
                value={inputValue}
                onChange={handleTextChange}
                helperText="Search by project name, project description, town/city, or county."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {inputValue && (
                        <>
                          <IconButton
                            type="submit"
                            aria-label="search"
                            edge="end"
                            style={{ display: 'none' }} // Hide the submit button
                          >
                            <CheckCircleIcon />
                          </IconButton>
                          <IconButton
                            aria-label="clear search"
                            onClick={handleClear}
                            edge="end"
                          >
                            <Cancel />
                          </IconButton>
                        </>
                      )}
                    </InputAdornment>
                  ),
                  inputProps: {
                    enterKeyHint: 'search'
                  }
                  // inputProps: {
                  //   enterKeyHint: searchText ? 'search' : undefined
                  // }
                }}
              />
            </Box>
          </div>
        </form>

        {/* v3 in progress */}
        <Tooltip
          title="View more project filter options"
          style={{ flexGrow: 1 }}
        >
          <>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleSortClick}
            >
              <FlexContainer>
                <SortIcon
                  aria-hidden={true}
                  color={
                    customProjectsFilterSelection === true
                      ? 'primary'
                      : 'secondary'
                  }
                />
                <Typography
                  id="filters-label"
                  variant="caption"
                  color={
                    customProjectsFilterSelection === true
                      ? 'primary'
                      : 'secondary'
                  }
                  style={{ marginTop: 3 }}
                >
                  Sort
                </Typography>
              </FlexContainer>
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleSortClose}
            >
              <MenuItem onClick={() => handleSelect('ALPHABETICAL_AZ')}>
                Alphabet (A - Z)
              </MenuItem>
              <MenuItem onClick={() => handleSelect('ALPHABETICAL_ZA')}>
                Alphabet (Z - A)
              </MenuItem>
              <MenuItem onClick={() => handleSelect('EVENT_DATE_NEWEST')}>
                Date (Newest)
              </MenuItem>
              <MenuItem onClick={() => handleSelect('EVENT_DATE_OLDEST')}>
                Date (Oldest)
              </MenuItem>
            </Menu>
          </>
        </Tooltip>
      </Container>
    </>
  );
}
