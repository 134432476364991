import WorkIcon from '@material-ui/icons/Work';
import SearchIcon from '@material-ui/icons/Search';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import SmsIcon from '@material-ui/icons/Sms';
import { MeetingRoom, AccountCircle, ArrowBack } from '@material-ui/icons/';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import { Theme } from '@material-ui/core';

export const MyIconButton = styled(IconButton)`
  padding: 0px !important;
`;

// LARGE ICONS
export interface ThemeProps {
  theme: Theme;
}

export const MyWorkIcon = styled(WorkIcon)`
  ${(props) => `
    font-size: 100px !important;
    color: ${props.theme.palette.secondary.main};
  `}
`;

export const MyMeetingRoomIcon = styled(MeetingRoom)`
  ${({ theme }: ThemeProps) => `
    color: ${theme.palette.secondary.main};
    font-size: 24px !important;
  `}
`;

export const MyAccountCircleIcon = styled(AccountCircle)`
  ${({ theme }: ThemeProps) => `
    color: ${theme.palette.secondary.main};
    font-size: 24px !important;
  `}
`;

export const MyLargeBlueSmsIcon = styled(SmsIcon)`
  font-size: 40px !important;
`;

// SMALL ICONS

export const MyThumbUpIcon = styled(ThumbUpIcon)`
  font-size: 24px !important;
`;

export const MySmsIcon = styled(SmsIcon)`
  font-size: 24px !important;
  margin-right: 8px !important;
`;

export const MyBlueSmsIcon = styled(SmsIcon)`
  font-size: 24px !important;
`;

export const MyBlueHomeIcon = styled(HomeIcon)`
  font-size: 24px !important;
`;

export const MyBlueCalendarIcon = styled(CalendarTodayIcon)`
  font-size: 24px !important;
`;

export const MyBlueDashboardIcon = styled(DashboardIcon)`
  font-size: 24px !important;
`;

export const MySmallAccountCircleIcon = styled(AccountCircle)`
  ${({ theme }: ThemeProps) => `
    color: ${theme.palette.secondary.main};
    font-size: 24px !important;
  `}
`;

export const MyHelpOutlineIcon = styled(HelpOutlineIcon)`
  ${({ theme }: ThemeProps) => `
    color: ${theme.palette.secondary.main};
    font-size: 24px !important;
  `}
`;

export const MySmallSearchIcon = styled(SearchIcon)`
  ${({ theme }: ThemeProps) => `
    color: ${theme.palette.secondary.main};
    margin-right: 10px;
    font-size: 24px !important;
  `}
`;

export const MyExitToAppIcon = styled(ExitToAppIcon)`
  ${({ theme }: ThemeProps) => `
    color: ${theme.palette.secondary.main};
    font-size: 24px !important;
  `}
`;

export const MyDeleteIcon = styled(DeleteIcon)`
  ${({ theme }: ThemeProps) => `
    color: ${theme.palette.secondary.main};
    font-size: 18px !important;
  `}
`;

export const MyEditIcon = styled(EditIcon)`
  ${({ theme }: ThemeProps) => `
    color: ${theme.palette.secondary.main};
    font-size: 18px !important;
  `}
`;

export const MyVisibilityIcon = styled(VisibilityIcon)`
  ${({ theme }: ThemeProps) => `
    color: ${theme.palette.secondary.main};
    font-size: 18px !important;
  `}
`;

export const MyVisibilityOffIcon = styled(VisibilityOffIcon)`
  ${({ theme }: ThemeProps) => `
    color: ${theme.palette.secondary.main};
    font-size: 18px !important;
  `}
`;

export const MyErrorIcon = styled(ErrorIcon)`
  font-size: 18px !important;
  margin-right: 8px;
`;
