import React, { useEffect } from 'react';
import List from '@material-ui/core/List';
import { ProjectComment, FeedHeaderNav, LoadMore } from '../';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchComments,
  setHasCheckedInitialState,
  setIsMine
} from '../../store/slices/feed';
import { CircularProgress } from '@material-ui/core';
import { setNavigationTab } from '../../store/slices/application';
import { AccountForBottomNavMargin, PaddedDiv } from '../../styled-elements';
import { RootState } from '../../store';
import styled from 'styled-components';
import { environment } from '../../environment';
import { Helmet } from 'react-helmet';

interface Props {
  isMine?: boolean;
  location: {
    hash: string;
    key: string;
    pathname: string;
    search: string;
    state: any;
  };
}

const Container = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const MyList = styled(List)`
  padding: 0px 8px 8px 8px;
  max-width: 100vw;
  &:not:first-child {
    padding: 8px;
  }
`;

export default function Feed(props: Props) {
  const { isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;

  const dispatch = useDispatch();
  const {
    comments,
    loading,
    hasErrors,
    errorMsg,
    isMine,
    isFollowedComments,
    hasCheckedInitialState,
    isAllCommentsLoaded
  } = useSelector((state: RootState) => state).feedSlice;

  useEffect(() => {
    if (!loading && !comments && hasCheckedInitialState) {
      dispatch(
        fetchComments({
          isMine,
          isFollowedComments,
          start: 0,
          length: 20
        })
      ); // initial fetch
    }
  }, [
    comments,
    dispatch,
    hasCheckedInitialState,
    isFollowedComments,
    isMine,
    loading
  ]);

  useEffect(() => {
    const isMineURL = new URLSearchParams(props.location.search).get('isMine');
    if (props.location) {
      if (!hasCheckedInitialState) {
        dispatch(setHasCheckedInitialState(true));
      }
      if (!isMine && isMineURL) {
        dispatch(setIsMine(true));
      }
    }
  }, [dispatch, hasCheckedInitialState, isMine, props.location]);

  useEffect(() => {
    if (!hasErrors && !loading && !comments) {
      dispatch(setNavigationTab('feed'));
    }
  }, [comments, dispatch, hasErrors, loading]);

  return (
    <>
      <Container isMobile={isMobile}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Feed - {environment.entityName} REACH</title>
          <link rel="canonical" href={`${environment.clientUrl}/feed`} />
        </Helmet>
        <div>
          {hasErrors && errorMsg && (
            <div role="alert" style={{ marginTop: 10, color: 'red' }}>
              {errorMsg}
            </div>
          )}
          {loading && (
            <>
              <span
                role="alert"
                style={{ position: 'absolute', marginLeft: -9999 }}
              >
                Loading your feed of project comments
              </span>
              <CircularProgress style={{ marginTop: 16 }} />
            </>
          )}
          {!loading && <FeedHeaderNav />}
          {comments && comments.length > 0 && (
            <MyList>
              {comments.map((comment, idx) => (
                <ProjectComment comment={comment} key={idx} />
              ))}
            </MyList>
          )}
          {!loading && comments && comments.length === 0 && (
            <div>
              Your feed is empty based on the current filter selection. <br />
              <br />
            </div>
          )}
          {/* Paginator */}
          {!loading && !isAllCommentsLoaded ? <LoadMore /> : <></>}
        </div>
      </Container>
      <AccountForBottomNavMargin />
    </>
  );
}
