import React, { useCallback, useEffect, useState } from 'react';
import { DashboardMap, DashboardTable } from '..';
import { useSelector, useDispatch } from 'react-redux';
import { setNavigationTab } from '../../store/slices/application';
import {
  clearDashboardState,
  fetchDashComments,
  fetchLUStakeSupport
} from '../../store/slices/dashboard';
import { Button, CircularProgress } from '@material-ui/core';
import { use100vh } from 'react-div-100vh';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../store';
import { environment } from '../../environment';
import { Helmet } from 'react-helmet';
//
const DashboardContainer: React.FC = () => {
  const { loading, hasErrors, dashComments, lookups } = useSelector(
    (state: RootState) => state
  ).dashboardSlice;
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [mapOrTable, setMapOrTable] = useState('map');
  const { currentPath } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  const dispatch = useDispatch();
  const height100vh = use100vh();
  const history = useHistory();

  const subscribeToHistory = useCallback(() => {
    const unlisten = history.listen((loc, action) => {
      if (action) {
        if (loc.pathname !== currentPath) {
          dispatch(clearDashboardState());
          unlisten(); // no longer listen after clearing dashboard state
        }
      }
    });
  }, [currentPath, dispatch, history]);

  useEffect(() => {
    if (!hasErrors && !loading && !lookups.stakeholderSupport.length) {
      // runs first as map favorability depends on it
      dispatch(fetchLUStakeSupport());
    }
  }, [dispatch, hasErrors, loading, lookups.stakeholderSupport.length]);

  useEffect(() => {
    if (
      !hasErrors &&
      !loading &&
      dashComments === null &&
      lookups.stakeholderSupport.length
    ) {
      subscribeToHistory();
      dispatch(setNavigationTab('dashboard'));
      dispatch(fetchDashComments());
    }
  }, [
    dashComments,
    dispatch,
    hasErrors,
    loading,
    lookups.stakeholderSupport,
    subscribeToHistory
  ]);
  const toggleMapOrTable = (state: string) => {
    if (state === 'map') {
      setMapOrTable('table');
    }
    if (state === 'table') {
      setMapOrTable('map');
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard - {environment.entityName} REACH</title>
        <link rel="canonical" href={`${environment.clientUrl}/dashboard`} />
      </Helmet>
      {/* <DashboardStats /> */}
      {/* MAS-181 - ticket to hide DashboardStats */}
      {/* subtract header (56px), footer (75px) and DashboardStats (71px) */}
      <div
        style={{
          height: height100vh - 56 - 75,
          position: mapOrTable === 'map' ? 'relative' : 'static'
        }}
      >
        {dashComments && lookups.stakeholderSupport.length ? (
          <>
            {!isMapLoading && (
              <Button
                onClick={() => {
                  if (mapOrTable === 'table') {
                    setIsMapLoading(true);
                  }
                  toggleMapOrTable(mapOrTable);
                }}
                variant="contained"
                color="primary"
                style={{
                  zIndex: 1,
                  position: mapOrTable === 'map' ? 'absolute' : 'static',
                  margin: mapOrTable === 'table' ? '8px 0px 8px 0px' : '0px',
                  top: 58,
                  right: 16
                }}
              >
                {mapOrTable === 'map' && 'View Map Data in Table'}
                {mapOrTable === 'table' && 'Return to Map'}
              </Button>
            )}
            {mapOrTable === 'map' && (
              <DashboardMap
                isMapLoading={isMapLoading}
                setIsMapLoading={setIsMapLoading}
              />
            )}
            {mapOrTable === 'table' && <DashboardTable />}
          </>
        ) : (
          <>
            <span
              role="alert"
              style={{ position: 'absolute', marginLeft: -9999 }}
            >
              Loading dashboard map
            </span>
            <CircularProgress style={{ margin: 24 }}></CircularProgress>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default DashboardContainer;
