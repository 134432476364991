import React, { ReactElement } from 'react';

export default function MassDOTPrivacyPolicy(): ReactElement {
  return (
    <div>
      By continuing, you agree to our User Agreement, Privacy Policy and Cookie
      Policy.
    </div>
  );
}
