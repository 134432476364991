import React, { useEffect } from 'react';
import { FieldsObject } from 'react-ga';
import { RouteComponentProps } from 'react-router-dom';
import { ga4react } from '.';
import { environment } from './environment';
// import GoogleAnalytics from 'react-ga';
// GoogleAnalytics.initialize('UA-2983547-34'); // if we add GA back, it will need to be passed as an Amplify SAM parameter

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {}
) => {
  const trackPage = (page: string) => {
    // TODO: uncomment these two lines to add Google Analytics/GA back if needed
    // GoogleAnalytics.set({ page, ...options });
    // GoogleAnalytics.pageview(page);
    if (
      environment.googleAnalyticsId &&
      environment.googleAnalyticsId !== 'none'
    ) {
      ga4react.pageview(page);
      ga4react.gtag('event', 'pageview', page); // or your custom gtag event
    }
  };

  return (props: P) => {
    if (
      environment.googleAnalyticsId &&
      environment.googleAnalyticsId !== 'none'
    ) {
      useEffect(() => {
        trackPage(props.location.pathname);
      }, [props.location.pathname]);
    }

    return <WrappedComponent {...props} />;
  };
};
