import React, { ReactElement, useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogActions, TextField } from '@material-ui/core';
import { Spacer } from '../../styled-elements';
import {
  clearEventSelectionState,
  fetchRegisterForEvent,
  setHasClickedMultiLanguageEvent,
  setIsNumAttendeesDialogOpen,
  setSelectedEvent
} from '../../store/slices/projects';
import { environment } from '../../environment';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { useHistory } from 'react-router-dom';

interface Props {
  projectId: number;
}

export default function InputNumAttendeesDialog({
  projectId
}: Props): ReactElement {
  const [attendanceCount, setAttendanceCount] = useState(1);
  const { stakeholderInfo } = useSelector(
    (state: RootState) => state
  ).myProfileSlice;

  const { isNumAttendeesDialogOpen, selectedEventDetails } = useSelector(
    (state: RootState) => state
  ).projectsSlice;
  const dispatch = useDispatch();

  const register = () => {
    if (selectedEventDetails && stakeholderInfo) {
      if (selectedEventDetails.OnlineMeetings.length) {
        // is Multi-Language event
        dispatch(setHasClickedMultiLanguageEvent(true));
      } else {
        const {
          PeId: eventId,
          PeGuid,
          EventSettings,
          OnlineResource
        } = selectedEventDetails;
        dispatch(fetchRegisterForEvent({ eventId, attendanceCount }));
        if (!selectedEventDetails.OnlineMeetings.length) {
          return; // in person meeting early return
        }
        /* EventSettings is a bitflag accounting for isVPH, isPodium, and isAnon
          public enum EventSettings : int
          {
          IS_EMBEDDED = 1 << 0,
          IS_PODIUM = 1 << 1,
          IS_ANON = 1 << 2,
          }
        */
        // TODO: confirm what EventSettings 8, 9, 10 and 11 mean for Iowa - this may not be the appropriate way to handle
        if (EventSettings === 0 || EventSettings === 8) {
          // all bitflags false, open directly to resource
          window.open(OnlineResource);
        } else if (
          EventSettings === 1 ||
          EventSettings === 9 ||
          EventSettings === 10 ||
          EventSettings === 14
        ) {
          // is embedded but not podium (do not include "stakeholder" in VPH link)
          window.open(
            `${environment.onlineMeetingsUrl}/?peGuid=${PeGuid}&stakeGuid=${stakeholderInfo.STAKE_GUID}`
          );
        } else if (
          EventSettings === 2 ||
          EventSettings === 7 ||
          EventSettings === 11
        ) {
          // IF 2: is embedded podium (include "stakeholder" in VPH link)
          // IF 7: all 3 bools are true including isAnon - handle the same as EventSettings = 2 because isAnon applies to the event registration form. We already know the user in REACH so they can't be anon.
          window.open(
            `${environment.onlineMeetingsUrl}/stakeholder?peGuid=${PeGuid}&stakeGuid=${stakeholderInfo.STAKE_GUID}`
          );
        }
        dispatch(clearEventSelectionState());
      }
    }
  };
  return (
    <Dialog
      onClose={() => dispatch(setIsNumAttendeesDialogOpen(false))}
      aria-labelledby=""
      open={isNumAttendeesDialogOpen}
    >
      <DialogTitle>
        <div role="alert">
          Please input the number of attendees for your registration
        </div>
        <Spacer />
        <TextField
          onInput={(e: any) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 1);
          }}
          onChange={(e) => setAttendanceCount(Number(e.target.value))}
          value={attendanceCount}
          fullWidth
          id="standard-number"
          label="Number of Attendees"
          type="number"
        />
        <Spacer />
      </DialogTitle>
      <DialogActions>
        <Button
          disabled={attendanceCount === 0}
          onClick={() => {
            register();
            dispatch(setIsNumAttendeesDialogOpen(false));
          }}
          color="primary"
          variant="contained"
        >
          Register
        </Button>
        <Button
          color="secondary"
          onClick={() => dispatch(setIsNumAttendeesDialogOpen(false))}
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
