import grey from '@material-ui/core/colors/grey';
import { Button } from '@material-ui/core/';
import styled from 'styled-components';
import { styled as styledMUI } from '@material-ui/core/styles';

export const SubmitButton = styled.button`
  padding: 10px;
  border: 1px solid black;
`;

export const BlueButton = styledMUI(Button)({
  backgroundColor: '#2b79c6',
  color: 'white'
});

export const GreyButton = styledMUI(Button)({
  color: grey[700]
});
