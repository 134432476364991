import { combineReducers, configureStore } from '@reduxjs/toolkit';
import projectsSlice from './slices/projects';
import userSlice from './slices/user';
import applicationSlice from './slices/application';
import feedSlice from './slices/feed';
import myProfileSlice from './slices/myprofile';
import participateSlice from './slices/participate';
import dashboardSlice from './slices/dashboard';
import notificationsSlice from './slices/notifications';

const combinedReducer = combineReducers({
  userSlice, // used for login state and tokens etc
  applicationSlice, // used for navigation state
  myProfileSlice, // only used for my profile
  projectsSlice,
  feedSlice,
  participateSlice,
  dashboardSlice,
  notificationsSlice
});

const rootReducer = (state, action) => {
  // this resets the entire store to its initial state on logout
  if (action.type === 'user/setIsLoggedOut') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
