import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MUISlider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    width: 200
  }
});
interface Props {
  default: number;
}

export default function Slider(props: Props): ReactElement {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.default);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs>
          <MUISlider
            max={50}
            min={1}
            value={value}
            onChange={handleChange}
            aria-labelledby="continuous-slider"
          />
        </Grid>
        <Grid item>
          <p>Up to {value} miles</p>
        </Grid>
      </Grid>
    </div>
  );
}
