import React, { ReactElement } from 'react';

export default function IowaDOTPrivacyPolicy(): ReactElement {
  return (
    <>
      {`By continuing, you agree to all Iowa DOT policies and statements regarding `}
      <a
        href="https://iowadot.gov/policies_and_statements/Terms-of-Use"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Use
      </a>
      ,{' '}
      <a
        href="https://iowadot.gov/policies_and_statements/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Accessibility
      </a>
      , and{' '}
      <a
        href="https://iowadot.gov/policies_and_statements/Privacy-Policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy
      </a>
    </>
  );
}
