import React from 'react';
import { AmplifyLoginForm, SubHeaderGoBack } from '..';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Spacer } from '../../styled-elements';
import { environment } from '../../environment';
import { Helmet } from 'react-helmet';

export default function LoginPage() {
    const { isLoggedInREACH } = useSelector(
        (state: RootState) => state
    ).userSlice;

    const getSubHeaderGoBackLogin = () => {
        switch (environment.entityName) {
            case 'Iowa DOT':
                return (
                    <SubHeaderGoBack
                        isArrowBack={true}
                        goBackLink={''}
                        heading="Log in - REACH"
                    />
                );
            case 'Mass DOT':
                return (
                    <SubHeaderGoBack
                        isArrowBack={true}
                        goBackLink={''}
                        heading="Log in - REACH"
                    />
                );
            case 'Maine DOT':
                return (
                    <SubHeaderGoBack
                        isArrowBack={true}
                        goBackLink={''}
                        heading="Log in - MaineDOT Public Involvement"
                    />
                );
            case 'Connecticut DOT':
                return (
                    <SubHeaderGoBack
                        isArrowBack={true}
                        goBackLink={''}
                        heading="Log in - REACH"
                    />
                );
            default:
                throw new Error(
                    'unhandled case in LoginPage -> getSubHeaderGoBackLogin()'
                );
        }
    };
    return (
        <div className="App">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Login - {environment.entityName} REACH</title>
                <link rel="canonical" href={`${environment.clientUrl}/login`} />
            </Helmet>
            <div>
                {getSubHeaderGoBackLogin()}
                <AmplifyLoginForm />
                <Spacer />
                {!isLoggedInREACH && (
                    <Link
                        to="/forgotpassword"
                        style={{ textDecoration: 'none', color: '#2972BB' }}
                        aria-label="Forgot password?"
                    >
                        Forgot password?
                    </Link>
                )}
            </div>
        </div>
    );
}
