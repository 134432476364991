import { Button } from '@material-ui/core';
import React, { ReactElement } from 'react';
import history from '../../history';
import { setSelectedPIMAUser } from '../../store/slices/user';
import { useDispatch } from 'react-redux';

interface Props {
  user: any;
}

export default function ExistingPIMAUser({ user }: Props): ReactElement {
  const dispatch = useDispatch();
  const handleThisIsMe = () => {
    dispatch(setSelectedPIMAUser(user));
    history.push('/signup/confirmaccount');
  };
  const handleThisIsNOTMe = () => {
    history.push('/signup/confirmaccount');
  };
  return (
    <div
      style={{
        border: '1px solid black',
        padding: 16
      }}
    >
      <div
        style={{
          marginBottom: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <div>Name: {`${user.FIRST_NAME} ${user.LAST_NAME}`}</div>
        <div>Address: {user.MAIL1_ADDRESS_LINE1}</div>
        <div>City: {user.MAIL1_CITY}</div>
        <div>State: {user.MAIL1_STATE}</div>
        <div>Zipcode: {user.MAIL1_ZIP_CODE}</div>
      </div>
      <div style={{ margin: '8px 0px' }}>
        <Button
          aria-label="This is me"
          onClick={handleThisIsMe}
          style={{ marginRight: 8 }}
          variant="contained"
          color="primary"
        >
          This is me
        </Button>
        <Button
          aria-label="This is NOT me"
          onClick={handleThisIsNOTMe}
          variant="contained"
          color="secondary"
        >
          This is NOT me
        </Button>
      </div>
    </div>
  );
}
