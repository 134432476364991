import React, { useEffect } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import {
  Project,
  NotificationsContainer,
  DashboardContainer,
  Feed,
  AllProjectsList,
  MyProfile,
  ProjectMap,
  LandingPage,
  LoginPage,
  Participate,
  ProjectSelectionFilter,
  ProjectCommentForm,
  ParticipateCommentFilter,
  MeetingDetails,
  AmplifySignUpForm,
  ConfirmAccount,
  ForgotPassword
} from './components';
import { withTracker } from './withTracker';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLogout } from './store/slices/user';
import { environment } from './environment';

// console.log('environment:', environment);

// wrapping HOC in this fashion allows the withTracker function to only run once on page change
// https://github.com/react-ga/react-ga/issues/401
const LandingPageWithTracker = withTracker(LandingPage);
const AmplifySignUpFormWithTracker = withTracker(AmplifySignUpForm);
const ConfirmAccountWithTracker = withTracker(ConfirmAccount);
const LoginPageWithTracker = withTracker(LoginPage);
const ForgotPasswordWithTracker = withTracker(ForgotPassword);
const ParticipateWithTracker = withTracker(Participate);
const ParticipateCommentFilterWithTracker = withTracker(
  ParticipateCommentFilter
);
const ProjectCommentFormWithTracker = withTracker(ProjectCommentForm);
const FeedWithTracker = withTracker(Feed);
const AllProjectsListWithTracker = withTracker(AllProjectsList);
const ProjectMapWithTracker = withTracker(ProjectMap);
const ProjectSelectionFilterWithTracker = withTracker(ProjectSelectionFilter);
const ProjectWithTracker = withTracker(Project);
const MeetingDetailsWithTracker = withTracker(MeetingDetails);
const MyProfileWithTracker = withTracker(MyProfile);
const NotificationsContainerWithTracker = withTracker(NotificationsContainer);
const DashboardContainerWithTracker = withTracker(DashboardContainer);

const Routes = (props) => {
  const dispatch = useDispatch();

  const { isLoggedInREACH } = useSelector((state) => {
    return state;
  }).userSlice;
  useEffect(() => {
    if (localStorage.getItem('username') === 'null') {
      dispatch(fetchLogout());
    }
  }, [dispatch]);
  return (
    <Switch>
      <Route exact path="/" component={LandingPageWithTracker} />
      {/* ADD SIGNUP/LOGIN BACK AFTER PROD LAUNCH */}
      <Route exact path="/signup" component={AmplifySignUpFormWithTracker} />
      <Route
        exact
        path="/signup/confirmaccount"
        component={ConfirmAccountWithTracker}
      />
      <Route exact path="/login" component={LoginPageWithTracker} />
      <Route
        exact
        path="/forgotpassword"
        component={ForgotPasswordWithTracker}
      />
      <Route exact path="/participate" component={ParticipateWithTracker} />
      {/*
      NOT BEING USED
      <Route
        exact
        path="/participate/comment"
        component={ParticipateCommentFilterWithTracker}
      />
      */}
      <Route
        path="/participate/comment/:projectId"
        component={ProjectCommentFormWithTracker}
      />
      <Route exact path="/feed" component={FeedWithTracker} />
      {/*
      in case we add more feed filters at a later date.
      <Route
        exact
        path="/feed/filters"
        component={FeedFilters)}
      />
      */}
      <Route exact path="/projects/" component={AllProjectsListWithTracker} />
      <Route
        path="/projects/projectmap/:projectId"
        component={ProjectMapWithTracker}
      />
      <Route
        exact
        path="/projects/filters"
        component={ProjectSelectionFilterWithTracker}
      />
      <Route exact path="/projects/:projectId" component={ProjectWithTracker} />
      {/*
      NOT BEING USED
      <Route
        exact
        path="/projects/:projectId/meetingdetails"
        component={MeetingDetailsWithTracker}
      />
      */}
      <Route exact path="/myprofile/" component={MyProfileWithTracker} />
      <Route
        path="/notifications"
        component={NotificationsContainerWithTracker}
      />
      <Route path="/dashboard" component={DashboardContainerWithTracker} />
      {isLoggedInREACH && (
        <Switch>
          {/* Fallback if logged in and user attempts to go to a route that doesn't exist - redirect to projects view */}
          <Redirect to="/projects"></Redirect>
        </Switch>
      )}
    </Switch>
  );
};

export default withRouter(Routes);
