import { Tooltip } from '@material-ui/core';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const DivAlignedLeft = styled.div`
  text-align: left;
`;

const MyUL = styled.ul`
  list-style: none;
  padding: 0px;
`;

const MyLI = styled.li`
  padding: 0px;
`;

const MyH2 = styled.h2`
  margin-bottom: 8px;
  font-size: 16px;
`;

export default function PasswordRequirements(): ReactElement {
  return (
    <DivAlignedLeft>
      <MyH2>Password Requirements</MyH2>
      <MyUL>
        <MyLI>8 characters</MyLI>
        <MyLI>At least 1 uppercase letter</MyLI>
        <MyLI>At least 1 lowercase letter</MyLI>
        <MyLI>At least 1 number</MyLI>
        <Tooltip title="Special characters include !@#$%^&*()_+[]{}|;:,.<>?">
          <MyLI>At least 1 special character</MyLI>
        </Tooltip>
      </MyUL>
    </DivAlignedLeft>
  );
}
