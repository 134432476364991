import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { AccountForBottomNavMargin } from '../../styled-elements';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0px 8px 83px 8px;
`;

export default function DashboardTable(): ReactElement {
  const { dashComments, lookups } = useSelector(
    (state: RootState) => state
  ).dashboardSlice;

  return (
    <Container>
      <TableContainer component={Paper}>
        <Table aria-label="Dashboard table - includes project comments from projects you follow">
          <TableHead>
            <TableRow>
              <TableCell>Project Name</TableCell>
              <TableCell>Project ID</TableCell>
              <TableCell>Stakeholder Comment</TableCell>
              <TableCell>Stakeholder Comment Date</TableCell>
              <TableCell>Stakeholder Name</TableCell>
              <TableCell>Stakeholder Support Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dashComments?.map((row, idx) => (
              <TableRow key={idx}>
                <TableCell component="th" scope="row">
                  {row.ProjectDesc}
                </TableCell>
                <TableCell>{row.ProjectId}</TableCell>
                <TableCell>{row.StakeComment}</TableCell>
                <TableCell>{row.StakeCommentDate}</TableCell>
                <TableCell>{row.StakeholderName}</TableCell>
                <TableCell>
                  {
                    lookups.stakeholderSupport.find(
                      (entry) =>
                        entry.STAKEHOLDER_SUPPORT_ID ===
                        row.StakeholderSupportId
                    ).STAKEHOLDER_SUPPORT
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <AccountForBottomNavMargin />
      </TableContainer>
    </Container>
  );
}
