import React, { ReactElement, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WebMap, SubHeaderGoBack } from '../';
import {
  clearSelectedProject,
  fetchSelectedProject
} from '../../store/slices/projects';
import { useHistory } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import { RootState } from '../../store';
import { Helmet } from 'react-helmet';
import { environment } from '../../environment';

export default function ProjectMap(props): ReactElement {
  const { loading, selectedProject } = useSelector(
    (state: RootState) => state
  ).projectsSlice;
  const { currentPath } = useSelector(
    (state: RootState) => state
  ).applicationSlice;
  const { projectId } = props.match.params;
  const dispatch = useDispatch();
  const history = useHistory();
  const height100vh = use100vh();

  const subscribeToHistory = useCallback(() => {
    const unlisten = history.listen((loc, action) => {
      if (action) {
        if (loc.pathname !== currentPath && !loc.pathname.includes(projectId)) {
          dispatch(clearSelectedProject());
          unlisten(); // no longer listen after clearing project on page leave
        }
      }
    });
  }, [currentPath, dispatch, history, projectId]);

  useEffect(() => {
    if (projectId && !loading && !selectedProject) {
      subscribeToHistory();
      dispatch(fetchSelectedProject(projectId));
    }
  }, [dispatch, loading, projectId, selectedProject, subscribeToHistory]);

  return (
    <>
      {selectedProject && (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Project Map - {environment.entityName} REACH</title>
            <link
              rel="canonical"
              href={`${environment.clientUrl}/projects/projectmap/${selectedProject.ProjectId}`}
            />
          </Helmet>
          <SubHeaderGoBack
            isArrowBack={true}
            heading={`Project Map: ${selectedProject.ProjectNr}`}
            goBackLink={`/projects/${selectedProject.ProjectId}`}
          />
          <div style={{ height: height100vh - 56 - 75 }}>
            {/* subtract header and footer height */}
            <WebMap
              isProjectCommentMap={false}
              MAP_ID={selectedProject.MapId}
            />
          </div>
        </>
      )}
    </>
  );
}
