import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Theme
} from '@material-ui/core';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { MyProfileHeading, MyTextField } from './MyProfile';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUpdateStakeholder } from '../../store/slices/myprofile';
import { RootState } from '../../store';
import styled from 'styled-components';
const MySelect = styled(Select)({
  '& .MuiInputLabel-root.Mui-disabled': {
    color: '#767676 !important'
  }
});

const MyPaper = styled(Paper)`
  padding: 8px;
  margin-top: 8px;
  width: ${(props: { isMobile: boolean }) =>
    props.isMobile ? '95%' : '575px'};
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    formControl: {
      margin: theme.spacing(0, 2)
    },
    formControlDropdown: {
      width: '100%',
      marginTop: 12
    },
    formControlLabel: {
      textAlign: 'left',
      fontSize: 6
    },
    formLabel: {
      margin: theme.spacing(3),
      color: 'black',
      fontSize: 6
    },
    text: {
      margin: theme.spacing(1, 2),
      listStyle: 'none',
      textAlign: 'left',
      paddingLeft: 0
    },
    inputRoot: {
      '&$disabled': {
        color: '#767676'
      }
    },
    disabled: {}
  })
);

export default function Affiliations(): ReactElement {
  const { isMobile } = useSelector(
    (state: RootState) => state
  ).applicationSlice;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { stakeholderInfo, isLoadingStakeholder, isLoadingOrgTypes, orgTypes } =
    useSelector((state: RootState) => state).myProfileSlice;

  const [isEdit, setIsEdit] = useState(false);
  const [orgName, setOrgName] = useState('');
  const initialOrgName = useRef(''); // store so we can reset to initial on cancel
  const getSelectedOrgLookup = useCallback(() => {
    let res;
    if (
      stakeholderInfo &&
      stakeholderInfo.ORGANIZATION_TYPE &&
      orgTypes.length
    ) {
      res = orgTypes.find(
        (o) => o.ORGANIZATION_TYPE === stakeholderInfo.ORGANIZATION_TYPE
      ).ORGANIZATION_TYPE_ID;
    } else {
      res = '';
    }
    return res;
  }, [orgTypes, stakeholderInfo]);

  const [dropdownsState, setDropdownsState] = useState({
    selectedOrgType: getSelectedOrgLookup()
  });

  const handleChangeEdit = async () => {
    if (isEdit) {
      const { selectedOrgType } = dropdownsState;
      try {
        await dispatch(
          fetchUpdateStakeholder({
            ...stakeholderInfo,
            ORGANIZATION: orgName,
            StakeholderOrgs: [
              {
                STAKE_ID: 0,
                ORGANIZATION_TYPE_ID: selectedOrgType,
                ORGANIZATION_TYPE: ''
              }
            ]
          })
        );
        setIsEdit(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      setIsEdit(true);
    }
  };
  useEffect(() => {
    // set initial form state
    if (!isLoadingOrgTypes && !isLoadingStakeholder && stakeholderInfo) {
      setDropdownsState({
        selectedOrgType: getSelectedOrgLookup()
      });
      const orgName = stakeholderInfo.ORGANIZATION;
      if (orgName) {
        setOrgName(orgName);
        initialOrgName.current = orgName;
      }
    }
  }, [
    getSelectedOrgLookup,
    isLoadingOrgTypes,
    isLoadingStakeholder,
    orgTypes,
    stakeholderInfo
  ]);
  const handleChangeDropdown =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setDropdownsState({ ...dropdownsState, [name]: event.target.value });
    };

  const { selectedOrgType } = dropdownsState;

  return (
    <MyPaper isMobile={isMobile}>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <MyProfileHeading>Affiliations </MyProfileHeading>

          {isEdit && (
            <Button
              aria-label="Cancel"
              style={{
                height: 25,
                marginLeft: 8,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                setIsEdit(false);
                setDropdownsState({
                  ...dropdownsState,
                  selectedOrgType: getSelectedOrgLookup()
                });
                setOrgName(initialOrgName.current);
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            aria-label={isEdit ? 'Save' : 'Edit'}
            disabled={isEdit && !selectedOrgType}
            style={{
              margin: '4px 16px',
              height: 25,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundColor: isEdit ? '#21890e' : '',
              color: 'white'
            }}
            onClick={handleChangeEdit}
            variant="contained"
            color="primary"
          >
            <div>{isEdit ? 'Save' : 'Edit'}</div>
          </Button>
        </div>
        <div>
          {isLoadingStakeholder && (
            <>
              <span
                role="alert"
                style={{ position: 'absolute', marginLeft: -9999 }}
              >
                Updating user information
              </span>
              <CircularProgress style={{ margin: 16 }} />
            </>
          )}
          <FormControl
            variant="outlined"
            disabled={!isEdit}
            className={classes.formControlDropdown}
            required
          >
            <InputLabel
              style={{ fontSize: '14px !important', color: '#767676' }}
              id="demo-simple-select-label"
            >
              Organization Type
            </InputLabel>
            <MySelect
              inputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled
                }
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedOrgType}
              onChange={handleChangeDropdown('selectedOrgType')}
            >
              {orgTypes.length &&
                orgTypes.map((orgType, idx) => (
                  <MenuItem key={idx} value={orgType.ORGANIZATION_TYPE_ID}>
                    {orgType.ORGANIZATION_TYPE}
                  </MenuItem>
                ))}
              {/* bryan's original */}
              {/* {orgTypes.length &&
                orgTypes.map((orgType, idx) => (
                  <MenuItem key={idx} value={orgType.ID}>
                    {orgType.Description}
                  </MenuItem>
                ))} */}
            </MySelect>
          </FormControl>
          <MyTextField
            inputProps={{
              classes: {
                root: classes.inputRoot,
                disabled: classes.disabled
              },
              style: { fontSize: 14 }
            }}
            InputLabelProps={{
              id: 'Name of business or organization represented'
            }}
            disabled={!isEdit}
            fullWidth
            value={orgName}
            onInput={(e: any) => setOrgName(e.target.value)}
            label="Name of business or organization represented"
            variant="outlined"
            id="Name of business or organization represented"
          />
        </div>
      </div>
    </MyPaper>
  );
}
