import React, { ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  fetchFollowProject,
  fetchUnfollowProject
} from '../../store/slices/projects';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
const useStyles = makeStyles((theme) => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  },
  formControl: {
    margin: theme.spacing(2)
  }
}));

interface Props {
  project;
  style?;
}

export default function DeprecatedToggleFollowBottomDrawer({
  project
}: Props): ReactElement {
  const { isFollowedProjects } = useSelector(
    (state: RootState) => state
  ).projectsSlice;
  const dispatch = useDispatch();
  const { ProjectId: projectId, IsFollowing } = project;

  const optionList = IsFollowing
    ? [
        {
          text: 'Unsubscribe to all project updates',
          action: 'unsubscribe'
        },
        {
          text: 'Unsubscribe project event notifications'
        },
        {
          text: 'Cancel'
        }
      ]
    : [
        {
          text: 'Subscribe to all project updates​',
          action: 'subscribe'
        },
        {
          text: 'Subscribe to project event notifications'
        },
        {
          text: 'Cancel'
        }
      ];
  const classes = useStyles();
  const [state, setState] = React.useState({
    bottom: false
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };
  const [optionValue, setOptionValue] = useState('');
  const handleChange = (event) => {
    setOptionValue(event.target.value);
  };
  const handleSubmit = (value: string) => {
    if (value === 'Cancel') {
      setState({ bottom: false });
      return;
    } else if (value.includes('Unsubscribe')) {
      dispatch(
        fetchUnfollowProject({
          projectId,
          isOnFollowingOnlyView: isFollowedProjects
        })
      );
    } else if (value.includes('Subscribe')) {
      dispatch(
        fetchFollowProject({
          projectId,
          isEmail: true,
          isSMS: false,
          isNotify: false
        })
      );
    }
  };

  return (
    <div style={{ alignSelf: 'center' }}>
      <Button
        aria-label={IsFollowing ? 'FOLLOWING' : 'FOLLOW'}
        onClick={toggleDrawer('bottom', true)}
        color="secondary"
        style={{
          fontSize: 10,
          maxWidth: 75,
          minWidth: 75,
          backgroundColor: IsFollowing ? '#21890e' : ''
        }}
        variant="contained"
      >
        {IsFollowing ? 'FOLLOWING' : 'FOLLOW'}
      </Button>
      <Drawer
        anchor="bottom"
        open={state.bottom}
        onClose={toggleDrawer('bottom', false)}
      >
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup value={optionValue} onChange={handleChange}>
            {optionList.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.text}
                control={<Radio />}
                label={option.text}
              ></FormControlLabel>
            ))}
          </RadioGroup>
        </FormControl>
        <Button
          aria-label="Submit."
          color="primary"
          variant="contained"
          onClick={() => handleSubmit(optionValue)}
          style={{ borderRadius: 0 }}
        >
          Submit
        </Button>
      </Drawer>
    </div>
  );
}
