import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeProps } from './Icons';

export default styled(Link)`
  ${({ theme }: ThemeProps) => `
  color: ${theme.palette.primary.main};
  text-decoration: none;
  // disable text highlighting https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
  `}
`;
