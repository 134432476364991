import React, { ReactElement } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SubHeaderGoBack } from '..';

export default function MeetingDetails(): ReactElement {
  return (
    <div>
      <SubHeaderGoBack isArrowBack={false} heading="Meeting Details" />
      <Link style={{ textDecoration: 'none' }} to="/participate">
        <Button
          aria-label="Register"
          style={{ marginTop: 50 }}
          color="primary"
          variant="contained"
        >
          Register
        </Button>
      </Link>
    </div>
  );
}
