import React, { ReactElement, useEffect } from 'react';
import { Button, Typography } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import history from '../../history';
import { useSelector } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';
import { setIsAlreadyLoggedIn } from '../../store/slices/user';
import { useDispatch } from 'react-redux';
import { styled as styledMUI } from '@material-ui/core/styles';
import { Spacer } from '../../styled-elements';
import EmailIcon from '@material-ui/icons/Email';
import { RootState } from '../../store';
import { environment } from '../../environment';
import { IowaDOTPrivacyPolicy, MassDOTPrivacyPolicy } from '..';
import { Helmet } from 'react-helmet';
const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IowaDOTLandingPageImg = styled.img`
  margin-top: 16px;
  max-height: 50vh;
  width: 100%;
  object-fit: cover;
  object-position: 100% 25%;
  margin-bottom: 16px;
`;

const MassDOTLandingPageImg = styled.img`
  margin-top: 16px;
  max-height: 50vh;
  width: 100%;
  object-fit: cover;
  object-position: 100% 25%;
  margin-bottom: 16px;
  @media only screen and (min-height: 720px) {
    object-position: 100% 75%;
    margin-top: 24px;
  }
`;

const MaineDOTLandingPageImg = styled.img`
  margin-top: 16px;
  max-height: 50vh;
  width: 100%;
  object-fit: cover;
  object-position: 100% 25%;
  margin-bottom: 16px;
  @media only screen and (min-height: 720px) {
    object-position: 100% 75%;
    margin-top: 24px;
  }
`;

const CTDOTLandingPageImg = styled.img`
  margin-top: 16px;
  max-height: 50vh;
  width: 100%;
  object-fit: cover;
  object-position: 100% 25%;
  margin-bottom: 16px;
`;

const LogosContainerFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 720px) {
    justify-content: center;
  }
`;

const MainDOTLogosContainerFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  @media only screen and (max-width: 720px) {
    justify-content: center;
  }
`;

const CTDOTLogosContainerFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  @media only screen and (max-width: 720px) {
    justify-content: center;
  }
`;

const EmptyFormattingDiv = styled.div`
  // this is so we can have one item centered and the other right aligned
  width: 125px;
  @media only screen and (max-width: 720px) {
    display: none;
  }
`;
const ReachLogo = styled.img`
  width: 200px;
  position: absolute;
  left: calc(50% - 103px);
  @media only screen and (max-width: 720px) {
    width: 125px;
    position: static;
  }
`;

const IowaDOTEntityLogo = styled.img`
  width: 125px;
  margin: 8px 20px 0px 0px;
  @media only screen and (max-width: 720px) {
    margin: 0px 20px 0px 0px;
    width: 100px;
    margin-left: 16px;
  }
`;

const MassDOTEntityLogo = styled.img`
  width: 175px;
  margin: 8px 20px 0px 0px;
  @media only screen and (max-width: 720px) {
    width: 125px;
    margin-left: 16px;
  }
`;


const CTDOTEntityLogo = styled.img`
  width: 125px;
  margin: 8px 20px 0px 0px;
  position: absolute;
  top: -15px;
  right: 10px;
  @media only screen and (max-width: 720px) {
    margin: 0px 20px 0px 0px;
    width: 100px;
    margin-left: 16px;
  }
`;

const MaineDOTEntityLogo = styled.img`
  width: 175px;
  margin: 8px 20px 0px 0px;
  @media only screen and (max-width: 720px) {
    width: 125px;
    margin-left: 16px;
  }
`;
const ReachSubtitle = styled.h1`
  text-transform: uppercase;
  font-size: 12px;
  color: #707070;
  position: absolute;
  left: calc(50% - 155px);
  top: 62px;
  @media only screen and (max-width: 720px) {
    padding-top: 4px;
    top: 48px;
  }
`;

const MaineDOTSubtitle = styled.h1`
  text-transform: uppercase;
  font-size: 18px;
  color: black;
  position: absolute;
  left: calc(50% - 105px);
  top: 37px;
  @media only screen and (max-width: 720px) {
    padding-top: 4px;
    top: 48px;
  }
`;

const GoogleLogoComponent = styled.img`
  width: 25px;
  padding-right: 12px;
`;

const CenteredFlexContainer = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
`;

const LoginButtonContainer = styled.div`
  background-color: white;
  text-decoration: none;
  width: 200px;
  border: 1px solid grey;
  border-radius: 5px;
  height: 40px;
`;

const LoginButton = styledMUI(Button)({
  margin: 0,
  padding: '0px 8px',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around'
});

const LoginText = styled.div`
  padding-bottom: 3px;
  font-size: 12px;
`;

const PolicyAgreementsLandingPage = styled.div`
  width: 300px;
  font-size: 14px;
  margin: 36px auto;
  @media only screen and (min-height: 800px) {
    width: 300px;
    position: fixed;
    margin: 12px;
    font-size: 14px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%); // fixes bottom center on desktop too
  }
`;

export const getEntityPrivacyPolicy = () => {
  // console.log('environment: getEntityPrivacyPolicy', environment);
  switch (environment.entityName) {
    case 'Iowa DOT':
      return <IowaDOTPrivacyPolicy />;
    case 'Mass DOT':
      return <MassDOTPrivacyPolicy />;
    case 'Maine DOT':
      return <></>;
    case 'Connecticut DOT':
      return <></>;
    default:
      throw new Error(
        'unhandled case in LandingPage -> getEntityPrivacyPolicy()'
      );
  }
};

const getEntityLandingPageImg = () => {
    switch (environment.entityName) {
        case 'Iowa DOT':
            return (
                <IowaDOTLandingPageImg
                    src={environment.landingPageImg}
                    alt={`${environment.entityName} Landing Page`}
                />
            );
        case 'Mass DOT':
            return (
                <MassDOTLandingPageImg
                    src={environment.landingPageImg}
                    alt={`${environment.entityName} Landing Page`}
                />
            );
        case 'Maine DOT':
            return (
                <MaineDOTLandingPageImg
                    src={environment.landingPageImg}
                    alt={`${environment.entityName} Landing Page`}
                />
            );
        case 'Connecticut DOT':
            return (
                  <CTDOTLandingPageImg
                  src={environment.landingPageImg}
                  alt={`${environment.entityName} Landing Page`}
              />
            );
        default:
            throw new Error(
                'unhandled case in LandingPage -> getEntityLandingPageImg()'
            );
    }
};

const getEntitySignIn = () => {
  switch (environment.entityName) {
    case 'Iowa DOT':
      return (
          <div>
              New to REACH? <Link to="/signup">Sign up</Link>
          </div>
      );
    case 'Mass DOT':
      return (
          <div>
              New to REACH? <Link to="/signup">Sign up</Link>
          </div>
      );
    case 'Maine DOT':
      return (
          <div>
              New to this site? <Link to="/signup">Sign up</Link>
          </div>
      );
      case 'Connecticut DOT':
        return (
            <div>
                New to REACH? <Link to="/signup">Sign up</Link>
            </div>
        );
    default:
      throw new Error(
        'unhandled case in LandingPage -> getEntityLandingPageImg()'
      );
  }
};

const getEntityLogo = () => {
  // console.log('environment: ', environment);
  switch (environment.entityName) {
    case 'Iowa DOT':
      return (
        <IowaDOTEntityLogo
          src={environment.clientLogo}
          alt={`${environment.entityName} Logo`}
        ></IowaDOTEntityLogo>
      );
    case 'Mass DOT':
      return (
        <MassDOTEntityLogo
          src={environment.clientLogo}
          alt={`${environment.entityName} Logo`}
        ></MassDOTEntityLogo>
      );
    case 'Maine DOT':
      return ("");
    case 'Connecticut DOT':
      return (
        <CTDOTEntityLogo
          src={environment.clientLogo}
          alt={`${environment.entityName} Logo`}
        ></CTDOTEntityLogo>
      );
    default:
      throw new Error('unhandled case in LandingPage -> getEntityLogo()');
  }
};

const getReachLogo = () => {
    // console.log('environment: ', environment);
    switch (environment.entityName) {
        case 'Iowa DOT':
            return (
                <ReachLogo
                    src="/images/logos/REACH_logo.png"
                    alt={`${environment.entityName} REACH Logo (Real Time Engagement and Communications Hub)`}
                />
            );
        case 'Mass DOT':
            return (
                <ReachLogo
                    src="/images/logos/REACH_logo.png"
                    alt={`${environment.entityName} REACH Logo (Real Time Engagement and Communications Hub)`}
                />
            );
        case 'Maine DOT':
            return (
                <ReachLogo
                    src={environment.clientLogo}
                    alt={`${environment.entityName} Logo`}
                />
            );
        case 'Connecticut DOT':
          return (
            <ReachLogo
                src="/images/logos/REACH_logo.png"
                alt={`${environment.entityName} REACH Logo (Real Time Engagement and Communications Hub)`}
            />
        );
        default:
            throw new Error('unhandled case in LandingPage -> getReachLogo()');
    }
};

const getLogosContainer = () => {
    // console.log('environment: ', environment);
    switch (environment.entityName) {
        case 'Iowa DOT':
            return (
                <LogosContainerFlex>
                    <EmptyFormattingDiv />
                    <EmptyFormattingDiv />
                    {getReachLogo()}
                    {getEntityLogo()}
                </LogosContainerFlex>
            );
        case 'Mass DOT':
            return (
                <LogosContainerFlex>
                    <EmptyFormattingDiv />
                    <EmptyFormattingDiv />
                    {getReachLogo()}
                    {getEntityLogo()}
                </LogosContainerFlex>
            );
        case 'Maine DOT':
            return (
                <MainDOTLogosContainerFlex>
                    <EmptyFormattingDiv />
                    <EmptyFormattingDiv />
                    {getReachLogo()}
                    {getEntityLogo()}
                </MainDOTLogosContainerFlex>
            );
        case 'Connecticut DOT':
          return (
            <CTDOTLogosContainerFlex>
                <EmptyFormattingDiv />
                <EmptyFormattingDiv />
                {getReachLogo()}
                {getEntityLogo()}
            </CTDOTLogosContainerFlex>
        );
        default:
            throw new Error('unhandled case in LandingPage -> getReachLogo()');
    }
};

const getReachSubTitle = () => {
    // console.log('environment: ', environment);
    switch (environment.entityName) {
        case 'Iowa DOT':
            return (
                <ReachSubtitle>Real-Time Engagement and Communications Hub</ReachSubtitle>
            );
        case 'Mass DOT':
            return (
                <ReachSubtitle>Real-Time Engagement and Communications Hub</ReachSubtitle>
            );
        case 'Maine DOT':
            return (
                <MaineDOTSubtitle>Public Involvement</MaineDOTSubtitle>
            );
        case 'Connecticut DOT':
          return (
            <ReachSubtitle>Real-Time Engagement and Communications Hub</ReachSubtitle>
        );
        default:
            throw new Error('unhandled case in LandingPage -> getReachSubTitle()');
    }
};

export default function LandingPage(): ReactElement {
  const dispatch = useDispatch();
  const { isLoggedInREACH } = useSelector((state: RootState) => {
    return state;
  }).userSlice;

  useEffect(() => {
    // use for logging, state is in store
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          break;
        case 'cognitoHostedUI':
          break;
        case 'signOut':
          // handled in Header component
          break;
        case 'signIn_failure':
          console.log('SIGN OUT FAILURE');
          break;
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then((userData) => {
      if (userData && !isLoggedInREACH) {
        const payload = userData.signInUserSession.idToken.payload;
        localStorage.setItem('sub', payload.sub);
        if (payload && localStorage.getItem('reachAuth')) {
          dispatch(setIsAlreadyLoggedIn(payload.email));
        }
      }
    });
  }, [dispatch, isLoggedInREACH]);

  const getUser = () => {
    return Auth.currentAuthenticatedUser()
      .then((userData) => {
        return userData;
      })
      .catch(() => {
        //console.log('Not signed in');
      });
  };

  useEffect(() => {
    isLoggedInREACH && history.push('/projects');
  }, [isLoggedInREACH]);

  return (
    <ComponentContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{environment.entityName} REACH</title>
        <meta
          name="description"
          content={`${environment.entityName} - Real Time Engagement and Communications Hub (REACH)`}
        ></meta>
        {environment.entityName.includes('DOT') && (
          <meta
            name="keywords"
            content={`Department of Transportation,REACH,Real Time Engagement and Communications Hub,${environment.entityName}`}
          ></meta>
        )}
        <link rel="canonical" href={environment.clientUrl} />
      </Helmet>
          <Spacer />
      {getLogosContainer()}
      {getReachSubTitle()}
      {/*<ReachSubtitle>Real-Time Engagement and Communications Hub</ReachSubtitle>*/}
      <Spacer />
      {getEntityLandingPageImg()}
      <Spacer />
      <CenteredFlexContainer>
        {/* MAS-193 hide google login - finish development in future phase */}
        {/* <LoginButtonContainer>
          <LoginButton
            onClick={() =>
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google
              })
            }
          >
            <GoogleLogoComponent
              style={{ paddingLeft: 6 }}
              alt="google-logo"
              src={GoogleIcon}
            />
            <LoginText style={{ paddingRight: 2 }}>Login with Google</LoginText>
          </LoginButton>
        </LoginButtonContainer>
        <Spacer />
        */}
        {/* <Typography variant="h4" color="primary">
          Coming October 1
        </Typography> */}
        {/* ADD SIGNUP/LOGIN BACK AFTER PROD LAUNCH */}
        <LoginButtonContainer>
          <LoginButton onClick={() => history.push('/login')}>
            <EmailIcon color="primary" />
            <LoginText style={{ paddingRight: 7 }}>Login with Email</LoginText>
          </LoginButton>
        </LoginButtonContainer>
      </CenteredFlexContainer>
      <Spacer />
      <Spacer />
          {/* ADD SIGNUP/LOGIN BACK AFTER PROD LAUNCH */}
      {getEntitySignIn()}
      <Spacer />
      <a href={environment.faqDocUrl} target="blank">
        Frequently Asked Questions
      </a>
      <PolicyAgreementsLandingPage>
        {getEntityPrivacyPolicy()}
      </PolicyAgreementsLandingPage>
    </ComponentContainer>
  );
}
