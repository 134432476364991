import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface Props {
  localState: any;
  setLocalState: Function;
}

export default function ProjectCommentFormTopics({
  localState,
  setLocalState
}: Props): ReactElement {
  const { project, commentFormState } = useSelector(
    (state: RootState) => state
  ).participateSlice;

  return (
    <>
      {project &&
      commentFormState.commentTopics &&
      project.SelectedCommentTopics.length ? (
        <div>
          <FormControl component="fieldset">
            <FormGroup>
              {project.SelectedCommentTopics.map((t, idx: number) => (
                <FormControlLabel
                  key={idx}
                  control={
                    <Checkbox
                      checked={localState.commentTopics.includes(
                        t.COMMENT_TOPIC_ID
                      )}
                      onChange={(e: any) => {
                        const idToAddOrRemove = project.SelectedCommentTopics.find(
                          (t) => t.COMMENT_TOPIC === e.target.name
                        ).COMMENT_TOPIC_ID;
                        if (e.target.checked) {
                          // add
                          setLocalState({
                            ...localState,
                            commentTopics: [
                              ...localState.commentTopics,
                              idToAddOrRemove
                            ]
                          });
                        } else {
                          // remove
                          setLocalState({
                            ...localState,
                            commentTopics: localState.commentTopics.filter(
                              (id: number) => id !== idToAddOrRemove
                            )
                          });
                        }
                      }}
                      name={t.COMMENT_TOPIC}
                    />
                  }
                  label={t.COMMENT_TOPIC}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      ) : (
        <>
          <span role="alert" style={{ visibility: 'hidden' }}>
            Loading project comment form
          </span>
          <CircularProgress style={{ margin: 24 }} />
        </>
      )}
    </>
  );
}
